import React from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { jwtSelector } from "../../../../../selectors/auth";
import LoginForm from "./LoginForm";
import logo from "../../../../../styles/images/logo_login_big.png";
import "../styles/login-page.scss";

const LoginPage = props => {
  const { token } = props;
  const { from } = props.location.state || {
    from: { pathname: "/" }
  };
  if (token) {
    return <Redirect to={from} />;
  }
  return (
    <div className="login-page">
      <header>
        <img src={logo} alt="scrooge-icon" />
      </header>
      <div className="login-container">
        <LoginForm />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: jwtSelector(state)
});

export default connect(mapStateToProps)(LoginPage);
