import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { userSelector } from "../../../../selectors/user";
import { getUserRequest } from "../../../../actions/admin";
// import "react-toastify/dist/ReactToastify.css";
import "../../../admin/UsersPage/components/CreateUserSubPage/styles/create-user-form-container.scss";
import "../../../admin/UsersPage/styles/users-page.scss";
import { ProfileUserForm } from "./ProfileUserForm";
import { ChangePasswordForm } from "./ChangePasswordForm";
import TelegramCode from "./TelegramCode";

const UserNewHeader = () => (
  <div className="subheader-new-user">
    <span className="subtitle">User Profile</span>
  </div>
);

class ProfileUser extends Component {
  // onSubmit = values => {
  //   const { email } = values;
  //   this.setState({ email: email });
  //   let registrationDto = { ...values };
  //   if (registrationDto.referralCode === "") {
  //     registrationDto.referralCode = null;
  //   }
  //   const { createUser } = this.props;
  //   createUser(registrationDto);
  //   toast(`User ${email} has successfully created`, {
  //     className: "toast"
  //   });
  // };

  render() {
    const { isFetching, status, message, user } = this.props;
    return (
      <div className="users-page">
        <div className="page-content">
          <div className="create-user-form-container">
            <UserNewHeader />
            <TelegramCode />
            {/*<div className="create-user-wrapper flex-wrapper">*/}
            {/*  <div className="white-container width-equal">*/}
            {/*    <ProfileUserForm*/}
            {/*      user={user}*/}
            {/*      //onSubmitCallback={this.onSubmit}*/}
            {/*      isFetching={isFetching}*/}
            {/*      error={message}*/}
            {/*    />*/}
            {/*    {status === 200 ? <ToastContainer /> : null}*/}
            {/*  </div>*/}
            {/*  <div className="white-container width-equal">*/}
            {/*    <ChangePasswordForm*/}
            {/*      //onSubmitCallback={this.onSubmit}*/}
            {/*      isFetching={isFetching}*/}
            {/*      error={message}*/}
            {/*    />*/}
            {/*    {status === 200 ? <ToastContainer /> : null}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUser: id => dispatch(getUserRequest(id))
});

const mapStateToProps = state => ({
  user: userSelector(state)
  // isFetching: isFetchingSelector(state),
  // status: userStatusSelector(state),
  // message: userMessageSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileUser);
