import React, { Component } from "react";
import "../../../admin/UsersPage/styles/users-page.scss";
import "../../Referees/styles/Referees.scss";
import "../../TraderDiary/styles/TraderDiary.scss";
import "../styles/Payments.scss";
import UserTransactions from "./UserTransactions";
import PageableTable from "../../../../components/PageableTable/components";

class Payments extends Component {
  render() {
    return (
      <div className="users-page user-referees trader-diary payments">

        <UserTransactions id="referral-transactions" title="Referral Bonus" style={{margin: '2em 0'}}/>

        <UserTransactions id="user-transactions" title="Payments to Scrooge"/>

      </div>
    );
  }
}

export default Payments;
