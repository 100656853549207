// import React, {Component, createRef} from "react";
// import {connect} from "react-redux";
// import {
//     lastBalanceSelector,
//     openDealsSelector, openDealsStatsSelector,
//     userDealsSelector
// } from "../../../../selectors/user";
// import {userIdSelector} from "../../../../selectors/user";
// import {getUserDealsRequest, getUserOpenDealsRequest} from "../../../../actions/user";
// import {getLiveBalanceRequest} from "../../../../actions/userStatistics";
// import "../styles/new-order.scss"
// import {UserTradingSettingsSchema} from "../../../admin/UsersPage/components/UsersProfileSubPage/components/TabPanel/SettingsTab/TradingSettings/validators/trading-settings";
// import {Formik} from "formik";
// import {FormikTextField} from "../../../../components/formik/FormikTextField";
// import {FormikSliderField} from "../../../../components/formik/FormikSliderField";
//
//
// // const validationSchema = Yup.object().shape({
// //     decimal: Yup.number().test(
// //         'is-decimal',
// //         'invalid decimal',
// //         value => (value + "").match(/^\d*\.{1}\d*$/),
// //     ),
// // });
//
// const sliderMarks = [
//     {
//         value: 0,
//         // label: "0%"
//     },
//     {
//         value: 25,
//         // label: "25%"
//     },
//     {
//         value: 50,
//         // label: "50%"
//     },
//     {
//         value: 75,
//         // label: "75%"
//     },
//     {
//         value: 100,
//         // label: "100%"
//     }
// ];
//
// class SellOrder extends Component {
//
//     state = {
//         orderType: "limit"
//     };
//
//     constructor() {
//         super()
//
//     }
//
//     componentDidMount() {
//         // const {getDeals, getUsersOpenDeals, userId, getBalances} = this.props;
//         // // console.log('this.props ==>',this.props)
//         // getDeals(userId);
//         // getUsersOpenDeals(userId);
//         // setTimeout(() => {
//         //     getBalances(userId);
//         // }, 500)
//
//     }
//
//     newOrderSubmit(values) {
//         console.log("newOrderSubmit: ", values)
//     }
//
//     validateNumber = (v = 0, decimals = 2, min = 10, max, pattern) => {
//         try {
//             const regex = new RegExp(pattern, 'g');
//             let n = parseFloat(v)
//             if (n <= min) {
//                 n = min
//             }
//             if (n > max) {
//                 n = max
//             }
//             if (!regex.test(n)) {
//                 n = parseFloat(n.toFixed(decimals))
//             }
//             return n
//
//         } catch (error) {
//             return (min).toFixed(decimals)
//         }
//     }
//
//     getNumericPattern = (end) => {
//         const start = 1;
//         const p = `^\\d+((\\.|\\,)\\d{${Array(end - start + 1).fill().map((_, idx) => start + idx).join(",")}})?$`
//         return p
//     }
//
//     valuetext = (v) => {
//         return `${v}%`
//     }
//
//     render() {
//         // const {
//         //     setFieldValue,
//         //     handleSubmit,
//         //     values
//         // } = this.props;
//         const {orderType} = this.props
//         const init = {
//             base: "USDT", baseAmount: 1234.32, altAmount: 12.452345, alt: "BTC", priceToOrder: 41023.23,
//             altDecimals: 5, baseDecimals: 2
//         }
//
//
//         return (
//             <Formik
//                 // enableReinitialize={true}
//                 initialValues={init}
//                 // validationSchema={UserTradingSettingsSchema}
//                 onSubmit={this.newOrderSubmit}
//             >
//                 {({handleSubmit, values, setFieldValue}) => (
//
//                     <div className="new-order__sell">
//                         <div className="order-available">
//                             <div className="order-available__label">Available</div>
//                             <div className="order-available__amount">{init.altAmount}</div>
//                             <div className="order-available__currency">{init.alt}</div>
//                         </div>
//
//                         <div className="order-price">
//                             <div className="order-label">Price</div>
//                             <FormikTextField
//                                 placeholder={" "}
//                                 name="priceToOrder"
//                                 type="number"
//                                 disabled={orderType === "market"}
//                                 inputProps={{
//                                     inputMode: 'numeric',
//                                     pattern: this.getNumericPattern(init.baseDecimals),
//                                     min: (10).toFixed(init.baseDecimals),
//                                     step: 0.01
//                                 }}
//                                 onChange={v => {
//                                     const price = this.validateNumber(v.target.value, init.baseDecimals, 10, 99999999999, this.getNumericPattern(init.baseDecimals))
//                                     setFieldValue("priceToOrder", price)
//                                     const amount = this.validateNumber(values.amountToOrder, init.altDecimals, 10 / price, init.altAmount, this.getNumericPattern(init.altDecimals))
//                                     setFieldValue("amountToOrder", amount)
//                                     const totalPriceToOrder = parseFloat((amount * price).toFixed(init.baseDecimals))
//                                     setFieldValue("totalPriceToOrder", totalPriceToOrder)
//                                     setFieldValue("percent", ((amount / init.altAmount) * 100).toFixed(0))
//                                 }}
//
//                             />
//                             <div className="order-currency">{init.base}</div>
//                         </div>
//
//                         <div className="order-price">
//                             <div className="order-label">Amount</div>
//                             <FormikTextField
//                                 placeholder={" "}
//                                 inputProps={{
//                                     inputMode: 'numeric',
//                                     pattern: this.getNumericPattern(init.altDecimals),
//                                     step: 0.00001
//                                 }}
//                                 name="amountToOrder"
//                                 type="number"
//                                 onChange={v => {
//                                     const amount = this.validateNumber(v.target.value, init.altDecimals, 10 / values.priceToOrder, init.altAmount, this.getNumericPattern(init.altDecimals))
//                                     setFieldValue("amountToOrder", amount)
//                                     setFieldValue("percent", ((amount / init.altAmount) * 100).toFixed(0))
//                                     const totalPriceToOrder = this.validateNumber(amount * values.priceToOrder, init.baseDecimals, 10, init.altAmount * values.priceToOrder, this.getNumericPattern(init.baseDecimals))
//                                     setFieldValue("totalPriceToOrder", totalPriceToOrder)
//                                 }}
//                             />
//                             <div className="order-currency">{init.alt}</div>
//                         </div>
//
//                         <div className="order-price">
//                             <div className="order-label">Total</div>
//                             <FormikTextField
//                                 placeholder={" "}
//                                 inputProps={{
//                                     inputMode: 'numeric',
//                                     pattern: this.getNumericPattern(init.altDecimals),
//                                     // max: (init.baseAmount / values.priceToOrder).toFixed(init.altDecimals),
//                                     // min: (10 / values.priceToOrder).toFixed(init.altDecimals),
//                                     step: 0.01
//                                 }}
//                                 name="totalPriceToOrder"
//                                 disabled={orderType === "market"}
//                                 type="number"
//                                 onChange={v => {
//                                     console.log("totalPriceToOrder change:", values)
//
//                                     const totalPriceToOrder = this.validateNumber(v.target.value, init.baseDecimals, 10, init.baseAmount * init.altAmount, this.getNumericPattern(init.baseDecimals))
//                                     setFieldValue("totalPriceToOrder", totalPriceToOrder)
//                                     const amount = this.validateNumber(totalPriceToOrder / values.priceToOrder, init.altDecimals, 10 / values.priceToOrder, init.altAmount, this.getNumericPattern(init.altDecimals))
//                                     setFieldValue("amountToOrder", amount)
//                                     setFieldValue("percent", ((amount / init.altAmount) * 100).toFixed(0))
//
//                                 }}
//                             />
//                             <div className="order-currency">{init.base}</div>
//                         </div>
//
//                         <FormikSliderField
//                             name="percent"
//                             min={0}
//                             max={100}
//                             step={1}
//                             // defaultValue={0}
//                             getAriaValueText={this.valuetext}
//                             valueLabelFormat={this.valuetext}
//                             value={values.percent || 0}
//                             aria-label="custom thumb label"
//                             valueLabelDisplay="auto"
//                             marks={sliderMarks}
//                             onChange={(component, value) => {
//                                 console.log("values", values)
//                                 const amount = this.validateNumber((value / 100.00) * init.altAmount, init.altDecimals, 10 / values.priceToOrder, init.altAmount, this.getNumericPattern(init.altDecimals))
//                                 setFieldValue("percent", value)
//                                 setFieldValue("amountToOrder", amount)
//                                 const totalPriceToOrder = this.validateNumber(amount * values.priceToOrder, init.baseDecimals, 10, init.altAmount * values.priceToOrder, this.getNumericPattern(init.baseDecimals))
//                                 setFieldValue("totalPriceToOrder", totalPriceToOrder)
//                             }}
//                         />
//                         <button className={"sell-btn"}>{`Sell ${init.alt}`}</button>
//                     </div>
//                 )}
//             </Formik>
//         );
//     }
// }
//
// const mapStateToProps = (state, ownProps) => ({
//     trades: userDealsSelector(state),
//     userId: ownProps.userId || userIdSelector(state),
//     lastBalance: lastBalanceSelector(state),
//     openDeals: openDealsSelector(state),
//     openDealsStats: openDealsStatsSelector(state)
// });
//
// const mapDispatchToProps = dispatch => ({
//     getDeals: userId => dispatch(getUserDealsRequest(userId)),
//     getUsersOpenDeals: userId => dispatch(getUserOpenDealsRequest(userId)),
//     getBalances: userId => dispatch(getLiveBalanceRequest(userId))
// });
//
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(SellOrder);


import React, {Component} from "react";
import {connect} from "react-redux";
import {
    lastBalanceSelector,
    openDealsSelector, openDealsStatsSelector,
    userDealsSelector
} from "../../../../selectors/user";
import {userIdSelector} from "../../../../selectors/user";
import {getUserDealsRequest, getUserOpenDealsRequest} from "../../../../actions/user";
import {getLiveBalanceRequest} from "../../../../actions/userStatistics";
import "../styles/new-order.scss"
import {Formik} from "formik";
import {FormikTextField} from "../../../../components/formik/FormikTextField";
import {FormikSliderField} from "../../../../components/formik/FormikSliderField";

const sliderMarks = [
    {
        value: 0,
        // label: "0%"
    },
    {
        value: 25,
        // label: "25%"
    },
    {
        value: 50,
        // label: "50%"
    },
    {
        value: 75,
        // label: "75%"
    },
    {
        value: 100,
        // label: "100%"
    }
];

class SellOrder extends Component {

    state = {};

    constructor(props) {
        super(props)
    }

    newOrderSubmit(values) {
        const confirmBox = window.confirm(
            "Do you really wanna place SELL order?"
        )
        if (confirmBox === true) {
            console.log("newOrderSubmit: ", values)
        }
    }

    validateNumber = (v = 0, decimals = 2, min = 10, max, pattern) => {
        try {
            const regex = new RegExp(pattern, 'g');
            let n = parseFloat(v)
            if (n <= min) {
                n = min
            }
            if (n >= max) {
                n = max
            }
            if (!regex.test(n)) {
                n = parseFloat(n.toFixed(decimals))
            }
            return n

        } catch (error) {
            return (min).toFixed(decimals)
        }
    }

    getNumericPattern = (end = 8) => {
        const start = 1;
        const p = `^\\d+((\\.|\\,)\\d{${Array(end - start + 1).fill().map((_, idx) => start + idx).join(",")}})?$`
        return p
    }

    valuetext = (v) => {
        return `${v}%`
    }


    onFieldChange = (field, value, values = {}, setFieldValue) => {
        const {symbolLimits = {}, availableBase, availableAlt} = this.props
        const {
            baseAssetPrecision = 8,
            quoteAssetPrecision = 8,
            maxPrice,
            minNotional,
            minPrice,
        } = symbolLimits
        const {amountToOrder, priceToOrder, totalPriceToOrder} = values
        if (field === "priceToOrder") {
            const price = this.validateNumber(value, quoteAssetPrecision, minPrice, maxPrice, this.getNumericPattern(quoteAssetPrecision))
            setFieldValue("priceToOrder", price)
            const amount = this.validateNumber(amountToOrder, baseAssetPrecision, minNotional / price, availableAlt, this.getNumericPattern(baseAssetPrecision))
            setFieldValue("amountToOrder", amount)
            const tpto = parseFloat((amount * price).toFixed(quoteAssetPrecision))
            setFieldValue("totalPriceToOrder", tpto)
            setFieldValue("percent", ((amount / availableAlt) * 100).toFixed(0))
        }
        if (field === "amountToOrder") {
            const amount = this.validateNumber(value, baseAssetPrecision, minNotional / priceToOrder, availableAlt, this.getNumericPattern(baseAssetPrecision))
            setFieldValue("amountToOrder", amount)
            setFieldValue("percent", ((amount / availableAlt) * 100).toFixed(0))
            const tpto = this.validateNumber(amount * priceToOrder, quoteAssetPrecision, minNotional, priceToOrder * availableAlt, this.getNumericPattern(quoteAssetPrecision))
            setFieldValue("totalPriceToOrder", tpto)
        }
        if (field === "totalPriceToOrder") {
            const tpto = this.validateNumber(value, quoteAssetPrecision, minNotional, priceToOrder * availableAlt, this.getNumericPattern(quoteAssetPrecision))
            setFieldValue("totalPriceToOrder", tpto)
            const amount = this.validateNumber(totalPriceToOrder / priceToOrder, baseAssetPrecision, minNotional / priceToOrder, availableAlt, this.getNumericPattern(baseAssetPrecision))
            setFieldValue("amountToOrder", amount)
            setFieldValue("percent", ((amount / availableAlt) * 100).toFixed(0))
        }
        if (field === "percent") {
            const amount = this.validateNumber(((value || 0) / 100.00) * availableAlt, baseAssetPrecision, minNotional / priceToOrder, availableAlt, this.getNumericPattern(baseAssetPrecision))
            setFieldValue("percent", value)
            setFieldValue("amountToOrder", amount)
            const tpto = this.validateNumber(amount * priceToOrder, quoteAssetPrecision, minNotional, availableAlt * priceToOrder, this.getNumericPattern(quoteAssetPrecision))
            setFieldValue("totalPriceToOrder", tpto)
        }
    }

    render() {
        const {orderType, openDeal = {}, symbolLimits = {}, availableBase, availableAlt} = this.props
        const {base, alt, currentPrice, volume} = openDeal
        const marketInit = {
            maxQty: 0,
            minQty: 0,
            stepSize: 0,
        }
        const {
            baseAssetPrecision = 8,
            quoteAssetPrecision = 8,
            market = marketInit,
            maxQty = 0,
            minQty = 0,
            stepSize = 0,
            minNotional = 10,
            maxPrice = 999999,
            minPrice = 0,
            tickSize = 0
        } = symbolLimits

        return (
            <Formik
                initialValues={{priceToOrder: currentPrice}}
                onSubmit={this.newOrderSubmit}
            >
                {({handleSubmit, values, setFieldValue}) => (

                    <form onSubmit={handleSubmit} className="new-order__sell">
                        <div className="order-available">
                            <div className="order-available__label">Available</div>
                            <div className="order-available__amount">{volume}</div>
                            <div className="order-available__currency">{alt}</div>
                        </div>
                        <div className="order-price">
                            <div className="order-label">Price</div>
                            <FormikTextField
                                placeholder={orderType === "market" ? "Market" : " "}
                                name="priceToOrder"
                                type="number"
                                disabled={orderType === "market"}
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: this.getNumericPattern(quoteAssetPrecision),
                                    min: minPrice,
                                    max: maxPrice,
                                    step: tickSize
                                }}
                                onChange={v => {
                                    this.onFieldChange("priceToOrder", v.target.value, values, setFieldValue)
                                }}
                            />
                            <div className="order-currency">{base}</div>
                        </div>
                        <div className="order-price">
                            <div className="order-label">Amount</div>
                            <FormikTextField
                                placeholder={" "}
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: this.getNumericPattern(baseAssetPrecision),
                                    min: (orderType === "market" ? market.minQty : minQty),
                                    max: (orderType === "market" ? market.maxQty : maxQty),
                                    step: (orderType === "market" ? market.stepSize : stepSize)
                                }}
                                name="amountToOrder"
                                type="number"
                                onChange={v => {
                                    this.onFieldChange("amountToOrder", v.target.value, values, setFieldValue)
                                }}
                            />
                            <div className="order-currency">{alt}</div>
                        </div>

                        <FormikSliderField
                            name="percent"
                            min={0}
                            max={100}
                            step={1}
                            // defaultValue={0}
                            getAriaValueText={this.valuetext}
                            valueLabelFormat={this.valuetext}
                            value={values.percent || 0}
                            aria-label="custom thumb label"
                            valueLabelDisplay="auto"
                            marks={sliderMarks}
                            onChange={(component, value) => {
                                this.onFieldChange("percent", value, values, setFieldValue)
                            }}
                        />

                        <div className="order-price">
                            <div className="order-label">Total</div>
                            <FormikTextField
                                placeholder={" "}
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: this.getNumericPattern(quoteAssetPrecision),
                                    min: minNotional,
                                    max: maxQty * maxPrice,
                                    step: tickSize
                                }}
                                name="totalPriceToOrder"
                                type="number"
                                disabled={orderType === "market"}
                                onChange={v => {
                                    this.onFieldChange("totalPriceToOrder", v.target.value, values, setFieldValue)
                                }}
                            />
                            <div className="order-currency">{base}</div>
                        </div>

                        <button type="submit" className={"sell-btn"}>{`Sell ${alt}`}</button>
                    </form>)}
            </Formik>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    trades: userDealsSelector(state),
    userId: ownProps.userId || userIdSelector(state),
    lastBalance: lastBalanceSelector(state),
    openDeals: openDealsSelector(state),
    openDealsStats: openDealsStatsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getDeals: userId => dispatch(getUserDealsRequest(userId)),
    getUsersOpenDeals: userId => dispatch(getUserOpenDealsRequest(userId)),
    getBalances: userId => dispatch(getLiveBalanceRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SellOrder);
