import React from "react";
import { Route, Switch, Router } from "react-router-dom";
import { history } from "../../../historyInstance";
import UserRoute from "../../../components/UserRoute/UserRoute";
import AdminRoute from "../../../components/AdminRoute/AdminRoute";
import NotFoundPage from "../../NotFoundPage";
import UsersPage from "../../admin/UsersPage";
import AdminStatisticPage from "../../admin/AdminStatisticPage";
import Referees from "../../user/Referees/components/Referees";
import Home from "../../user/Home/components/Home";
import Header from "./Header";
import TraderDiary from "../../user/TraderDiary/components/TraderDiary";
import Payments from "../../user/Payments/components/Payments";
import ProfileUser from "../../user/Profile/components/ProfileUser";
import "../styles/main-content.scss";

export const MainContent = () => (
  <>
    <Router history={history}>
      <Header />
      <main>
        <Switch>
          <AdminRoute path="/admin/users" component={UsersPage} />
          <AdminRoute
            path="/admin/statistics"
            exact
            component={AdminStatisticPage}
          />
          <UserRoute path="/user/profile" component={ProfileUser} />
          <UserRoute path="/user/referees" component={Referees} />
          <UserRoute path="/user/home" component={Home} />
          <UserRoute path="/user/trader-diary" component={TraderDiary} />
          <UserRoute path="/user/payments" component={Payments} />
          <Route exact component={NotFoundPage} />
        </Switch>
      </main>
    </Router>
  </>
);
