import React, { Component } from "react";
import TabPanel from "./TabPanel";

export default class UsersProfileSubPage extends Component {
  render() {
    const userId = this.props.match.params.id;
    return (
      <div>
        <TabPanel userId={userId} />
      </div>
    );
  }
}
