import React from "react";

const getDate = milisec => {
    return new Date(milisec).toLocaleString();
};

export const detailCellStyleColored = (isRed, fontWeight = 400) => ({
    border: "none",
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "1em",
    // backgroundColor: "rgb(247, 248, 250, 0.5)",
    // borderLeft: "1px solid rgba(224, 224, 224, 1)",
    color: isRed ? "red" : "green",
    fontWeight: fontWeight
});

export const firstHeaderStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f1f4f7",
    textTransform: "capitalize",
    border: "none",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "2em",
    paddingRight: "1em",
    paddingTop: "10px",
    paddingBottom: "10px",

};
export const firstCellStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "2em",
    paddingRight: "1em",
    border: "none",
};


export const detailCellStyle = {
    border: "none",
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "1em",
    backgroundColor: "rgb(247, 248, 250, 0.5)"
};

export const detailedHeaderStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f7f8fa",
    backgroundColor: "rgb(247, 248, 250, 0.6)",
    textTransform: "capitalize",
    border: "none",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "1em"
};

export const headerStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f1f4f7",
    textTransform: "capitalize",
    border: "none",
    // textAlign: "center",
    textAlign: "left",
    fontWeight: "600",
    // width: '100%',
    // width: "150",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
};
export const cellStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    border: "none",
    // paddingLeft: "3em",
    // paddingRight: "1em",
};

export const columns = [
    {
        title: "E-mail",
        field: "email",
        headerStyle: firstHeaderStyle,
        cellStyle: firstCellStyle
    },
    {
        title: "Base Currency",
        field: "base",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Bonus in Base",
        field: "profit",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Status",
        field: "status",
        headerStyle: headerStyle,
        // cellStyle: cellStyle
        cellStyle: cell => {
            return  detailCellStyleColored(cell === "BLOCKED", 600)
        }
    },
    {
        title: "Registration Date",
        field: "registeredAt",
        headerStyle: headerStyle,
        customSort: (a, b) => b.registeredAt - a.registeredAt,
        render: rowData => <div>{getDate(rowData.registeredAt)}</div>,
        defaultSort: "asc",
        cellStyle: cellStyle
    }
];

export const detailColumns = [
    {
        title: "Side",
        field: "side",
        headerStyle: detailedHeaderStyle,
        cellStyle: cell => {
            return  detailCellStyleColored(cell == "SELL", 600)
        }
    },
    {
        title: "Price",
        field: "price",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle,
    },
    {
        title: "Amount",
        field: "amount",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle
    },
    // {
    //   title: "Status",
    //   field: "status",
    //   headerStyle: detailedHeaderStyle,
    //   cellStyle: detailCellStyle
    // },
    {
        title: "Date",
        field: "date",
        type: "date",
        customSort: (a, b) => b.date - a.date,
        render: rowData => <div>{getDate(rowData.date)}</div>,
        defaultSort: "asc",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle
    }
];
