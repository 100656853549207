import React, {Component} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {getUserBalancesRequest} from "../../../../actions/user";
import {userIdSelector} from "../../../../selectors/user";
import {ColumnOptions, Options} from "../../../../helper/statistics/Options";
import {userBalancesSelector, userDealsChartSelector, userDealsSelector} from "../../../../selectors/user";
import "../../Home/styles/Home.scss";

const styles = () => ({
    root: {},
    message: {
        position: "relative",
        textAlign: "center",
        bottom: "5em"
    },
    invisible: {
        display: "none"
    }
});

class DealsChart extends Component {
    state = {}

    componentDidMount() {
        const {getChartData, userId} = this.props;
        getChartData(userId);
    }


    render() {
        const {classes, chartData, alignZoomButton, dealsData, dateRangeHandler, baseCurrency} = this.props;

        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"stockChart"}
                    // constructorType={"column"}
                    options={Options(chartData || [], alignZoomButton, baseCurrency, 2, dateRangeHandler)}
                    // options={ColumnOptions(chartData || [], alignZoomButton, 'USD', 2, dateRangeHandler)}
                />
                <div
                    className={
                        chartData.length === 0 ? classes.message : classes.invisible
                    }
                >
                    There is no data yet
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getChartData: userId => dispatch(getUserBalancesRequest(userId))
});

const mapStateToProps = state => ({
    chartData: userDealsChartSelector(state),
    dealsData: userBalancesSelector(state),
    userId: userIdSelector(state)
});

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(DealsChart);

// DealsChart.propTypes = {
//     classes: PropTypes.object.isRequired,
//     chartData: PropTypes.object,
//     getChartData: PropTypes.func
// };

// DealsChart.defaultProps = {
//     classes: {},
//     chartData: {},
//     getChartData: null
// };
