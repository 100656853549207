import {createSelector} from "reselect";
import moment from "moment";
import {roundCurrencyPrice, roundCurrencyVolume} from "../utils/calculations";
import {adminItems} from "../pages/MainPage/components/Header/components/headerMenu";

export const userSelector = state => state.user.user;

const userRefereesSelector = state => {
    return state.user.referees;
};

export const userDealsSelector = state => state.user.trades || {};
export const userBaseSelector = state => {
    let base = null
    state.user.trades && state.user.trades.deals.forEach(d => {
        if (!base) {
            base = d.base
        }
    })
    return base

};

export const isAdminUserSelector = state => (state.user.user.roles || []).includes("ROLE_ADMIN")

export const userBalancesSelector = state => state.user.balances || [];

export const userDealsChartSelector = state => {
    const result = {}
    const dates = []
    state.user.trades && state.user.trades.deals.forEach(d => {
        let date = moment(d.closeDate).startOf('day').toDate().getTime()
        if (result[date]) {
            result[date] += d.profit
        } else {
            dates.push(date)
            result[date] = d.profit
        }
    })
    const chartData = []
    dates.sort((a, b) => a - b);
    dates.forEach((key, index) => {
        chartData.push([key, result[key]])
    });
    return chartData;
}

export const userRolesSelector = state =>
    state.user.user ? state.user.user.roles : [];

export const userIdSelector = state =>
    state.user.user ? state.user.user.id : [];

export const lastBalanceSelector = state => {
    return state.userStatistic.liveBalance;
}

function openDealsWithTimeout(state) {
    // await setTimeout(() => {
    //
    // }, 1000);
    let clearVolumeInOpenDeals = 0
    let openDeals = state.user.openDeals ? state.user.openDeals.data || [] : []
    let base = null
    let profitPercent = state.user.user.tradingConfig.baseTradeConfig.profit
    let currentVolumeInBase = 0
    openDeals.forEach(od => {
        clearVolumeInOpenDeals += od.volume
        if (!base) {
            base = od.base
        }
        od.volume = roundCurrencyVolume(od.volume, base)
        od.currentPrice = roundCurrencyPrice(od.currentPrice, base)
        od.qty = roundCurrencyVolume(od.qty, base)

        currentVolumeInBase += od.qty * od.currentPrice
        od.diffInPercent = roundCurrencyPrice(od.diffInPercent, base, 2)
        od.expectedSellPrice = roundCurrencyPrice(((profitPercent + 0.1) / 100 + 1) * parseFloat(od.buyPrice), base)
        let openDate = 0
        od.orders.forEach(odo => {
            if (!openDate || openDate > odo.time) {
                openDate = odo.time
            }
        })
        od.openDate = openDate
    })
    let portfolio = 0
    let expectedProfit = (clearVolumeInOpenDeals * profitPercent) / 100;
    let availableBase = 0
    if (Object.keys(state.userStatistic.liveBalance).length > 0) {
        state.userStatistic.liveBalance.balances.forEach(lb => {
            if (lb.name === base) {
                portfolio += roundCurrencyPrice(parseFloat(lb.value), base)
            }
        })
        state.userStatistic.liveBalance.pureBalances && state.userStatistic.liveBalance.pureBalances.forEach(lb => {
            if (lb.asset === base) {
                availableBase = roundCurrencyPrice(parseFloat(lb.free), base)
            }
        })

    }
    let inOtherCurrencies = portfolio - currentVolumeInBase - availableBase

    inOtherCurrencies = roundCurrencyPrice(inOtherCurrencies < 0 ? 0 : inOtherCurrencies, base)
    let usedPercentOfDepo = (currentVolumeInBase / (clearVolumeInOpenDeals + availableBase)) * 100
    let expectedBalance = roundCurrencyPrice(clearVolumeInOpenDeals + availableBase + expectedProfit, base)


    return {
        usedVolumeOfDepo: currentVolumeInBase,
        expectedProfit,
        usedPercentOfDepo,
        deals: openDeals.length,
        base,
        openDeals: openDeals,
        portfolio,
        availableBase: availableBase,
        expectedBalance: expectedBalance,
        inOtherCurrencies: inOtherCurrencies
    }
}

function sleep (fn, par) {
    return new Promise((resolve) => {
        // wait 3s before calling fn(par)
        setTimeout(() => resolve(fn(par)), 1)
    })
}


export const openDealsStatsSelector = state => {
    return openDealsWithTimeout(state)
}


export const openDealsSelector = state =>
    state.user.openDeals;

export const userRefereesContentSelector = createSelector(
    userRefereesSelector,
    referees => {
        const tempResult = []

        referees.map(element => {
            Object.keys(element.profit).forEach(function (key, index) {
                tempResult.push({
                    ...element,
                    profit: roundCurrencyPrice(element.profit[key], key),
                    base: key,
                    id: element.email
                })
            });
        })

        const totalBonus = {}
        const activeFriends = []
        const totalFriends = []
        tempResult.forEach(c => {
            if (!totalFriends.includes(c.email)) {
                totalFriends.push(c.email)
            }
            if (!activeFriends.includes(c.email) && c.status === "ACTIVE") {
                activeFriends.push(c.email)
            }
            totalBonus[c.base] = (totalBonus[c.base] || 0) + c.profit
        })
        const result = {
            referees: tempResult,
            total: {
                totalBonus: totalBonus,
                activeFriends: activeFriends.length,
                totalFriends: totalFriends.length
            }
        }

        return result
    }
);

export const userReferralCodeSelector = state => state.user.referralCode;
