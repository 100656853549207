import React, { Component } from "react";
import Chart from "./components/Chart";
import Table from "./components/Table";
import Rates from "./components/Rates";
import "../../styles/TabPanel.scss";
import Home from "../../../../../../../user/Home/components/Home";
import TraderDiary from "../../../../../../../user/TraderDiary/components/TraderDiary";

class StatisticTab extends Component {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <div className="tab-panel__trader-diary">
        <div className="white-block">
          {/*<Rates userId={id} />*/}
          <Home userId={id}/>
          <TraderDiary userId={id}/>
          {/*<Chart userId={id} />*/}
          {/*<Table userId={id} />*/}
        </div>
      </div>
    );
  }
}
export default StatisticTab;
