import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { createUserRequest } from "../../../../../../actions/admin";
import { isFetchingSelector } from "../../../../../../selectors/app";
import {
  userStatusSelector,
  userMessageSelector
} from "../../../../../../selectors/admin";
import { CreateUserForm } from "./CreateUserForm";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../styles/create-user-form-container.scss";
import "../../../styles/users-page.scss";

const UserNewHeader = () => (
  <div className="subheader-new-user">
    <NavLink className="nav-title" to={"/admin/users"}>
      Users
    </NavLink>
    <span className="subtitle">New User</span>
  </div>
);

class CreateUserFormContainer extends Component {
  onSubmit = values => {
    const { email } = values;
    this.setState({ email: email });
    let registrationDto = { ...values };
    if (registrationDto.referralCode === "") {
      registrationDto.referralCode = null;
    }
    const { createUser } = this.props;
    createUser(registrationDto);
    toast(`User ${email} has successfully created`, {
      className: "toast"
    });
  };

  render() {
    const { isFetching, status, message } = this.props;
    return (
      <div className="create-user-form-container">
        <UserNewHeader />
        <div className="create-user-wrapper">
          <div className="white-container">
            <CreateUserForm
              onSubmitCallback={this.onSubmit}
              isFetching={isFetching}
              error={message}
            />
            {status === 200 ? <ToastContainer /> : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createUser: userDto => {
      dispatch(createUserRequest(userDto));
    }
  };
};

const mapStateToProps = state => ({
  isFetching: isFetchingSelector(state),
  status: userStatusSelector(state),
  message: userMessageSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserFormContainer);
