import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import MaterialTable from "@material-table/core";
import PropTypes from "prop-types";
import {
    isAdminUserSelector,
    lastBalanceSelector,
    openDealsSelector, openDealsStatsSelector,
    userDealsSelector
} from "../../../../selectors/user";
import {userIdSelector} from "../../../../selectors/user";
import {getUserDealsRequest, getUserOpenDealsRequest} from "../../../../actions/user";
import {getLiveBalanceRequest} from "../../../../actions/userStatistics";
import HomeChart from "../../Home/components/HomeChart";
import {columns, detailColumns, openDealsColumns} from "./TraderTable";
import {TradeData, MediaData} from "./TraderData";

import "../styles/TraderDiary.scss";
import "../../../admin/UsersPage/styles/users-page.scss";
import {getUserLastBalance, getUsersDeals} from "../../../../api/user";
import {roundCurrencyPrice, roundCurrencyVolume} from "../../../../utils/calculations";
import DealsChart from "./DealsChart";
import moment from "moment";
import {TraderSummary} from "./TradeSummary";
import {Paper} from "@material-ui/core";
import {Container} from "@material-ui/core";
import NewOrder from "./NewOrder";

let tableDeals = []

class TraderDiary extends Component {
    constructor() {
        super()
        this.dailyProfit = createRef()
        this.dealsRef = createRef()
        this.avgProfitByDealRef = createRef()
        this.avgProfitByDayRef = createRef()
        this.tableRef = createRef()
    }

    componentDidMount() {
        const {getDeals, getUsersOpenDeals, userId, getBalances} = this.props;
        // console.log('this.props ==>',this.props)
        getDeals(userId);
        getUsersOpenDeals(userId);
        setTimeout(() => {
            getBalances(userId);
        }, 500)

    }

    dateRangeHandler = (startDate, endDate) => {
        let profit = 0
        let deals = 0
        let days = moment(endDate).diff(moment(startDate), 'days') + 1

        let tabDeals = []
        let baseCurrency = 'USDT'
        this.props.trades.deals.forEach((d) => {
            const startOfDay = moment(d.closeDate).startOf('day')
            if (baseCurrency !== d.base) {
                baseCurrency = d.base
            }
            if (endDate >= startOfDay && startOfDay >= startDate) {
                profit += d.profit
                deals++;
                tabDeals.push(d)
            } else {
                // console.log("dateRangeHandler ===>", endDate, d.closeDate, startDate, d)
            }
        })
        tableDeals = tabDeals
        this.dailyProfit.current.innerHTML = `+${roundCurrencyPrice(profit, baseCurrency)}`
        this.dealsRef.current.innerHTML = `${deals}`
        this.avgProfitByDealRef.current.innerHTML = `+${deals ? roundCurrencyPrice(profit / deals, baseCurrency) : 0}`
        this.avgProfitByDayRef.current.innerHTML = `+${days ? roundCurrencyPrice(profit / days, baseCurrency) : 0}`
    }


    render() {
        const {
            trades: {deals, profit, profitPercent, avgDealProfit, tradesMap},
            userId,
            lastBalance,
            loading = false,
            openDeals,
            isAdminUser,
            openDealsStats = {}
        } = this.props;
        // console.log('openDealsStats ===>', openDealsStats, deals)
        let baseCurrency = "USDT";
        // console.log('deals ====>', deals, tradesMap)
        if (Array.isArray(deals) && deals.length > 0) {
            if (deals.map(el => el.base)[0].includes("USDT")) {
                baseCurrency = "USDT";
            }
            if (deals.map(el => el.base)[0].includes("BTC")) {
                baseCurrency = "BTC";
            }
            if (deals.map(el => el.base)[0].includes("ETH")) {
                baseCurrency = "ETH";
            }
            if (deals.map(el => el.base)[0].includes("BNB")) {
                baseCurrency = "BNB";
            }
            if (deals.map(el => el.base)[0].includes("BUSD")) {
                baseCurrency = "BUSD";
            }
        }

        const datePanel = [{
            render: ({rowData}) => {
                const trades_data = rowData.trades;
                return (
                    <MaterialTable
                        title="Trades"
                        columns={detailColumns}
                        components={{
                            Container: props => <Paper {...props} elevation={0}/>
                        }}
                        data={trades_data.map(trade => {
                            return {
                                side: "BUY",
                                price: trade.price,
                                amount: trade.qty,
                                quoteQty: trade.quoteQty,
                                status: trade.status,
                                date: trade.time
                            };
                        })}
                        options={{
                            toolbar: false,
                            paging: false,
                            sorting: false,

                        }}
                    />
                );
            }
        },
            {
                icon: 'add',
                openIcon: "close",
                render: ({rowData}) => <NewOrder openDeal={rowData.original}
                                                 availableBase={openDealsStats.availableBase}/>
            }]

        return (
            <div className="trader-diary users-page">
                <div className="page-content">
                    <div className="block-white">
                        <div className="title margin-none">Deals Summary</div>
                        <div className="trader-summary">
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Base Currency</div>
                                <div className="trader-summary__block__data green">
                                    {baseCurrency}
                                </div>
                            </div>
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Total profit</div>
                                <div ref={this.dailyProfit} className="trader-summary__block__data green"/>
                            </div>
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Number of deals</div>
                                <div ref={this.dealsRef} className="trader-summary__block__data"/>
                            </div>

                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Avg profit by deal</div>
                                <div ref={this.avgProfitByDealRef} className="trader-summary__block__data green"/>
                            </div>

                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Avg profit by day</div>
                                <div ref={this.avgProfitByDayRef} className="trader-summary__block__data green"/>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            <div className="chart-container" style={{width: '100%', zoom: "133.5%"}}>
                                {/*<div className="chart-container" style={{width: '100%'}}>*/}
                                <DealsChart userId={{userId}} baseCurrency={baseCurrency}
                                            dateRangeHandler={this.dateRangeHandler}/>
                            </div>
                        </div>
                    </div>
                    <div className="block-white">
                        <div className="subtitle">Opened Deals</div>
                        <div className="trader-summary opened-deals">
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Expected Profit</div>
                                <div className="trader-summary__block__data yellow">
                                    {`≈ +${roundCurrencyPrice(openDealsStats.expectedProfit, baseCurrency)}`}
                                    {/*<br /> (*/}
                                    {/*{!isNaN(profitPercent) ? parseFloat(profitPercent).toFixed(2) : 0} %)*/}
                                </div>
                            </div>
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Expected Portfolio</div>
                                <div className="trader-summary__block__data yellow">
                                    {`${roundCurrencyPrice(openDealsStats.expectedBalance, baseCurrency)}`}
                                    {/*<br /> (*/}
                                    {/*{!isNaN(profitPercent) ? parseFloat(profitPercent).toFixed(2) : 0} %)*/}
                                </div>
                            </div>
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Number of deals</div>
                                <div className="trader-summary__block__data">
                                    {openDealsStats.deals ? `${openDealsStats.deals}` : "0"}
                                </div>
                            </div>

                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">Used Percent</div>
                                <div className="trader-summary__block__data">
                                    {openDealsStats.usedPercentOfDepo ? `${openDealsStats.usedPercentOfDepo.toFixed(2)}` : "0"}%
                                </div>
                            </div>

                            {/*<div className="trader-summary__block">*/}
                            {/*    <div className="trader-summary__block__title">Used Base Currency</div>*/}
                            {/*    <div className="trader-summary__block__data">*/}
                            {/*        {baseCurrency === 'USDT' ? '$' : baseCurrency}{openDealsStats.usedVolumeOfDepo ? `${openDealsStats.usedVolumeOfDepo.toFixed(2)}` : "0"}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">{`Available ${baseCurrency}`}</div>
                                <div className="trader-summary__block__data">
                                    ≈ {roundCurrencyPrice(openDealsStats.availableBase, baseCurrency)}
                                </div>
                            </div>
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">{`Portfolio ${baseCurrency}`}</div>
                                <div className="trader-summary__block__data">
                                    {roundCurrencyPrice(openDealsStats.portfolio, baseCurrency)}
                                </div>
                            </div>
                            <div className="trader-summary__block">
                                <div className="trader-summary__block__title">{`In Other ${baseCurrency}`}</div>
                                <div className="trader-summary__block__data">
                                    {roundCurrencyPrice(openDealsStats.inOtherCurrencies, baseCurrency)}
                                </div>
                            </div>
                        </div>

                        <div style={{}}>
                            {/*<NewOrder/>*/}

                            <MaterialTable
                                isLoading={loading}
                                options={{
                                    emptyRowsWhenPaging: false,
                                    sorting: true,
                                    exportButton: false,
                                    search: false,
                                    searchAutoFocus: true,
                                    toolbar: false,
                                    paging: false,
                                    headerStyle: {
                                        backgroundColor: 'rgb(241, 244, 247)',
                                    }
                                }}
                                components={{
                                    Container: props => <Paper {...props} elevation={0}/>
                                }}
                                columns={openDealsColumns}
                                data={
                                    openDealsStats.openDeals
                                        ? openDealsStats.openDeals.map(deal => {
                                            return {
                                                id: deal.id,
                                                pair: deal.pair,
                                                buyPrice: deal.buyPrice,
                                                currentPrice: deal.currentPrice,
                                                amount: deal.qty,
                                                openReason: deal.openReason,
                                                expectedSellPrice: deal.expectedSellPrice,
                                                // profit: deal.profit ? deal.profit.toFixed(5) : 0,
                                                diff: deal.diffInPercent,
                                                tradesCount: (Array.isArray(deal.trades) ? deal.trades : []).length,
                                                trades: Array.isArray(deal.trades) ? deal.trades : [],
                                                openDate: deal.openDate,
                                                value: deal.volume,
                                                original: deal
                                            }
                                        }
                                        )
                                        : []
                                }

                                // data={query =>
                                //     new Promise((resolve, reject) => {
                                //         console.log('query ===>', query)
                                //         let uri = `size=${query.pageSize}&page=${query.page}`
                                //
                                //         if (query.orderBy) {
                                //             uri += `&sort=${query.orderBy.field},${query.orderDirection}`
                                //         }
                                //
                                //         getUsersDeals(this.props.userId, uri)
                                //             .then(result => {
                                //                 console.log('result  ====>', result)
                                //
                                //
                                //                 // result.data.content
                                //                 //     ? result.data.content.map(deal => ({
                                //                 //         id: deal.id,
                                //                 //         pair: deal.pair,
                                //                 //         buyPrice: deal.buyPrice,
                                //                 //         sellPrice: deal.sellPrice,
                                //                 //         amount: deal.amount,
                                //                 //         profit: deal.profit ? deal.profit.toFixed(5) : 0,
                                //                 //         diff: deal.diff.toFixed(5),
                                //                 //         tradesCount: (Array.isArray(deal.trades) ? deal.trades : []).length,
                                //                 //         trades: Array.isArray(deal.trades) ? deal.trades : [],
                                //                 //         openDate: deal.openDate,
                                //                 //         closeDate: deal.closeDate,
                                //                 //         value: deal.profit
                                //                 //             ? (deal.profit * deal.amount).toFixed(5)
                                //                 //             : 0
                                //                 //     }))
                                //                 //     : []
                                //
                                //                 resolve({
                                //                     // data: result.data.content.filter(deal => JSON.stringify(deal).toLowerCase().includes(query.search.toLowerCase())),
                                //                     data: result.data.content
                                //                         ? result.data.content.map(deal => ({
                                //                             id: deal.id,
                                //                             pair: deal.pair,
                                //                             buyPrice: deal.buyPrice,
                                //                             sellPrice: deal.sellPrice,
                                //                             amount: deal.amount,
                                //                             profit: deal.profit ? deal.profit.toFixed(5) : 0,
                                //                             diff: deal.diff.toFixed(2),
                                //                             tradesCount: (Array.isArray(deal.trades) ? deal.trades : []).length,
                                //                             trades: Array.isArray(deal.trades) ? deal.trades : [],
                                //                             openDate: deal.openDate,
                                //                             closeDate: deal.closeDate,
                                //                             value: deal.volume.toFixed(5)
                                //                         }))
                                //                         : [],
                                //                     page: result.data.pageable.pageNumber,
                                //                     totalCount: result.data.totalElements,
                                //                 })
                                //             })
                                //     })}

                                // title={<div className="subtitle">Deals History</div>}
                                title={""}
                                detailPanel={
                                    isAdminUser ? datePanel : [datePanel[0]]
                                }
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                            />
                        </div>
                    </div>

                    <div className="white-table">
                        {/*<div className="subtitle">Closed Deals</div>*/}
                        <MaterialTable
                            isLoading={loading}
                            options={{
                                emptyRowsWhenPaging: false,
                                sorting: true,
                                exportButton: true,
                                search: true,
                                searchAutoFocus: false,
                                headerStyle: {
                                    backgroundColor: 'rgb(241, 244, 247)',
                                }
                            }}
                            components={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            columns={columns}
                            data={
                                deals
                                    ? deals.map(deal => {
                                        return {
                                            id: deal.id,
                                            pair: deal.pair,
                                            buyPrice: deal.buyPrice,
                                            sellPrice: deal.sellPrice,
                                            amount: deal.qty,
                                            profit: deal.profit ? deal.profit.toFixed(5) : 0,
                                            diff: deal.diff.toFixed(5),
                                            tradesCount: (Array.isArray(deal.trades) ? deal.trades : []).length,
                                            trades: Array.isArray(deal.trades) ? deal.trades : [],
                                            openDate: deal.openDate,
                                            closeDate: deal.closeDate,
                                            value: deal.volume
                                                ? deal.volume.toFixed(5)
                                                : 0
                                        }
                                    }
                                    )
                                    : []
                            }
                            title={"Closed Deals"}
                            detailPanel={({rowData}) => {
                                const trades_data = rowData.trades || [];
                                return (
                                    <MaterialTable
                                        title="Trades"
                                        columns={detailColumns}
                                        components={{
                                            Container: props => <Paper {...props} elevation={0}/>
                                        }}
                                        data={trades_data.map(trade => {
                                            // console.log('trade ===>', trade)
                                            return {
                                                side: trade.side,
                                                price: trade.price,
                                                amount: trade.qty,
                                                quoteQty: (trade.qty * trade.price).toFixed(4),
                                                status: trade.status,
                                                date: trade.date
                                            };
                                        })}
                                        options={{
                                            toolbar: false,
                                            paging: false,
                                            sorting: false
                                        }}
                                    />
                                );
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    trades: userDealsSelector(state),
    userId: ownProps.userId || userIdSelector(state),
    lastBalance: lastBalanceSelector(state),
    openDeals: openDealsSelector(state),
    openDealsStats: openDealsStatsSelector(state),
    isAdminUser: isAdminUserSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getDeals: userId => dispatch(getUserDealsRequest(userId)),
    getUsersOpenDeals: userId => dispatch(getUserOpenDealsRequest(userId)),
    getBalances: userId => dispatch(getLiveBalanceRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TraderDiary);
