import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  userIdSelector,
  userRolesSelector
} from "../../../../../selectors/user";
import ProfileMenu from "./ProfileMenu";
import { adminItems, userItems } from "./headerMenu";
import "../styles/header.scss";

const drawItem = item => {
  return (
    <NavLink
      key={item.link}
      to={item.link}
      className="nav-item"
      activeClassName="selected"
    >
      {item.text}
    </NavLink>
  );
};

const HeaderMenu = ({ userRoles }) => {
  let items = [];
  if (userRoles.includes("ROLE_ADMIN")) {
    items.push(...adminItems);
  }
  if (userRoles.includes("ROLE_CLIENT")) {
    items.push(...userItems);
  }
  return items.map(drawItem);
};

class Header extends React.Component {
  render() {
    const { userRoles } = this.props;

    return (
      <header>
        <div className="flex-wrapper">
          <div className="nav-flex-wrapper">
            <NavLink
              className="logo"
              to={
                userRoles.includes("ROLE_CLIENT")
                  ? "/user/home"
                  : "/admin/users"
              }
            />
            <div className="menu-items">
              <HeaderMenu userRoles={userRoles} />
            </div>
          </div>
          <ProfileMenu />
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  userRoles: userRolesSelector(state),
  userId: userIdSelector(state)
});

export default connect(mapStateToProps)(Header);
