import React, {Component} from "react";
import "./styles/trading-settings-tab.scss";
import {Button, IconButton} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {connect} from "react-redux";
import {FieldArray, Formik} from "formik";
import {isFetchingSelector} from "../../../../../../../../selectors/app";
import {FormikTextField} from "../../../../../../../../components/formik/FormikTextField";
import FormikSelectField from "../../../../../../../../components/formik/FormikSelectField";
import {UserTradingSettingsSchema} from "./validators/trading-settings";
import {
    getUserTradingSettingsRequest,
    updateUserTradingSettingsRequest
} from "../../../../../../../../actions/admin";
import {tradingSettingsSelector} from "../../../../../../../../selectors/admin";
import RadioButtons from "./components/RadioButtons";

import FormikAutoCompleteField from "../../../../../../../../components/formik/FormikAutocompleteField";


class TradingSettings extends Component {
    state = {
        radioSet: null,
        base_options: [],
        asset_options: [],
        candle_stick_options: [
            {label: '15m', value: '15m', disabled: true},
            {label: '30m', value: '30m'},
            {label: '1h', value: '1h'},
            {label: '4h', value: '4h'},
            {label: '1d', value: '1d'},
        ],
    };

    componentDidMount() {
        const {
            match: {
                params: {id}
            },
            getUserTradingSettings
        } = this.props;
        getUserTradingSettings(id);
        fetch("https://api.binance.com/api/v3/exchangeInfo")
            .then(res => res.json())
            .then(
                (result) => {
                    const base_options = new Set()
                    const asset_options = new Set()
                    result.symbols.map(s => {
                        if (s.status === "TRADING") {
                            base_options.add(s.quoteAsset)
                            asset_options.add(s.baseAsset)
                        }
                    })
                    this.setState({
                        isLoaded: true,
                        symbols: result.symbols.filter(s => s.status === "TRADING"),
                        base_options: Array.from(base_options).map(s => this.toOptions(s)),
                        asset_options: Array.from(asset_options).map(s => this.toOptions(s))
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    toOptions = (s) => ({label: s, value: s})

    addPair = (arrayHelpers, index) =>
        arrayHelpers.insert(index + 1, {
            base: "",
            alt: "",
            pair: ""
        });
    addNewPair = arrayHelpers =>
        arrayHelpers.push({
            base: "",
            alt: "",
            pair: ""
        });

    onRadioChange = value => {
        const radioSet = {
            stopLossProfit: false,
            dynamicProfit: false,
            fixedProfit: false
        };
        this.setState({radioSet: {...radioSet, ...value}});
    };

    removePair = (arrayHelpers, index) => arrayHelpers.remove(index);

    onTradingSettingsSubmit = values => {
        const {radioSet = {}} = this.state;
        const {type, baseTradeConfig = {}, ...rest} = values;
        const changedTradeConfig = {...baseTradeConfig, ...radioSet};
        const {
            match: {
                params: {id}
            },
            updateUserTradingSettings
        } = this.props;

        console.log("values: ", values)

        const confirmBox = window.confirm(
            "Do you wanna update trading settings?"
        )
        if (confirmBox === true) {
            console.log("newOrderSubmit: ", values)
            updateUserTradingSettings({
                userId: id,
                tradingSettings: {baseTradeConfig: changedTradeConfig, ...rest}
            })

            // toast.promise(
            //    ,
            //     {
            //         pending: 'Promise is pending',
            //         success: 'Promise resolved 👌',
            //         error: 'Promise rejected 🤯'
            //     }
            // )


        }


    };


    render() {
        const {isFetching, tradingSettings, status} = this.props || {};
        const {base_options, asset_options, candle_stick_options} = this.state || {};
        // const tradingConfig = {
        //     belowAverage: 0.0,
        //     candleStickIntervalId: "1h",
        //     dynamicProfit: true,
        //     emaPeriod: 15,
        //     fee: 0.1,
        //     fixedProfit: false,
        //     profit: 3.0,
        //     stopLossProfit: false,
        //     tradeMultiply: 12,
        // }

        return (
            <div className="trading-settings-tab">
                <div className="white-block">
                    <Formik
                        enableReinitialize={true}
                        initialValues={{...tradingSettings}}
                        validationSchema={UserTradingSettingsSchema}
                        onSubmit={this.onTradingSettingsSubmit}
                    >
                        {({handleSubmit, values, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-container">
                                    <RadioButtons
                                        className={"radio-btns"}
                                        onChange={this.onRadioChange}
                                        values={
                                            tradingSettings && tradingSettings.baseTradeConfig
                                                ? {
                                                    fixedProfit:
                                                    tradingSettings.baseTradeConfig.fixedProfit,

                                                    // dynamicProfit:
                                                    // tradingSettings.baseTradeConfig.dynamicProfit,

                                                    stopLossProfit:
                                                    tradingSettings.baseTradeConfig.stopLossProfit
                                                }
                                                : []
                                        }
                                    />
                                    <div className="flex-start">
                                        <LoadingButton type="submit" size="large" loading={isFetching}
                                                       variant="contained">
                                            Update
                                        </LoadingButton>
                                    </div>
                                </div>
                                <div className="flex-container base-trade-config">
                                    <FormikTextField
                                        label="Below Average"
                                        name="baseTradeConfig.belowAverage"
                                        className={"basic-field"}
                                    />
                                    <FormikSelectField
                                        label="Candle Stick Interval ID"
                                        name="baseTradeConfig.candleStickIntervalId"
                                        options={candle_stick_options}
                                        className="basic-field"
                                        value={values.baseTradeConfig ? values.baseTradeConfig.candleStickIntervalId : ''}
                                        onChange={(e) => {
                                            setFieldValue(
                                                `baseTradeConfig.candleStickIntervalId`,
                                                e.target.value
                                            );
                                        }}
                                    />
                                    <FormikTextField
                                        label="Ema Period"
                                        name="baseTradeConfig.emaPeriod"
                                        className="basic-field"
                                    />
                                    <FormikTextField
                                        label="Fee"
                                        name="baseTradeConfig.fee"
                                        className="basic-field"
                                    />
                                    <FormikTextField
                                        label="Profit"
                                        name="baseTradeConfig.profit"
                                        className="basic-field"
                                    />
                                    <FormikTextField
                                        label="Trade Multiplier"
                                        name="baseTradeConfig.tradeMultiply"
                                        className={"basic-field"}
                                    />
                                </div>


                                <div className="trading-pairs-title">Trading Pairs</div>
                                <FieldArray
                                    name="tradingPairs"
                                    render={arrayHelpers => (
                                        <div className={"trading-pairs"}>
                                            {values.tradingPairs &&
                                            values.tradingPairs.length > 0 ? (
                                                values.tradingPairs.map((tradingPair, index) => (
                                                    <div
                                                        key={index}
                                                        className="pair-field"
                                                    >
                                                        <FormikAutoCompleteField
                                                            label="Base"
                                                            name={`tradingPairs.${index}.base`}
                                                            className={"basic-field__base"}
                                                            options={base_options}
                                                            // freeSolo={true}
                                                            // disablePortal={true}
                                                            // autoHighlight={true}
                                                            value={tradingPair.base}
                                                            onChange={(e, newValue) => {
                                                                let value = (newValue || {}).value
                                                                if (!value) {
                                                                    value = ""
                                                                }
                                                                setFieldValue(
                                                                    `tradingPairs.${index}.base`,
                                                                    value
                                                                );
                                                                setFieldValue(
                                                                    `tradingPairs.${index}.pair`,
                                                                    tradingPair.alt + value
                                                                );
                                                            }}
                                                        />

                                                        <FormikAutoCompleteField
                                                            label="Alt"
                                                            name={`tradingPairs.${index}.alt`}
                                                            className={"basic-field__alt"}
                                                            options={asset_options}
                                                            // disablePortal={true}
                                                            // autoHighlight={true}
                                                            // id="controllable-states-demo"
                                                            // freeSolo={true}
                                                            value={tradingPair.alt}
                                                            onChange={(e, newValue) => {
                                                                let value = (newValue || {}).value
                                                                if (!value) {
                                                                    value = ""
                                                                }
                                                                setFieldValue(
                                                                    `tradingPairs.${index}.alt`,
                                                                    value
                                                                );
                                                                setFieldValue(
                                                                    `tradingPairs.${index}.pair`,
                                                                    value + tradingPair.base
                                                                );
                                                            }}
                                                        />

                                                        <FormikTextField
                                                            label="Pair"
                                                            name={`tradingPairs.${index}.pair`}
                                                            disabled
                                                        />
                                                        <div className="pair-field__btns">
                                                            <IconButton
                                                                className="btn btn-plus"
                                                                onClick={() =>
                                                                    this.addPair(arrayHelpers, index)
                                                                }
                                                            />
                                                            <IconButton
                                                                className="btn btn-minus"
                                                                onClick={() =>
                                                                    this.removePair(arrayHelpers, index)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <Button variant="outlined" className="add-new"
                                                        onClick={() => this.addNewPair(arrayHelpers)}>New pair</Button>
                                            )}
                                        </div>
                                    )}
                                />
                            </form>
                        )}
                    </Formik>
                    {/*{status === 200 ?  : null}*/}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tradingSettings: tradingSettingsSelector(state),
    isFetching: isFetchingSelector(state),
    status: state.admin.status
});

const mapDispatchToProps = dispatch => ({
    getUserTradingSettings: userId =>
        dispatch(getUserTradingSettingsRequest(userId)),
    updateUserTradingSettings: data =>
        dispatch(updateUserTradingSettingsRequest(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradingSettings);
