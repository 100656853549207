import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { MuiThemeProvider } from "@material-ui/core/styles/index";
import configureStore from "store/configure-store";

import * as serviceWorker from "./serviceWorker";
import { App } from "./App";

import theme from "theme";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { history } from "./historyInstance";

require("dotenv").config();
const { store, persistor } = configureStore();

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
