import axios from "axios";
import { store } from "../store/configure-store";

const apiVersion = "/api/v1";
const urlAddress = process.env.REACT_APP_API_URL || "http://localhost:8080";
const baseURL = urlAddress + apiVersion;

const clientAPI = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const handleError = error => {
  return Promise.reject(error.response);
};

clientAPI.interceptors.request.use(function(config) {
  const jwt = store.getState().auth.jwt;

  if (jwt && !config.url.includes("refresh-token")) {
    config.headers.Authorization = jwt.accessToken;
  }
  return config;
});

clientAPI.interceptors.response.use(
  response => {
    if (response.status > 399) {
      return Promise.reject(response);
    }
    return response;
  },
  error => {
    return handleError(error);
  }
);

export default clientAPI;

// "$2a$10$Aj1oSRNtA4bVjGvbKya.EO6KHSNh./HQtCISJM9QtOpjUmBI55UFe"
//  Vt6glj2S
