import React from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {jwtSelector} from "../../selectors/auth";
import {userRolesSelector} from "../../selectors/user";

function AdminRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={props =>
                Boolean(rest.token) && rest.userRoles.includes("ROLE_ADMIN") ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps = state => ({
    token: jwtSelector(state),
    userRoles: userRolesSelector(state)
});

export default connect(mapStateToProps)(AdminRoute);
