import React, {Component} from "react";
import {ToastContainer, toast} from "react-toastify";
import {connect} from "react-redux";
import {userSelector} from "../../../../selectors/user";
import {getUserRequest} from "../../../../actions/admin";
import "../../../admin/UsersPage/components/CreateUserSubPage/styles/create-user-form-container.scss";
import "../styles/TelegramCode.scss";
import {ProfileUserForm} from "./ProfileUserForm";
import {ChangePasswordForm} from "./ChangePasswordForm";
import CustomCopyToClipboard from "../../../../components/CustomCopyToClipboard/components";
const tb = process.env.REACT_APP_TELEGRAM_BOT
const UserNewHeader = () => (
    <div className="subheader-new-user">
        <span className="subtitle">User Profile</span>
    </div>
);

class TelegramCode extends Component {
    onSubmit = values => {
        const {email} = 'values';
        // this.setState({ email: email });
        // let registrationDto = { ...values };
        // if (registrationDto.referralCode === "") {
        //   registrationDto.referralCode = null;
        // }
        // const { createUser } = this.props;
        // createUser(registrationDto);
        // toast(`User ${email} has successfully created`, {
        //   className: "toast"
        // });
    };

    render() {
        const {isFetching, status, message, user} = this.props;

        return (
            <div>
                <div className="telegram-code">
                    <div className="title-block">Telegram token</div>
                    <div className="white-block referral-code-block code">
                        <CustomCopyToClipboard text={user.id}/>
                    </div>
                </div>
                <div>
                    <p
                        style={{lineHeight: '25px'}}
                    >{`Please follow instructions to connect telegram bot:`}
                        <br/>
                        <br/>1. <a href={`https://t.me/${tb}`} target="_blank">
                            {`Navigate to ${tb}`}
                        </a>
                        <br/>2. Enter text <b>/start</b> to start communication with bot.
                        <br/>3. As an answer you should receive the next message: <b>Hello {`{your name}`}, please enter your telegram token:</b>
                        <br/>4. Send above <b>Telegram token</b> to chat bot.
                        <br/>5. As a confirmation you should receive <b>Scrooge Bot successfully connected!</b> message.
                        <br/>6. If you received <b>Incorrect telegram token!</b>, please try to connect again.
                        <br/>
                        <br/>In all other cases please contact our support.
                    </p>
                </div>



            </div>



        );
    }
}

const mapDispatchToProps = dispatch => ({
    getUser: id => dispatch(getUserRequest(id))
});

const mapStateToProps = state => ({
    user: userSelector(state)
    // isFetching: isFetchingSelector(state),
    // status: userStatusSelector(state),
    // message: userMessageSelector(state)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TelegramCode);
