// Define all routes which would be used as navigation to internal resources (pages)

export const root = "/";
export const notFound = "/not-found";
export const statistics = "/admin/users/:id/statistics";
export const referees = "/admin/users/:id/referees";
export const transactions = "/admin/users/:id/transactions";
export const userActivity = "/admin/users/:id/activity";
export const logs = "/admin/users/:id/logs";
export const userSettings = "/admin/users/:id/user-settings";
export const tradingSettings = "/admin/users/:id/trading-settings";
export const userReferees = "/user/referees";
export const userHome = "/user/home";
export const userTraderDiary = "user/trader-diary";
export const userPayments = "user/payments";
