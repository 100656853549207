import React, {Component} from "react";
import {connect} from "react-redux";
import CustomCopyToClipboard from "../../../../components/CustomCopyToClipboard/components";
import PageableTable from "../../../../components/PageableTable/components";
import {userIdSelector} from "../../../../selectors/user";
import {
    userRefereesContentSelector,
    userReferralCodeSelector
} from "../../../../selectors/user";
import {
    getUserRefereesRequest,
    getUserReferralCodeRequest
} from "../../../../actions/user";
import "../../../admin/UsersPage/styles/users-page.scss";
import "../styles/Referees.scss";

import MaterialTable from "@material-table/core";
import {columns, detailColumns} from "./RefferalsTable";
import "../../../../styles/variables.scss";
import {roundCurrencyPrice} from "../../../../utils/calculations";
import Spinner from "../../../../components/Spinner";
import {Paper} from "@material-ui/core";
import moment from "moment";
import SyncIcon from "@material-ui/icons/Sync";

class Referees extends Component {
    state = {
        totalBonus: {},
        activeFriends: 0,
        totalFriends: 0
    }

    componentDidMount() {
        const {user = {}, getUserReferees, userId, refereesLastUpdate, refereesUserId, isUserRefereesLoading} = this.props;
        const isAdmin = (user.roles || []).includes("ROLE_ADMIN")
        const localUserId = isAdmin ? userId : user.id
        if (localUserId != null && (!refereesLastUpdate || refereesUserId !== localUserId || isUserRefereesLoading)) {
            getUserReferees(localUserId);
        }
    }

    title = () => {
        const {refereesLastUpdate, user, getUserReferees, userId} = this.props;
        return <div className="last-update">
            <div className="margin-none">Last
                sync: <span>{moment(refereesLastUpdate).format('MMMM Do YYYY, HH:mm:ss')}</span></div>
            <SyncIcon className="last-update_btn" onClick={() => {
                if (userId) {
                    getUserReferees(userId);
                } else {
                    getUserReferees(user);
                }
            }}>Sync</SyncIcon>
        </div>
    }

    render() {
        const {content, isUserRefereesLoading = false} = this.props;
        const {total, referees} = content
        const {totalBonus, activeFriends, totalFriends} = total;
        return (
            <div className="users-page user-referees">
                <Spinner className={"user-referees__spinner"} isLoading={isUserRefereesLoading}/>
                <div className="page-content">
                    <div className="white-block">
                        <div className="title">Referrals Overview</div>
                        <div className="user-referees__overview">
                            {Object.keys(totalBonus).map(key => {

                                return (<div key={`totalBonus_${key}`} className="user-referees__block">
                                    <div className="user-referees__block__title">{`Total Bonus by ${key}`}</div>
                                    <div className="user-referees__block__data green">
                                        {`${roundCurrencyPrice(totalBonus[key], key, null, true)}`}
                                    </div>
                                </div>)
                            })}


                            <div className="user-referees__block">
                                {/*<div className="title-block">Referral code</div>*/}
                                <div className="user-referees__block__title">Active Number of Friends</div>
                                <div className="user-referees__block__data">
                                    {/*{totalFriends === numberOfFriends ? `${totalFriends}` : `${numberOfFriends} of ${content.length}`}*/}
                                    {activeFriends}
                                </div>
                                {/*<CustomCopyToClipboard text={refCode || ""}/>*/}
                            </div>
                            <div className="user-referees__block">
                                {/*<div className="title-block">Referral code</div>*/}
                                <div className="user-referees__block__title">Total Number of Friends</div>
                                <div className="user-referees__block__data">
                                    {/*{totalFriends === numberOfFriends ? `${totalFriends}` : `${numberOfFriends} of ${content.length}`}*/}
                                    {totalFriends}
                                </div>
                                {/*<CustomCopyToClipboard text={refCode || ""}/>*/}
                            </div>
                        </div>
                    </div>
                    {/*<div className="white-block referral-code-block code">*/}
                    {/*    <div className="title-block">Referral code</div>*/}
                    {/*    <CustomCopyToClipboard text={refCode || ""}/>*/}
                    {/*</div>*/}

                    <div className="white-block">
                        <MaterialTable
                            isLoading={isUserRefereesLoading}
                            title={this.title()}
                            components={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            options={{
                                emptyRowsWhenPaging: false,
                                sorting: true,
                                exportButton: false,
                                search: true,
                                searchAutoFocus: false,
                                toolbar: true,
                                paging: false,
                                headerStyle: {
                                    backgroundColor: 'rgb(241, 244, 247)',
                                }

                            }}
                            columns={columns}
                            data={
                                referees
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // refCode: userReferralCodeSelector(state),
    content: userRefereesContentSelector(state),
    refereesLastUpdate: state.user.refereesLastUpdate,
    refereesUserId: state.user.refereesUserId,
    user: state.user.user,
    isUserRefereesLoading: state.ui.isUserRefereesLoading
});

const mapDispatchToProps = dispatch => ({
    getUserReferees: userId => dispatch(getUserRefereesRequest(userId)),
    getUserReferralCode: userId => dispatch(getUserReferralCodeRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Referees);
