import React, {Component} from "react";
import PropTypes from "prop-types";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {withStyles} from "@material-ui/core";
import "../styles/CustomCopyToClipboard.scss";

const styles = theme => ({
    button: {
        margin: theme.spacing(1)
    },
    leftIcon: {
        marginRight: theme.spacing(1)
    }
});

class CustomCopyToClipboard extends Component {
    state = {
        isVisible: false
    };

    render() {
        const {text, onCopyCallback} = this.props;
        const {isVisible} = this.state;
        return (
            <div className="custom-clipboard">
                <div className={'ref-code'}>{text}</div>
                <CopyToClipboard text={text} onCopy={onCopyCallback}>
                    <div
                        className="copy-btn copy-button"
                        onClick={() => this.setState({isVisible: true}, () => {
                            setTimeout(()=> {
                                this.setState({isVisible: false})
                            }, 700)
                        })}
                    />
                </CopyToClipboard>
                <div className={isVisible ? "copied" : "invisible"}>Copied!</div>
            </div>
        );
    }
}

export default withStyles(styles)(CustomCopyToClipboard);

CustomCopyToClipboard.propTypes = {
    text: PropTypes.string
};

CustomCopyToClipboard.defaultProps = {
    text: ""
};
