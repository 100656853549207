import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import MaterialTable, {MTableBodyRow, MTablePagination} from "@material-table/core";
import {Paper} from "@material-ui/core";
import {
    lastBalanceSelector,
    openDealsSelector, openDealsStatsSelector,
    userDealsSelector
} from "../../../../../../selectors/user";
import {userIdSelector} from "../../../../../../selectors/user";
import {getUserDealsRequest, getUserOpenDealsRequest} from "../../../../../../actions/user";
import {getLiveBalanceRequest} from "../../../../../../actions/userStatistics";
import {
    transactionColumns,
    detailColumns,
    openDealsColumns,
    referralTransactionsColumns, columns, columnsDetails
} from "../../../../TraderDiary/components/TraderTable";
import "../styles/user-transactions.scss";
import moment from "moment";
import {getReferralTransactions, getUsersDealsHistory, getUsersTransactions} from "../../../../../../api/user";

let tableDeals = []

class UserTransactions extends Component {

    constructor() {
        super()
        this.dailyProfit = createRef()
        this.dealsRef = createRef()
        this.avgProfitByDealRef = createRef()
        this.avgProfitByDayRef = createRef()
        this.tableRef = createRef()
    }

    componentDidMount() {
        const {getDeals, getUsersOpenDeals, userId, getBalances} = this.props;
        getBalances(userId);
        getDeals(userId);
        getUsersOpenDeals(userId);
    }

    dateRangeHandler = (startDate, endDate) => {
        let profit = 0
        let deals = 0
        let days = moment(endDate).diff(moment(startDate), 'days') + 1

        let tabDeals = []
        this.props.trades.deals.forEach((d) => {
            const startOfDay = moment(d.closeDate).startOf('day')
            if (endDate >= startOfDay && startOfDay >= startDate) {
                profit += d.profit
                deals++;
                tabDeals.push(d)
            } else {
                // console.log("dateRangeHandler ===>", endDate, d.closeDate, startDate, d)
            }
        })
        tableDeals = tabDeals
        this.dailyProfit.current.innerHTML = `+$${profit.toFixed(2)}`
        this.dealsRef.current.innerHTML = `${deals}`
        this.avgProfitByDealRef.current.innerHTML = `+$${deals ? (profit / deals).toFixed(2) : 0}`
        this.avgProfitByDayRef.current.innerHTML = `+$${days ? (profit / days).toFixed(2) : 0}`
    }

    render() {
        const {
            title, id
        } = this.props;
        return (
            <div className="trader-diary users-page">
                <div className="page-content">
                    <div className="white-table">
                        <div className="subtitle">{title}</div>
                        <MaterialTable

                            classes={{
                                // actions: {
                                //     color: 'green'
                                // }
                            }}
                            options={{
                                emptyRowsWhenPaging: false,
                                sorting: true,
                                toolbar: false,
                                // exportButton: true,
                                // search: true,
                                // searchAutoFocus: true,
                                headerStyle: {
                                    backgroundColor: 'rgb(241, 244, 247)',
                                    letterSpacing: "1px",
                                    color: "#000000",
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    // backgroundColor: "#f1f4f7",
                                    textTransform: "capitalize",
                                    // border: "none",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    // width: '100%',
                                    paddingLeft: "0",
                                    paddingRight: "0",
                                },
                                actionsCellStyle: {
                                    // color: 'red',
                                    // height: '200px'
                                    // backgroundColor: "white",
                                },
                                actionsColumnIndex: -1
                            }}
                            components={{
                                Container: props => <Paper {...props} elevation={0}/>,
                                // Action: props => (
                                //     <a href={`https://docs.google.com/viewer?url=${process.env.REACT_APP_API_URL}/api/v1/trade-stats/5f6ef8f061196139b8cd61ae.xls`} without target='_blank' rel='noopener noreferrer'>Download Reports</a>
                                // ),
                                // Pagination: (props) => (
                                //     <TablePagination {...props} className={classes.table} />
                                // )
                            }}
                            actions={[
                                {
                                    icon: 'description',
                                    tooltip: 'View Report',
                                    onClick: (event, rowData) => {
                                        const url = `https://docs.google.com/viewer?url=${process.env.REACT_APP_API_URL}/api/v1/${id === "user-transactions" ? "payment-details" : "bonus-details"}/${rowData.id}.xlsx`
                                        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
                                        if (newWindow) newWindow.opener = null
                                    }
                                }
                            ]}

                            columns={id === "referral-transactions" ? referralTransactionsColumns : transactionColumns}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    let uri = `size=${query.pageSize}&page=${query.page}`
                                    if (query.orderBy) {
                                        uri += `&sort=${query.orderBy.field},${query.orderDirection}`
                                    }
                                    (
                                        id === "referral-transactions" ?
                                            getReferralTransactions(this.props.userId, uri)
                                            :
                                            getUsersTransactions(this.props.userId, uri)
                                    )
                                        .then(result => {
                                            resolve({
                                                data: result.data.content
                                                    ? result.data.content.map(t => ({
                                                        id: t.id,
                                                        referrerId: t.referrerId,
                                                        userId: t.userId,
                                                        base: t.base,
                                                        status: t.status,
                                                        totalProfit: t.totalProfit,
                                                        totalFee: t.totalFee,
                                                        feePercent: t.feePercent,
                                                        start: t.start,
                                                        end: t.end,
                                                    }))
                                                    : [],
                                                page: result.data.pageable.pageNumber,
                                                totalCount: result.data.totalElements,
                                            })
                                        })
                                })}

                            title={""}
                            detailPanel={({rowData}) => {
                                return (<MaterialTable
                                        title="Trades"
                                        columns={columnsDetails}
                                        components={{
                                            Container: props => <Paper {...props} elevation={0}/>,
                                            Pagination: props => {
                                                // console.log("props ===>", props)
                                                props.classes.root = props.classes.root + " details-pagination"
                                                props.classes.toolbar = props.classes.toolbar + " details-toolbar"
                                                return <MTablePagination {...props} />
                                            }
                                        }}
                                        data={query =>
                                            new Promise((resolve, reject) => {
                                                let uri = `startDate=${rowData.start}&endDate=${rowData.end}&size=${query.pageSize}&page=${query.page}`
                                                if (query.orderBy) {
                                                    uri += `&sort=${query.orderBy.field},${query.orderDirection}`
                                                } else {
                                                    uri += `&sort=closeDate,desc`
                                                }
                                                getUsersDealsHistory(id === "referral-transactions" ? rowData.referrerId : rowData.userId, uri)
                                                    .then(result => {
                                                        resolve({
                                                            data: result.data.content
                                                                ? result.data.content.map(deal => {
                                                                        return {
                                                                            id: deal.id,
                                                                            pair: deal.pair,
                                                                            buyPrice: deal.buyPrice,
                                                                            sellPrice: deal.sellPrice,
                                                                            amount: deal.qty,
                                                                            profit: deal.profit ? deal.profit.toFixed(5) : 0,
                                                                            diff: deal.diff.toFixed(5),
                                                                            tradesCount: (Array.isArray(deal.trades) ? deal.trades : []).length,
                                                                            trades: Array.isArray(deal.trades) ? deal.trades : [],
                                                                            openDate: deal.openDate,
                                                                            closeDate: deal.closeDate,
                                                                            value: deal.volume
                                                                                ? deal.volume.toFixed(5)
                                                                                : 0
                                                                        }
                                                                    }
                                                                )
                                                                : [],
                                                            page: result.data.pageable.pageNumber,
                                                            totalCount: result.data.totalElements
                                                        })
                                                    })
                                            })}

                                        options={{
                                            emptyRowsWhenPaging: false,
                                            toolbar: false,
                                            paging: true,
                                            sorting: true
                                        }}
                                    />
                                );
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        />
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    trades: userDealsSelector(state),
    userId: ownProps.userId || userIdSelector(state),
    lastBalance: lastBalanceSelector(state),
    openDeals: openDealsSelector(state),
    openDealsStats: openDealsStatsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getDeals: userId => dispatch(getUserDealsRequest(userId)),
    getUsersOpenDeals: userId => dispatch(getUserOpenDealsRequest(userId)),
    getBalances: userId => dispatch(getLiveBalanceRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserTransactions);
