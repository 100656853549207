import React from "react";
import { Formik } from "formik";
import FormikInput from "../../../../../../components/FormikInput";
import { CreateUserValidationScheme } from "../validators/create-user-form";
import "../styles/create-user-form.scss";
import { NavLink } from "react-router-dom";

export const CreateUserForm = ({ isFetching, onSubmitCallback, error }) => (
  <div className="create-user-form">
    <Formik
      initialValues={{ email: "", password: "", referralCode: "" }}
      validationSchema={CreateUserValidationScheme}
      onSubmit={onSubmitCallback}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <FormikInput
              label="E-mail"
              name="email"
              type="email"
              placeholder="Enter user's e-mail"
            />
            <FormikInput
              label="Password"
              name="password"
              type="password"
              placeholder="Enter user's password"
            />
            <FormikInput
              label="Referral Code"
              name="referralCode"
              type="text"
              placeholder="Enter user's referral code"
            />
          </div>
          <div className="form-container">
            <FormikInput
              label="Name"
              name="name"
              type="text"
              placeholder="Enter user's name"
            />
            <FormikInput
              label="Surname"
              name="surname"
              type="text"
              placeholder="Enter user's surname"
            />
          </div>
          <div className="btn-block">
            <button className="submit-btn" type="submit" disabled={isFetching}>
              Submit
            </button>
            {error ? <div className="error-block">{error}</div> : null}
            <NavLink className="cancel-btn" disabled={isFetching} to="/">
              Cancel
            </NavLink>
          </div>
        </form>
      )}
    </Formik>
  </div>
);
