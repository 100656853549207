import { call, put, takeEvery } from "redux-saga/effects";
import {
  get24HoursStatsFailure,
  get24HoursStatsSuccess,
  GET_24_HOURS_STATS_REQUEST
} from "../actions/binance";
import { get24HoursStatsByCurrency } from "../api/binance";

function* statsSaga(action) {
  try {
    const response = yield call(get24HoursStatsByCurrency, action.payload);

    yield put(get24HoursStatsSuccess(response.data));
  } catch (e) {
    console.log(GET_24_HOURS_STATS_REQUEST, e);
    yield put(get24HoursStatsFailure());
  }
}

function* watchBinanceSaga() {
  yield takeEvery(GET_24_HOURS_STATS_REQUEST, statsSaga);
}

export default watchBinanceSaga;
