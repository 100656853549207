import clientAPI from "./";

export const getUserReferralCode = userId => {
    return clientAPI.get(`/referrals/users/${userId}/code`);
};

export const getUserReferees = userId => {
    return clientAPI.get(`/users/${userId}/referees`);
};

export const getUserBalances = userId => {
    return clientAPI.get(`/users/${userId}/balances/history`);
};

export const getTradingConfig = userId => {
    return clientAPI.get(`/users/${userId}/trade-settings`);
};

export const getUserLastBalance = userId => {
    return clientAPI.get(`/users/${userId}/balances/last`);
};

export const getUsersTrades = userId => {
    return clientAPI.get(`/users/${userId}/trades`);
};

export const getUsersDeals = (userId, query) => {
    return clientAPI.get(`/users/${userId}/deals?${query}`);
};
export const getUsersDealsHistory = (userId, query) => {
    return clientAPI.get(`/users/${userId}/deals-history?${query}`);
};

export const getUsersOpenDeals = (userId, query) => {
    return clientAPI.get(`/users/${userId}/open-deals?${query}`);
};

export const getUsersTransactions = (userId, query) => {
    return clientAPI.get(`/users/${userId}/user-transactions?${query}`);
};

export const getReferralTransactions = (userId, query) => {
    return clientAPI.get(`/users/${userId}/referral-transactions?${query}`);
};

export const getUserLogs = (userId, query) => {
    return clientAPI.get(`/users/${userId}/logs?${query}`);
};



