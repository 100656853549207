import React from "react";
import { Formik } from "formik";
import { LoginValidationScheme } from "../validators/login-form";
import "../styles/login-form.scss";
import { NavLink } from "react-router-dom";
import FormikInput from "../../../../../../../components/FormikInput";
import "../styles/login-form.scss";

export const LoginForm = ({ isFetching, onSubmitCallback, error }) => (
  <div className="login-form">
    <div className="welcome">
      <span>Welcome!</span>
    </div>
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={LoginValidationScheme}
      onSubmit={onSubmitCallback}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormikInput
            label="E-mail"
            name="email"
            type="email"
            className="formik-input"
            placeholder="Enter your e-mail"
          />
          <FormikInput
            label="Password"
            name="password"
            type="password"
            placeholder="Enter your password"
          />
          <div className="forgot-password">
            <NavLink to="/reset-password">
              <span>Forgot password?</span>
            </NavLink>
          </div>
          <button type="submit" disabled={isFetching} className="button">
            Log in
          </button>
          {error && <span className="error-message">Bad credentials</span>}
        </form>
      )}
    </Formik>
  </div>
);
