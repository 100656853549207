import {createAction} from "redux-actions";

export const USER_UPDATE = "USER_UPDATE";

export const GET_USER_REFEREES_REQUEST = "GET_USER_REFEREES_REQUEST";
export const GET_USER_REFEREES_SUCCESS = "GET_USER_REFEREES_SUCCESS";
export const GET_USER_REFEREES_FAILURE = "GET_USER_REFEREES_FAILURE";

export const GET_USER_REFERRAL_CODE_REQUEST = "GET_USER_REFERRAL_CODE_REQUEST";
export const GET_USER_REFERRAL_CODE_SUCCESS = "GET_USER_REFERRAL_CODE_SUCCESS";
export const GET_USER_REFERRAL_CODE_FAILURE = "GET_USER_REFERRAL_CODE_FAILURE";

export const GET_USER_BALANCES_REQUEST = "GET_USER_BALANCES";
export const GET_USER_BALANCES_SUCCESS = "GET_USER_BALANCES_SUCCESS";
export const GET_USER_BALANCES_FAILURE = "GET_USER_BALANCES_FAILURE";

export const GET_USER_LAST_BALANCE_REQUEST = "GET_USER_LAST_BALANCE";
export const GET_USER_LAST_BALANCE_SUCCESS = "GET_USER_LAST_BALANCE_SUCCESS";
export const GET_USER_LAST_BALANCE_FAILURE = "GET_USER_LAST_BALANCE_FAILURE";


export const GET_USER_DEALS_REQUEST = "GET_USER_DEALS_REQUEST";
export const GET_USER_DEALS_SUCCESS = "GET_USER_DEALS_SUCCESS";
export const GET_USER_DEALS_FAILURE = "GET_USER_DEALS_FAILURE";

export const GET_USER_OPEN_DEALS_REQUEST = "GET_USER_OPEN_DEALS_REQUEST";
export const GET_USER_OPEN_DEALS_SUCCESS = "GET_USER_OPEN_DEALS_SUCCESS";
export const GET_USER_OPEN_DEALS_FAILURE = "GET_USER_OPEN_DEALS_FAILURE";

export const userUpdate = createAction(USER_UPDATE);

export const getUserRefereesRequest = createAction(GET_USER_REFEREES_REQUEST);
export const getUserRefereesSuccess = createAction(GET_USER_REFEREES_SUCCESS);
export const getUserRefereesFailure = createAction(GET_USER_REFEREES_FAILURE);

export const getUserReferralCodeRequest = createAction(
    GET_USER_REFERRAL_CODE_REQUEST
);
export const getUserReferralCodeSuccess = createAction(
    GET_USER_REFERRAL_CODE_SUCCESS
);
export const getUserReferralCodeFailure = createAction(
    GET_USER_REFERRAL_CODE_FAILURE
);

export const getUserBalancesRequest = createAction(GET_USER_BALANCES_REQUEST);
export const getUserBalancesSuccess = createAction(GET_USER_BALANCES_SUCCESS);
export const getUserBalancesFailure = createAction(GET_USER_BALANCES_FAILURE);

export const getUserLastBalanceRequest = createAction(GET_USER_LAST_BALANCE_REQUEST);
export const getUserLastBalanceSuccess = createAction(GET_USER_LAST_BALANCE_SUCCESS);
export const getUserLastBalanceFailure = createAction(GET_USER_LAST_BALANCE_FAILURE);

export const getUserDealsRequest = createAction(GET_USER_DEALS_REQUEST);
export const getUserDealsSuccess = createAction(GET_USER_DEALS_SUCCESS);
export const getUserDealsFailure = createAction(GET_USER_DEALS_FAILURE);

export const getUserOpenDealsRequest = createAction(GET_USER_OPEN_DEALS_REQUEST);
export const getUserOpenDealsSuccess = createAction(GET_USER_OPEN_DEALS_SUCCESS);
export const getUserOpenDealsFailure = createAction(GET_USER_OPEN_DEALS_FAILURE);
