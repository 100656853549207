import {
    START_LOADING,
    STOP_LOADING
} from "../actions/ui";

const initialState = {
    loading: false,
};

const ui = (state = initialState, action) => {
    const {payload, type} = action;
    switch (type) {
        case START_LOADING:
            return {
                ...state,
                [`is${payload}Loading`]: true,
                loading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                [`is${payload}Loading`]: false,
                loading: false,
            };
        default:
            return state;
    }
};

export default ui;
