import React from "react";
import {getIn} from "formik";
import MatTextField from "@mui/material/TextField";

export default class TextField extends React.Component {
    handleChange = event => {
        this.props.field.onChange(event);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    handleBlur = event => {
        this.props.field.onBlur(event);
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    render() {
        const {
            field,
            form: {touched, errors, values},
            helperText,
            children,
            ...props
        } = this.props;
        const message = getIn(touched, field.name) && getIn(errors, field.name);
        const value = getIn(values, field.name);
        return (
            <MatTextField
                {...props}
                {...field}
                value={value || ""}
                error={Boolean(message)}
                helperText={message || helperText}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
            >
                {children}
            </MatTextField>
        );
    }
}
