import { createSelector } from "reselect";

const usersPageSelector = state => state.admin.usersPage;

export const userLogsPageSelector = state => state.admin.userLogsPage;

export const userSelector = state => state.admin.user;

export const tradingSettingsSelector = state => state.admin.userTradingSettings;

export const userTransactionsPageSelector = state =>
  state.admin.userTransactionsPage;

export const userStatusSelector = state => state.admin.status;

export  const userMessageSelector = state => state.admin.message;

export const usersPageContentSelector = createSelector(
  usersPageSelector,
  usersPage => (usersPage && buildUserPageContent(usersPage)) || []
);

export const usersLastUpdateSelector = createSelector(
  usersPageSelector,
  usersPage => usersPage && usersPage.lastUpdate
);
export const usersProfitSelector = createSelector(
    usersPageSelector,
    usersPage => usersPage && usersPage.profit || {}
);

export const usersBalanceSelector = createSelector(
    usersPageSelector,
    usersPage => usersPage && usersPage.balance || {}
);

export const usersAwaitingFeeSelector = createSelector(
    usersPageSelector,
    usersPage => usersPage && usersPage.awaitingFee || {}
);
export const usersCompletedFeeSelector = createSelector(
    usersPageSelector,
    usersPage => usersPage && usersPage.completedFee || {}
);

export const usersCompletedSystemFeeSelector = createSelector(
    usersPageSelector,
    usersPage => usersPage && usersPage.completedSystemFee || {}
);
export const usersFutureFeeSelector = createSelector(
    usersPageSelector,
    usersPage => usersPage && usersPage.futureFee || {}
);

export const usersFutureSystemFeeSelector = createSelector(
    usersPageSelector,
    usersPage => usersPage && usersPage.futureSystemFee || {}
);

const buildUserPageContent = ({content}) => {
  return content;
};

export const usersPageCountSelector = createSelector(
  usersPageSelector,
  usersPage => (usersPage && usersPage.totalElements) || 0
);

const buildLogsContent = ({ content }) => {
  return content.map(log => ({
    activityType: log.activityType,
    date: new Date(log.date).toGMTString(),
    id: log.id
  }));
};

export const userLogsPageContentSelector = createSelector(
  userLogsPageSelector,
  userLogsPage => (userLogsPage && buildLogsContent(userLogsPage)) || []
);

export const userLogsPageCountSelector = createSelector(
  userLogsPageSelector,
  userLogsPage => (userLogsPage && userLogsPage.totalElements) || 0
);

const buildTransactionsContent = ({ content }) => {
  return content.map(transaction => ({
    ...transaction,
    date: new Date(transaction.date).toGMTString()
  }));
};

export const userTransactionsPageContentSelector = createSelector(
  userTransactionsPageSelector,
  userTransactionsPage =>
    (userTransactionsPage && buildTransactionsContent(userTransactionsPage)) ||
    []
);

export const userTransactionsPageCountSelector = createSelector(
  userTransactionsPageSelector,
  userTransactionsPage =>
    (userTransactionsPage && userTransactionsPage.totalElements) || 0
);
