import React from "react";
import {roundCurrencyPrice} from "../../../../utils/calculations";

const getDate = milisec => {
    return new Date(milisec).toLocaleString();
};

const color_picker = {
    // "AWAITING_FOR_PROCESSING": "rgb(218, 192, 81)",
    // "AWAITING_FOR_PROCESSING": "#ecbd34",
    "AWAITING_FOR_PROCESSING": "#f0b90b",
    "COMPLETED": "green",
    "PROCESSING": "gray",
    "true": "red",
    "false": "green"
}

export const detailCellStyleColored = (isRed, fontWeight = 400) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "4em",
    paddingRight: "1em",
    backgroundColor: "rgb(247, 248, 250, 0.3)",
    // borderLeft: "1px solid rgba(224, 224, 224, 1)",
    color: isRed ? "red" : "green",
    fontWeight: fontWeight
});


export const detailCellStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "1em",
    paddingRight: "1em",
    backgroundColor: "rgb(247, 248, 250, 0.3)"
};

export const detailedHeaderStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f7f8fa",
    backgroundColor: "rgb(247, 248, 250, 0.8)",
    textTransform: "capitalize",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "1em",
    paddingRight: "1em"
};

export const firstDetailedHeaderStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f7f8fa",
    backgroundColor: "rgb(247, 248, 250, 0.8)",
    textTransform: "capitalize",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "4em",
    paddingRight: "1em"
};

export const headerStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f1f4f7",
    textTransform: "capitalize",
    // border: "none",
    // textAlign: "center",
    textAlign: "left",
    fontWeight: "600",
    // width: '100%',
    // width: "150",
    paddingLeft: "10px",
    paddingRight: "10px",
};
export const cellStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    // paddingLeft: "3em",
    // paddingRight: "1em",
};
export const firstHeaderStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f1f4f7",
    textTransform: "capitalize",
    // border: "none",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "2em",
    paddingRight: "1em",
};
export const firstCellStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "2em",
    paddingRight: "1em",
};

export const firstCellStyleColored = (isRed = false) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "2em",
    paddingRight: "1em",
    color: isRed ? "red" : "green",
});

export const cellStyleColored = (isRed = false, fontWeight = 400) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontWeight: fontWeight,
    color: color_picker[isRed],
});

export const columns = [
    {
        title: "Pair",
        field: "pair",
        headerStyle: firstHeaderStyle,
        cellStyle: firstCellStyle
    },
    {
        title: "AVG buy Price",
        field: "buyPrice",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "AVG sell price",
        field: "sellPrice",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Volume Base",
        field: "value",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Profit Base",
        field: "profit",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Profit %",
        field: "diff",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Trades",
        field: "tradesCount",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Open Date",
        field: "openDate",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.openDate - a.openDate,
        render: rowData => <div>{getDate(rowData.openDate)}</div>,
        // defaultSort: "asc",
        cellStyle: cellStyle
    },
    {
        title: "Close Date",
        field: "closeDate",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.closeDate - a.closeDate,
        render: rowData => <div>{getDate(rowData.closeDate)}</div>,
        defaultSort: "asc",
        cellStyle: cellStyle
    }
];
export const columnsDetails = [
    {
        title: "Pair",
        field: "pair",
        headerStyle: firstDetailedHeaderStyle,
        cellStyle: firstCellStyle
    },
    {
        title: "AVG buy Price",
        field: "buyPrice",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "AVG sell price",
        field: "sellPrice",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Volume Base",
        field: "value",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Profit Base",
        field: "profit",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Profit %",
        field: "diff",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Trades",
        field: "tradesCount",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Open Date",
        field: "openDate",
        type: "date",
        headerStyle: detailedHeaderStyle,
        customSort: (a, b) => a.openDate - b.openDate,
        render: rowData => <div>{getDate(rowData.openDate)}</div>,
        // defaultSort: "asc",
        cellStyle: cellStyle
    },
    {
        title: "Close Date",
        field: "closeDate",
        type: "date",
        headerStyle: detailedHeaderStyle,
        customSort: (a, b) => a.closeDate - b.closeDate,
        render: rowData => <div>{getDate(rowData.closeDate)}</div>,
        defaultSort: "desc",
        // defaultSort: "asc",
        cellStyle: cellStyle
    }
];
export const openDealsColumns = [
    {
        title: "Pair",
        field: "pair",
        headerStyle: firstHeaderStyle,
        cellStyle: firstCellStyle
    },
    {
        title: "AVG buy Price",
        field: "buyPrice",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Current price",
        field: "currentPrice",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Minimal Sell Price",
        field: "expectedSellPrice",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Diff %",
        field: "diff",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cell => {
            // console.log('cell ===>', cell)
            return cellStyleColored(parseFloat(cell) < 0, 600)
        },
        defaultSort: "asc",
    },
    {
        title: "Volume Base",
        field: "value",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Volume Alt",
        field: "amount",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Trades",
        field: "tradesCount",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Open Date",
        field: "openDate",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.openDate - a.openDate,
        render: rowData => <div>{getDate(rowData.openDate)}</div>,
        defaultSort: "asc",
        cellStyle: cellStyle
    }
];

export const detailColumns = [
    {
        title: "Side",
        field: "side",
        headerStyle: firstDetailedHeaderStyle,
        cellStyle: cell => {
            return detailCellStyleColored(cell === "SELL", 600)
        }
    },
    {
        title: "Price",
        field: "price",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle,
    },
    {
        title: "Volume Alt",
        field: "amount",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle
    },
    {
        title: "Volume Base",
        field: "quoteQty",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle
    },
    {
        title: "Date",
        field: "date",
        type: "date",
        customSort: (a, b) => b.date - a.date,
        render: rowData => <div>{getDate(rowData.date)}</div>,
        defaultSort: "asc",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle
    }
];

export const transactionColumns = [
    {
        title: "From Date",
        field: "start",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.start - a.start,
        render: rowData => <div>{getDate(rowData.start)}</div>,
        // defaultSort: "asc",
        cellStyle: cellStyle
    },
    {
        title: "To Date",
        field: "end",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.end - a.end,
        render: rowData => <div>{getDate(rowData.end)}</div>,
        defaultSort: "desc",
        cellStyle: cellStyle
    },
    {
        title: "Status",
        field: "status",
        headerStyle: headerStyle,
        // cellStyle: firstCellStyle
        // cellStyle: cell => {
        //     console.log('cell ===>', cell)
        //     return cellStyleColored(parseFloat(cell) < 0, 600)
        // },
        render: rowData => <div style={{fontStyle: "italic"}}>{rowData.status.replaceAll("_", " ")}</div>,
        cellStyle: cell => {
            return cellStyleColored(cell, 600)
        }
    },
    {
        title: "Profit By Period",
        field: "totalProfit",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Fee %",
        field: "feePercent",
        type: "numeric",
        render: rowData => <div>{rowData.feePercent * 100}</div>,
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Fee To Pay",
        field: "totalFee",
        type: "numeric",
        headerStyle: headerStyle,
        // cellStyle: cellStyle
        cellStyle: cell => {
            return cellStyleColored(true)
        }
    },

    {
        title: "Currency",
        field: "base",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },

    {
        title: "Clear Profit",
        field: "feePercent",
        type: "numeric",
        render: rowData =>
            <div>{roundCurrencyPrice(rowData.totalProfit - rowData.totalFee, rowData.base)}</div>,
        headerStyle: headerStyle,
        // cellStyle: cellStyle

        cellStyle: cell => {
            return cellStyleColored(false)
        }
    },


];
export const referralTransactionsColumns = [
    {
        title: "From Date",
        field: "start",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.start - a.start,
        render: rowData => <div>{getDate(rowData.start)}</div>,
        // defaultSort: "asc",
        cellStyle: cellStyle
    },
    {
        title: "To Date",
        field: "end",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.end - a.end,
        render: rowData => <div>{getDate(rowData.end)}</div>,
        defaultSort: "desc",
        cellStyle: cellStyle
    },

    {
        title: "Status",
        field: "status",
        headerStyle: firstHeaderStyle,
        // cellStyle: firstCellStyle
        // cellStyle: cell => {
        //     console.log('cell ===>', cell)
        //     return cellStyleColored(parseFloat(cell) < 0, 600)
        // },
        render: rowData => <div style={{fontStyle: "italic"}}>{rowData.status.replaceAll("_", " ")}</div>,
        cellStyle: cell => {
            return cellStyleColored(cell, 600)
        }
    },

    {
        title: "Bonus In Currency",
        field: "totalFee",
        type: "numeric",
        render: rowData =>
            <div>{roundCurrencyPrice(rowData.totalFee, rowData.base)}</div>,
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Bonus %",
        field: "feePercent",
        type: "numeric",
        render: rowData => <div>{rowData.feePercent * 100}</div>,
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },

    {
        title: "Currency",
        field: "base",
        headerStyle: firstHeaderStyle,
        cellStyle: firstCellStyle
    },


];

export const userLogsColumns = [
    {
        title: "Date",
        field: "date",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.date - a.date,
        render: rowData => <div>{getDate(rowData.date)}</div>,
        defaultSort: "desc",
        cellStyle: cellStyle
    },
    {
        title: "Type",
        field: "type",
        headerStyle: headerStyle,
        // cellStyle: cellStyle,
        // cellStyle: cell => {
        //     console.log('cell ===>', cell)
        //     return cellStyleColored(parseFloat(cell) < 0, 600)
        // },
        render: rowData => <div style={{fontStyle: "italic"}}>{rowData.type.replaceAll("_", " ")}</div>,
        cellStyle: cell => {
            return cellStyleColored(true, 600)
        }
    },

    {
        title: "Count",
        field: "count",
        type: "numeric",
        // render: rowData =>
        //     <div>{roundCurrencyPrice(rowData.totalFee , rowData.base)}</div>,
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Message",
        field: "mess",
        render: rowData => <div style={{
            textOverflow: "ellipsis",

            /* Required for text-overflow to do anything */
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "1300px"
        }}>{rowData.mess}</div>,
        headerStyle: headerStyle,
        cellStyle: cellStyle
    }
];
export const userActivityColumns = [
    {
        title: "Date",
        field: "date",
        type: "date",
        headerStyle: firstHeaderStyle,
        customSort: (a, b) => b.date - a.date,
        render: rowData => <div>{getDate(rowData.date)}</div>,
        defaultSort: "desc",
        cellStyle: firstCellStyle
    },
    {
        title: "Type",
        field: "activityType",
        headerStyle: headerStyle,
        render: rowData => <div style={{fontStyle: "italic"}}>{rowData.activityType.replaceAll("_", " ")}</div>,
        cellStyle: cell => {
            return cellStyleColored({}, 600)
        }
    }
];
