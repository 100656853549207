import React from "react";
import { connect } from "react-redux";
import { authLoginRequest } from "../../../../../../../actions/auth";
import { userRolesSelector } from "../../../../../../../selectors/user";
import { isFetchingSelector } from "../../../../../../../selectors/app";
import { LoginForm } from "./LoginForm";

class LoginFormContainer extends React.Component {
  onSubmit = credentials => {
    const { login } = this.props;
    login(credentials);
  };

  render() {
    const { isFetching, error } = this.props;
    return (
      <LoginForm
        isFetching={false}
        onSubmitCallback={this.onSubmit}
        error={error}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: credentials => {
      dispatch(authLoginRequest(credentials));
    }
  };
};

const mapStateToProps = state => ({
  isFetching: isFetchingSelector(state),
  error: state.auth.error,
  userRole: userRolesSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormContainer);
