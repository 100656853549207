import clientAPI from "./index";
import { handleError } from "./index";

export const getLiveBalancesByUserId = userId => {
  return clientAPI.get(`/users/${userId}/balances/last`);
};

export const getDealsByUserId = async userId => {
  try {
    const res = await clientAPI.get(`/admin/users/${userId}/deals?size=10000`);
    return res.data.content;
  } catch (e) {
    handleError(e);
  }
};

export const getTradesByUserId = async userId => {
  try {
    const res = await clientAPI.get(`/admin/users/${userId}/trades`);
    return res.data.content;
  } catch (e) {
    handleError(e);
  }
};
