import {call, put, takeEvery} from "redux-saga/effects";
import {forwardTo} from "../helper/forwardTo/forwardTo";
import {
    CREATE_USER_REQUEST,
    createUserFailure,
    createUserSuccess,
    GET_USER_ACTIVITY_REQUEST,
    GET_USER_REQUEST,
    GET_USER_TRADING_SETTINGS_REQUEST,
    GET_USER_TRANSACTIONS_REQUEST,
    GET_USERS_REQUEST,
    getUserActivityFailure,
    getUserActivitySuccess,
    getUserFailure,
    getUsersFailure,
    getUsersSuccess,
    getUserSuccess,
    getUserTradingSettingsFailure,
    getUserTradingSettingsSuccess,
    getUserTransactionsFailure,
    getUserTransactionsSuccess,
    UPDATE_USER_REQUEST,
    UPDATE_USER_TRADING_SETTINGS_REQUEST,
    updateUserFailure,
    updateUserSuccess,
    updateUserTradingSettingsFailure,
    updateUserTradingSettingsSuccess
} from "../actions/admin";
import {
    createUser,
    getUser,
    getUserActivity,
    getUsers,
    getUserTradingSettings,
    getUserTransactions,
    updateUser,
    updateUserTradingSettings
} from "../api/admin";
import {delay} from "redux-saga";
import {toast} from "react-toastify";

function* getUsersSaga(action) {
    try {
        const response = yield call(getUsers, action.payload);

        const profit = {}
        const balance = {}
        const awaitingFee = {}
        const completedFee = {}
        const futureFee = {}
        const completedSystemFee = {}
        const futureSystemFee = {}
        response.data.forEach(c => {
            if (c.base && c.email !== "samgeka@gmail.com" &&
                c.email !== "zorian64@gmail.com" &&
                c.email !== "miner.xrp@gmail.com"
            ) {
                if (profit[c.base]) {
                    profit[c.base] = profit[c.base] + c.totalProfit
                } else {
                    profit[c.base] = c.totalProfit
                }
                if (completedSystemFee[c.base]) {
                    completedSystemFee[c.base] = completedSystemFee[c.base] + c.completedSystemFee
                } else {
                    completedSystemFee[c.base] = c.completedSystemFee
                }
                if (futureSystemFee[c.base]) {
                    futureSystemFee[c.base] = futureSystemFee[c.base] + c.futureSystemFee
                } else {
                    futureSystemFee[c.base] = c.futureSystemFee
                }


                if (balance[c.base]) {
                    balance[c.base] = balance[c.base] + c.balanceInBase
                } else {
                    balance[c.base] = c.balanceInBase
                }

                if (completedFee[c.base]) {
                    completedFee[c.base] = completedFee[c.base] + c.completedFee
                } else {
                    completedFee[c.base] = c.completedFee
                }

                if (awaitingFee[c.base]) {
                    awaitingFee[c.base] = awaitingFee[c.base] + c.awaitingFee
                } else {
                    awaitingFee[c.base] = c.awaitingFee
                }

                if (futureFee[c.base]) {
                    futureFee[c.base] = futureFee[c.base] + c.futureFee + c.awaitingFee
                } else {
                    futureFee[c.base] = c.futureFee + c.awaitingFee
                }

            }

        })

        yield put(getUsersSuccess({
            content: response.data,
            lastUpdate: new Date(),
            profit,
            balance,
            awaitingFee,
            futureFee,
            completedFee,
            completedSystemFee,
            futureSystemFee
        }));
    } catch (e) {
        console.log(GET_USERS_REQUEST, e);
        yield put(getUsersFailure());
    }
}

function* createUserSaga(action) {
    try {
        const response = yield call(createUser, action.payload);

        yield put(createUserSuccess(response.status));

        yield call(forwardTo, "/admin/users");
    } catch (e) {
        console.log(CREATE_USER_REQUEST, e);
        yield put(createUserFailure(e));
    }
}

function* getUserSaga(action) {
    try {
        const response = yield call(getUser, action.payload);

        yield put(getUserSuccess(response.data));
    } catch (e) {
        console.log(GET_USER_REQUEST, e);
        yield put(getUserFailure());
    }
}

function* updateUserSaga(action) {
    try {
        const {userId, user} = action.payload;

        const response = yield call(updateUser, userId, user);
        yield put(updateUserSuccess(response.status));
    } catch (e) {
        console.log(UPDATE_USER_REQUEST, e);
        yield put(updateUserFailure());
    }
}

function* getUserActivitySaga(action) {
    try {
        const {userId, params} = action.payload;
        const response = yield call(getUserActivity, userId, params);

        yield put(getUserActivitySuccess(response.data));
    } catch (e) {
        console.log(GET_USER_ACTIVITY_REQUEST, e);
        yield put(getUserActivityFailure());
    }
}

function* getUserTransactionsSaga(action) {
    try {
        const {userId, params} = action.payload;
        const response = yield call(getUserTransactions, userId, params);

        yield put(getUserTransactionsSuccess(response.data));
    } catch (e) {
        console.log(GET_USER_TRANSACTIONS_REQUEST, e);
        yield put(getUserTransactionsFailure());
    }
}

function* getUserTradingSettingsSaga(action) {
    try {
        const response = yield call(getUserTradingSettings, action.payload);

        yield put(getUserTradingSettingsSuccess(response.data));
    } catch (e) {
        console.log(GET_USER_TRADING_SETTINGS_REQUEST, e);
        yield put(getUserTradingSettingsFailure());
    }
}

function* updateUserTradingSettingsSaga(action) {
    try {
        const {userId, tradingSettings} = action.payload;
        const response = yield call(
            updateUserTradingSettings,
            userId,
            tradingSettings
        );
        yield put(updateUserTradingSettingsSuccess(response.status));
        yield delay(1000);
        yield put(getUserTradingSettingsSuccess(response.data));
        toast.success("Trading Setting successfully updated!", {});
    } catch (e) {
        console.log(UPDATE_USER_TRADING_SETTINGS_REQUEST, e);
        yield put(updateUserTradingSettingsFailure());
        toast.warn("MY WARN");
    }




}

function* watchAdminSaga() {
    yield takeEvery(GET_USERS_REQUEST, getUsersSaga);
    yield takeEvery(CREATE_USER_REQUEST, createUserSaga);
    yield takeEvery(GET_USER_REQUEST, getUserSaga);
    yield takeEvery(UPDATE_USER_REQUEST, updateUserSaga);
    yield takeEvery(GET_USER_ACTIVITY_REQUEST, getUserActivitySaga);
    yield takeEvery(GET_USER_TRANSACTIONS_REQUEST, getUserTransactionsSaga);
    yield takeEvery(
        GET_USER_TRADING_SETTINGS_REQUEST,
        getUserTradingSettingsSaga
    );
    yield takeEvery(
        UPDATE_USER_TRADING_SETTINGS_REQUEST,
        updateUserTradingSettingsSaga
    );
}

export default watchAdminSaga;
