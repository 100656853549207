import React from "react";
import { Route } from "react-router-dom";
import AdminRoute from "../../../../components/AdminRoute/AdminRoute";
import { Switch } from "react-router";
import UsersProfileSubPage from "./UsersProfileSubPage/components";
import NotFoundPage from "../../../NotFoundPage";
import CreateUserFormContainer from "./CreateUserSubPage";
import UsersTableSubPage from "./UsersTableSubPage";
import "../styles/users-page.scss";

class UsersPage extends React.Component {
  render() {
    return (
      <div className="users-page">
        <div className="page-content">
          <Switch>
            <AdminRoute
              path="/admin/users/create-new-user"
              component={CreateUserFormContainer}
            />
            <AdminRoute
              path="/admin/users/"
              exact
              component={UsersTableSubPage}
            />
            <AdminRoute
              path="/admin/users/:id"
              component={UsersProfileSubPage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default UsersPage;
