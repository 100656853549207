import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_LIVE_BALANCE_REQUEST,
    getLiveBalanceSuccess,
    getLiveBalanceFailure,
    GET_DEALS_REQUEST,
    getDealsSuccess,
    GET_DEALS_FAILURE,
    getDealsFailure
} from "../actions/userStatistics";

import {
    getDealsByUserId,
    getLiveBalancesByUserId,
    getTradesByUserId
} from "../api/action";
import {
    getCalculatedBalances,
    getUserDeals
} from "../helper/statistics/userStatistic";
import {getUserBalances} from "../api/user";
import {getUserBalancesSuccess} from "../actions/user";

function* getUserBalanceSaga(action) {
    try {
        const res = yield call(getUserBalances, action.payload);
        const balances = res.data

        const response = yield call(getLiveBalancesByUserId, action.payload);
        const liveBalance = response.data;
        balances.sort((a, b) => a.date - b.date);
        const result = [
            ...balances.map(balance => [balance.date, balance.balance])
        ];

        yield put(getLiveBalanceSuccess(liveBalance));
        yield put(getUserBalancesSuccess(result));
    } catch (e) {
        console.log(GET_LIVE_BALANCE_REQUEST, e);
        yield put(getLiveBalanceFailure());
    }
}

function* getUserDealsSaga(action) {
    try {
        const deals = yield call(getDealsByUserId, action.payload);
        const trades = yield call(getTradesByUserId, action.payload);
        const result = getUserDeals(deals, trades) || [];
        yield put(getDealsSuccess(result));
    } catch (e) {
        console.log(GET_DEALS_FAILURE, e);
        yield put(getDealsFailure);
    }
}

function* watchUserStatisticSaga() {
    yield takeEvery(GET_LIVE_BALANCE_REQUEST, getUserBalanceSaga);
    yield takeEvery(GET_DEALS_REQUEST, getUserDealsSaga);
}

export default watchUserStatisticSaga;
