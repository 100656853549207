import React from "react";
import PropTypes from "prop-types";
import spinner from "./spinner.svg";

const styles = {
  // spinnerStyle: {
  //   position: "absolute",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   width: "100%",
  //   minHeight: "100vh",
  //   backgroundColor: "rgba(255,255,255,0.75)"
  // }
};

const Spinner = ({ isLoading, className }) => {
  return isLoading ? (
    <div style={styles.spinnerStyle} className={`spinner ${className}`}>
      <img src={spinner} alt={""} />
    </div>
  ) : null;
};

Spinner.propTypes = {
  /** boolean isLoading*/
  isLoading: PropTypes.bool.isRequired
};

Spinner.defaultProps = {
  isLoading: true
};

export default Spinner;
