import React, {Component} from "react";
import {connect} from "react-redux";
import {
    lastBalanceSelector,
    openDealsSelector, openDealsStatsSelector,
    userDealsSelector
} from "../../../../selectors/user";
import {userIdSelector} from "../../../../selectors/user";
import {getUserDealsRequest, getUserOpenDealsRequest} from "../../../../actions/user";
import {getLiveBalanceRequest} from "../../../../actions/userStatistics";
import "../styles/new-order.scss"
import {Formik} from "formik";
import {FormikTextField} from "../../../../components/formik/FormikTextField";
import {FormikSliderField} from "../../../../components/formik/FormikSliderField";

const sliderMarks = [
    {
        value: 0,
        // label: "0%"
    },
    {
        value: 25,
        // label: "25%"
    },
    {
        value: 50,
        // label: "50%"
    },
    {
        value: 75,
        // label: "75%"
    },
    {
        value: 100,
        // label: "100%"
    }
];

class BuyOrder extends Component {

    state = {
        orderType: "limit"
    };

    constructor() {
        super()

    }

    newOrderSubmit(values) {
        const confirmBox = window.confirm(
            "Do you really wanna place BUY order?"
        )
        if (confirmBox === true) {
            console.log("newOrderSubmit: ", values)
        }
    }

    validateNumber = (v = 0, decimals = 2, min = 10, max, pattern) => {
        try {
            const regex = new RegExp(pattern, 'g');
            let n = parseFloat(v)
            if (n <= min) {
                n = min
            }
            if (n > max) {
                n = max
            }
            if (!regex.test(n)) {
                n = parseFloat(n.toFixed(decimals))
            }
            return n

        } catch (error) {
            return (min).toFixed(decimals)
        }
    }

    getNumericPattern = (end = 8) => {
        const start = 1;
        const p = `^\\d+((\\.|\\,)\\d{${Array(end - start + 1).fill().map((_, idx) => start + idx).join(",")}})?$`
        return p
    }

    valuetext = (v) => {
        return `${v}%`
    }


    onFieldChange = (field, value, values, setFieldValue) => {
        const {symbolLimits = {}, availableBase} = this.props
        // console.log("onFieldChange ===>", field, value)
        const {
            baseAssetPrecision = 8,
            quoteAssetPrecision = 8,
            maxPrice,
            minNotional,
            minPrice,
        } = symbolLimits

        if (field === "priceToOrder") {
            const price = this.validateNumber(value, quoteAssetPrecision, minPrice, maxPrice, this.getNumericPattern(quoteAssetPrecision))
            setFieldValue("priceToOrder", price)

            const amount = this.validateNumber(values.amountToOrder, baseAssetPrecision, minNotional / price, availableBase / price, this.getNumericPattern(baseAssetPrecision))
            setFieldValue("amountToOrder", amount)

            const totalPriceToOrder = parseFloat((amount * price).toFixed(quoteAssetPrecision))
            setFieldValue("totalPriceToOrder", totalPriceToOrder)
            setFieldValue("percent", ((totalPriceToOrder / availableBase) * 100).toFixed(0))
        }

        if (field === "amountToOrder") {
            const amount = this.validateNumber(value, baseAssetPrecision, minNotional / values.priceToOrder, availableBase / values.priceToOrder, this.getNumericPattern(baseAssetPrecision))
            setFieldValue("amountToOrder", amount)
            setFieldValue("percent", ((amount / (availableBase / values.priceToOrder)) * 100).toFixed(0))
            const totalPriceToOrder = this.validateNumber(amount * values.priceToOrder, quoteAssetPrecision, minNotional, availableBase, this.getNumericPattern(quoteAssetPrecision))
            setFieldValue("totalPriceToOrder", totalPriceToOrder)
        }

        if (field === "percent") {
            setFieldValue("percent", value)
            const amount = this.validateNumber(((value || 0) / 100.00) * (availableBase) / values.priceToOrder, baseAssetPrecision, minNotional / values.priceToOrder, availableBase / values.priceToOrder, this.getNumericPattern(baseAssetPrecision))
            setFieldValue("amountToOrder", amount)
            const totalPriceToOrder = this.validateNumber(amount * values.priceToOrder, quoteAssetPrecision, minNotional, availableBase, this.getNumericPattern(quoteAssetPrecision))
            setFieldValue("totalPriceToOrder", totalPriceToOrder)
        }

        if (field === "totalPriceToOrder") {
            const totalPriceToOrder = this.validateNumber(value, quoteAssetPrecision, minNotional, availableBase, this.getNumericPattern(quoteAssetPrecision))
            setFieldValue("totalPriceToOrder", totalPriceToOrder)
            const amount = this.validateNumber(totalPriceToOrder / values.priceToOrder, baseAssetPrecision, minNotional / values.priceToOrder, availableBase / values.priceToOrder, this.getNumericPattern(baseAssetPrecision))
            setFieldValue("amountToOrder", amount)
            setFieldValue("percent", ((totalPriceToOrder / availableBase) * 100).toFixed(0))
        }

    }

    render() {
        const {orderType, openDeal = {}, symbolLimits = {}, availableBase} = this.props
        const {percent = 0} = this.state;
        const {base, alt, currentPrice} = openDeal
        const marketInit = {
            maxQty: 0,
            minQty: 0,
            stepSize: 0,
        }
        const {
            baseAssetPrecision = 8,
            quoteAssetPrecision = 8,
            market = marketInit,
            maxQty = 0,
            minQty = 0,
            stepSize = 0,
            minNotional = 10,
            maxPrice = 999999,
            minPrice = 0,
            tickSize = 0
        } = symbolLimits

        const isFormDisabled = availableBase < minNotional

        return (

            <Formik
                // enableReinitialize={true}
                initialValues={{priceToOrder: currentPrice}}
                onSubmit={this.newOrderSubmit}
            >
                {({handleSubmit, values, setFieldValue}) => {
                    return (
                        <form onSubmit={handleSubmit} className="new-order__buy">
                            <div className="order-available">
                                <div className="order-available__label">Available</div>
                                <div className="order-available__amount">{availableBase}</div>
                                <div className="order-available__currency">{base}</div>
                            </div>
                            <div className="order-price">
                                <div className="order-label">Price</div>
                                <FormikTextField
                                    className={"order-amount"}
                                    placeholder={orderType === "market" ? "Market" : " "}
                                    name="priceToOrder"
                                    type="number"
                                    disabled={orderType === "market" || isFormDisabled}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: this.getNumericPattern(quoteAssetPrecision),
                                        min: minPrice,
                                        max: maxPrice,
                                        step: tickSize
                                    }}
                                    onChange={v => {
                                        this.onFieldChange("priceToOrder", v.target.value, values, setFieldValue)
                                    }}
                                />
                                <div className="order-currency">{base}</div>
                            </div>
                            <div className="order-price">
                                <div className="order-label">Amount</div>
                                <FormikTextField
                                    className={"order-amount"}
                                    placeholder={" "}
                                    disabled={orderType === "market" || isFormDisabled}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: this.getNumericPattern(baseAssetPrecision),
                                        min: (orderType === "market" ? market.minQty : minQty),
                                        max: (orderType === "market" ? market.maxQty : maxQty),
                                        step: (orderType === "market" ? market.stepSize : stepSize)
                                    }}
                                    name="amountToOrder"
                                    type="number"
                                    onChange={v => {
                                        this.onFieldChange("amountToOrder", v.target.value, values, setFieldValue)
                                    }}
                                />
                                <div className="order-currency">{alt}</div>
                            </div>


                            <FormikSliderField
                                name="percent"
                                min={0}
                                max={100}
                                step={1}
                                defaultValue={0}
                                getAriaValueText={this.valuetext}
                                valueLabelFormat={this.valuetext}
                                value={values.percent || 0}
                                valueLabelDisplay="auto"
                                marks={sliderMarks}
                                disabled={isFormDisabled}
                                onChange={(component, value) => {
                                    this.onFieldChange("percent", value, values, setFieldValue)
                                }}

                            />

                            <div className="order-price">
                                <div className="order-label">Total</div>
                                <FormikTextField
                                    className={"order-amount"}
                                    placeholder={" "}
                                    disabled={isFormDisabled}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: this.getNumericPattern(quoteAssetPrecision),
                                        min: minNotional,
                                        max: availableBase,
                                        step: tickSize
                                    }}
                                    name="totalPriceToOrder"
                                    type="number"
                                    onChange={v => {
                                        this.onFieldChange("totalPriceToOrder", v.target.value, values, setFieldValue)
                                    }}
                                />
                                <div className="order-currency">{base}</div>
                            </div>
                            <button disabled={isFormDisabled} type="submit"
                                    className={"buy-btn"}>{`Buy ${alt}`}</button>
                        </form>)
                }}
            </Formik>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    trades: userDealsSelector(state),
    userId: ownProps.userId || userIdSelector(state),
    lastBalance: lastBalanceSelector(state),
    openDeals: openDealsSelector(state),
    openDealsStats: openDealsStatsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getDeals: userId => dispatch(getUserDealsRequest(userId)),
    getUsersOpenDeals: userId => dispatch(getUserOpenDealsRequest(userId)),
    getBalances: userId => dispatch(getLiveBalanceRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuyOrder);
