import { createAction } from "redux-actions";

export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";

export const AUTH_LOG_OUT_REQUEST = "AUTH_LOG_OUT_REQUEST";
export const AUTH_LOG_OUT_SUCCESS = "AUTH_LOG_OUT_SUCCESS";
export const AUTH_LOG_OUT_FAILURE = "AUTH_LOG_OUT_FAILURE";

export const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";
export const AUTH_REFRESH_TOKEN_SUCCESS = "AUTH_REFRESH_TOKEN_SUCCESS";
export const AUTH_REFRESH_TOKEN_FAILURE = "AUTH_REFRESH_TOKEN_FAILURE";

export const authLoginRequest = createAction(AUTH_LOGIN_REQUEST);
export const authLoginSuccess = createAction(AUTH_LOGIN_SUCCESS);
export const authLoginFailure = createAction(AUTH_LOGIN_FAILURE);

export const authLogoutRequest = createAction(AUTH_LOG_OUT_REQUEST);
export const authLogoutSuccess = createAction(AUTH_LOG_OUT_SUCCESS);
export const authLogoutFailure = createAction(AUTH_LOG_OUT_FAILURE);

export const authRefreshTokenRequest = createAction(AUTH_REFRESH_TOKEN);
export const authRefreshTokenSuccess = createAction(AUTH_REFRESH_TOKEN_SUCCESS);
export const authRefreshTokenFailure = createAction(AUTH_REFRESH_TOKEN_FAILURE);
