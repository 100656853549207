import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { userBalancesSelector } from "../../../../../../../../../../selectors/user";
import { getUserBalancesRequest } from "../../../../../../../../../../actions/user";
import "../../../../../../../../../user/TraderDiary/styles/TraderDiary.scss";
import { Options } from "../../../../../../../../../../helper/statistics/Options";

const styles = () => ({
  root: {
    marginBottom: "2rem",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)"
  }
});

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {}
    };
  }

  componentDidMount() {
    const { getChartData, userId } = this.props;
    getChartData(userId);
  }

  render() {
    const { classes, chartData } = this.props;
    return (
      <div className={classes.root}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={Options(chartData)}
        />
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getChartData: userId => dispatch(getUserBalancesRequest(userId))
});

const mapStateToProps = state => ({
  chartData: userBalancesSelector(state)
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Chart);

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
  chartData: PropTypes.object,
  getChartData: PropTypes.func
};

Chart.defaultProps = {
  classes: {},
  chartData: {},
  getChartData: null
};
