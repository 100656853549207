import React, {Component} from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";

class RadioButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value:
                Object.entries(this.props.values).filter(el => el[1] === true)
                    .length !== 0
                    ? Object.entries(this.props.values).filter(el => el[1] === true)[0][0]
                    : ""
        };
    }

    handleChange = event => {
        this.setState({value: event.target.value});
        const {onChange} = this.props;
        onChange({[event.target.value]: true});
    };

    render() {
        const {value} = this.state;

        return (
            <FormControl component="fieldset">
                <RadioGroup
                    className={"radio-btns"}
                    aria-label="gender"
                    name="type"
                    value={value}
                    onChange={this.handleChange}
                >
                    <FormControlLabel
                        value="stopLossProfit"
                        control={<Radio color="primary"/>}
                        label="Stop Loss Profit"
                    />
                    <FormControlLabel
                        value="fixedProfit"
                        control={<Radio color="primary"/>}
                        label="Fixed Profit"
                    />
                    <FormControlLabel
                        value="dynamicProfit"
                        control={<Radio color="primary"/>}
                        label="Dynamic Profit"
                        disabled
                    />
                </RadioGroup>
            </FormControl>
        );
    }
}

export default RadioButtons;
