import * as Yup from "yup";

export const emailValidator = Yup.string()
  .email("Invalid email")
  .required("Required");

export const passwordValidator = Yup.string()
  .min(8, "Too Short!")
  .max(16, "Too Long!")
  .required("Required");

export const CreateUserValidationScheme = Yup.object().shape({
  email: emailValidator,
  password: passwordValidator
});
