import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// <FormControl sx={{ m: 1, minWidth: 120 }}>
//     <InputLabel id="select-label">{label}</InputLabel>
//     <Select
//         labelId="select-label"
//         {...props}
//     >
//         <MenuItem value="">
//             <em>None</em>
//         </MenuItem>
//         <MenuItem value={10}>Ten</MenuItem>
//         <MenuItem value={20}>Twenty</MenuItem>
//         <MenuItem value={30}>Thirty</MenuItem>
//     </Select>
//     <FormHelperText>With label + helper text</FormHelperText>
// </FormControl>
const SelectField = ({
                         options = [],
                            label,
                         value,
                         ...props
                     }) => {
    return (
    <FormControl sx={{ margin: "16px 0", minWidth: 120 }}>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
            // labelId="select-label"
            id="select-label-id"
            value={value}
            label={label}
            {...props}
        >
            {options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
        </Select>
    </FormControl>
    );
};

export default SelectField;
