import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Link, NavLink, Route, Switch} from "react-router-dom";
import StatisticTab from "./StatisticTab";
import UserActivityTab from "./UserActivityTab";
import UserSettings from "./UserSettingsTab";
import TradingSettings from "./TradingSettingsTab";
import TransactionsTab from "./TransactionsTab";
import RefereeTab from "./RefereeTab";
import LogsTab from "./LogsTab";
import NotFoundPage from "../../../../../../NotFoundPage";
import PrivateRoute from "../../../../../../../components/PrivateRoute";
import {getUserRequest} from "../../../../../../../actions/admin";
import {userSelector} from "../../../../../../../selectors/admin";
import * as Routes from "../../../../../../../routes/internal";
import "../styles/TabPanel.scss";

const UserHeader = ({header}) => (
    <div className="header subheader-new-user subheader">
        <div className="subhead-flex">
            <NavLink className="nav-title" to={"/admin/users"} activeClassName="">
                Users
            </NavLink>
            <div className="btn-create">
                <span>{header}</span>
                <NavLink className="close-btn" to={"/admin/users"} activeClassName=""/>
            </div>
        </div>
        <Link to={"/admin/users/create-new-user"}>
            <button>Create new user</button>
        </Link>
    </div>
);

class ScrollableTabsButtonAuto extends Component {
    componentDidMount() {
        const {getUser, userId} = this.props;
        getUser(userId);
    }

    render() {
        const {userId, user} = this.props;

        return (
            <div className="tab-panel">
                <UserHeader header={user && user.email}/>
                <nav className="nav-bar">
                    <NavLink
                        className="nav-bar-item stat"
                        to={`/admin/users/${userId}/statistics`}
                        activeClassName="active stat-active"
                    >
                        Statistics
                    </NavLink>
                    <NavLink
                        className="nav-bar-item ref"
                        to={`/admin/users/${userId}/referees`}
                        activeClassName="active ref-active"
                    >
                        Referees
                    </NavLink>
                    <NavLink
                        className="nav-bar-item user-settings"
                        to={`/admin/users/${userId}/user-settings`}
                        activeClassName="active user-settings-active"
                    >
                        User Settings
                    </NavLink>
                    <NavLink
                        className="nav-bar-item trad-settings"
                        to={`/admin/users/${userId}/trading-settings`}
                        activeClassName="active trad-settings-active"
                    >
                        Trading Settings
                    </NavLink>
                    <NavLink
                        className="nav-bar-item transact"
                        to={`/admin/users/${userId}/transactions`}
                        activeClassName="active transact-active"
                    >
                        Transactions
                    </NavLink>
                    <NavLink
                        className="nav-bar-item activity"
                        to={`/admin/users/${userId}/activity`}
                        activeClassName="active activity-active"
                    >
                        User Activity
                    </NavLink>
                    <NavLink
                        className="nav-bar-item activity"
                        to={`/admin/users/${userId}/logs`}
                        activeClassName="active activity-active"
                    >
                        Logs
                    </NavLink>
                </nav>
                <Switch>
                    <PrivateRoute path={Routes.statistics} component={StatisticTab}/>
                    <PrivateRoute path={Routes.referees} component={RefereeTab}/>
                    <PrivateRoute path={Routes.tradingSettings} component={TradingSettings}/>
                    <PrivateRoute path={Routes.userSettings} component={UserSettings}/>
                    <PrivateRoute path={Routes.transactions} component={TransactionsTab}/>
                    <PrivateRoute path={Routes.userActivity} component={UserActivityTab}/>
                    <PrivateRoute path={Routes.logs} component={LogsTab}/>
                    <Route component={NotFoundPage}/>
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: userSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getUser: userId => dispatch(getUserRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScrollableTabsButtonAuto);

ScrollableTabsButtonAuto.propTypes = {
    userId: PropTypes.string
};
