import * as Yup from "yup";

const emailValidator = Yup.string()
  .email("Invalid email")
  .required("Required");

export const UserSettingsSchema = Yup.object().shape({
  email: emailValidator,
  status: Yup.string().required("Required"),
  telegramBotInfo: Yup.object({
    token: Yup.string().required("Required")
  })
    .default(null)
    .nullable()
});
