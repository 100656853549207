import React from "react";
import {perc2color, roundCurrencyPrice, toNumeralFormat} from '../../../../../../utils/calculations';
import numeral from "numeral";

const getDate = milisec => {
    return new Date(milisec).toLocaleString();
};

const color_picker = {
    // "AWAITING_FOR_PROCESSING": "rgb(218, 192, 81)",
    // "AWAITING_FOR_PROCESSING": "#ecbd34",
    "AWAITING_FOR_PROCESSING": "#f0b90b",
    "COMPLETED": "green",
    "PROCESSING": "gray",
    "true": "red",
    "false": "green"
}

export const detailCellStyleColored = (isRed, fontWeight = 400) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "4em",
    paddingRight: "1em",
    backgroundColor: "rgb(247, 248, 250, 0.3)",
    // borderLeft: "1px solid rgba(224, 224, 224, 1)",
    color: isRed ? "red" : "green",
    fontWeight: fontWeight
});


export const detailCellStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "1em",
    paddingRight: "1em",
    backgroundColor: "rgb(247, 248, 250, 0.3)"
};

export const detailedHeaderStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f7f8fa",
    backgroundColor: "rgb(247, 248, 250, 0.8)",
    textTransform: "capitalize",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "1em",
    paddingRight: "1em"
};

export const firstDetailedHeaderStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f7f8fa",
    backgroundColor: "rgb(247, 248, 250, 0.8)",
    textTransform: "capitalize",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "4em",
    paddingRight: "1em"
};

export const headerStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f1f4f7",
    textTransform: "capitalize",
    // border: "none",
    textAlign: "center",
    fontWeight: "600",
    // width: '100%',
    paddingLeft: "0",
    paddingRight: "0",
};
export const cellStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "center",
    padding: "unset",
    // paddingLeft: "3em",
    // paddingRight: "1em",
};
export const firstHeaderStyle = {
    letterSpacing: "1px",
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    // backgroundColor: "#f1f4f7",
    textTransform: "capitalize",
    // border: "none",
    textAlign: "left",
    fontWeight: "600",
    paddingLeft: "2em",
    paddingRight: "1em",
};
export const firstCellStyle = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "2em",
    paddingRight: "1em",
};

export const firstCellStyleColored = (isRed = false) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "left",
    paddingLeft: "2em",
    paddingRight: "1em",
    color: isRed ? "red" : "green",
});

export const cellStyleColored = (isRed = false, fontWeight = 400, backgroundColor) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "center",
    paddingLeft: "1em",
    paddingRight: "1em",
    fontWeight: fontWeight,
    color: color_picker[isRed],
    // textAlign: "center",
    background: backgroundColor || "none",
});

export const cellStyleCustom = (isRed = false, fontWeight = 400, backgroundColor) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    textAlign: "center",
    paddingLeft: "1em",
    paddingRight: "1em",
    fontWeight: fontWeight,
    // color: color_picker[isRed],
    // textAlign: "center",
    background: backgroundColor || "none",
});

export const columns = [
    {
        Header: () => <p className="base-currency">E-mail</p>,
        id: "email",
        accessor: d => d.user.email
    },
    {
        Header: () => <p className="base-currency">Name</p>,
        id: "name",
        accessor: d => d.user.name
    },
    {
        Header: () => <p className="base-currency">Base Currency</p>
    },
    {
        Header: () => <p className="base-currency">Number of Deals</p>,
        id: "numberOfDeals",
        accessor: d => d.userStatistic.numberOfDeals
    },
    {
        Header: () => <p className="base-currency">Free</p>,
        id: "fee",
        accessor: d => d.user.profitFeePercent
    },
    {
        Header: () => <p className="base-currency">In Order</p>
    },
    {
        Header: () => <p className="base-currency">Total Profit</p>,
        id: "totalProfit",
        accessor: d => d.userStatistic.totalProfit
    },
    {
        Header: () => <p className="base-currency">Account Status</p>,
        id: "status",
        accessor: d => d.user.status,
        Cell: props => (
            <div
                className={
                    props.value === "ACTIVE" ? "active-status" : "passive-status"
                }
            ></div>
        )
    }
];

//
// availableBase: 1658.1
//
// balanceInBase: 7081.214594600306
//
// base: "USDT"
//
// closedDeals: 1280
//
// diffOfAvailableBase: 165810
//
// email: "miner@gmail.com"
//
// id: "5f6ef8f061196139b8cd61ae"
//
// multiplierConfig: 7
//
// name: ""
//
// openedDeals: 5
//
// profitPercentConfig: 3
//
// status: {states: [], paused: false, freezed: false}
//
// tableData: {id: 0}
//
// totalProfit: 5423.11
//
// usedBase: 1471.63


export const userColumns = [
    {
        title: "E-mail",
        field: "email",
        headerStyle: firstHeaderStyle,
        cellStyle: firstCellStyle
    },
    {
        title: "Base",
        field: "base",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "CDeals",
        field: "closedDeals",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "ODeals",
        field: "openedDeals",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Pairs",
        field: "tradingPairs",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "EMultiplier",
        field: "possibleMultiplier",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
    },
    {
        title: "Multiplier",
        field: "multiplierConfig",
        type: "numeric",
        headerStyle: headerStyle,
        // cellStyle: cellStyle
        // cellStyle: (cell, d) => {
        //     console.log('Expected Multiplier cell ===>', d)
        //
        //     return
        // },
        render: rowData => {
            let perc = 50.0
            let top = 1.2 * rowData.possibleMultiplier
            let bottom = rowData.possibleMultiplier * 0.95
            if (rowData.multiplierConfig > rowData.possibleMultiplier) {
                if (rowData.multiplierConfig > top) {
                    perc = 0.0
                } else {
                    perc = (1 - (rowData.multiplierConfig / rowData.possibleMultiplier)) * 100
                }

                // perc = (rowData.multiplierConfig / rowData.possibleMultiplier) * 100
            } else {
                if (rowData.multiplierConfig <= bottom) {
                    perc = 100.0
                } else {
                    perc = perc - ((rowData.possibleMultiplier / rowData.multiplierConfig) - 1) * 50
                }
            }
            let color = perc2color(perc)
            if(perc === 50.0){
                color = "inherit"
            }
            return <div style={cellStyleCustom(false, 600, color)}
            >{rowData.multiplierConfig}</div>
        }

        // render: rowData => {
        //     },

    },
    {
        title: "Profit %",
        field: "profitPercentConfig",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Total Balance",
        field: "balanceInBase",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: rowData => {
            return <div
            >{toNumeralFormat(rowData.balanceInBase, rowData.base)}</div>},
    },
    {
        title: "Balance To Trade",
        field: "possibleBalanceForBotInBase",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: rowData => {
            return <div
            >{toNumeralFormat(rowData.possibleBalanceForBotInBase, rowData.base)}</div>},
    },
    {
        title: "Available",
        field: "availableBase",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: rowData => {
            return <div
            >{toNumeralFormat(rowData.availableBase, rowData.base)}</div>},
    },
    {
        title: "Used",
        field: "usedBase",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: rowData => {
            return <div
            >{toNumeralFormat(rowData.usedBase, rowData.base)}</div>},
    },{
        title: "Available %",
        field: "diffOfAvailableBase",
        type: "numeric",
        headerStyle: headerStyle,
        defaultSort: "asc",
        cellStyle: cell => {
            // console.log('cell ===>', cell)

            return cellStyleColored(false, 600, perc2color(cell))
        },


    },
    {
        title: "Profit",
        field: "totalProfit",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: rowData => {
            return <div
            >{toNumeralFormat(rowData.totalProfit, rowData.base)}</div>},
    },

    {
        title: "Status",
        field: "status",
        headerStyle: headerStyle,
        customSort: (a, b) => b.openDate - a.openDate,
        render: rowData => {
            let s = "Active"
            if (rowData.status){
                if(rowData.status.states && rowData.status.states.length > 0){
                    s = rowData.status.states.join(", ")
                }
            }else{
                s = "Inactive"
            }


            return <div
            className={
                s === "Active" ? "active-status" : "passive-status"
            }
        >{s}</div>},
        defaultSort: "asc",
        cellStyle: cellStyle
    }
];
export const columnsDetails = [
    {
        title: "Pair",
        field: "pair",
        headerStyle: firstDetailedHeaderStyle,
        cellStyle: firstCellStyle
    },
    {
        title: "AVG buy Price",
        field: "buyPrice",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "AVG sell price",
        field: "sellPrice",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Volume Base",
        field: "value",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Profit Base",
        field: "profit",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Profit %",
        field: "diff",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Trades",
        field: "tradesCount",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: cellStyle
    },
    {
        title: "Open Date",
        field: "openDate",
        type: "date",
        headerStyle: detailedHeaderStyle,
        customSort: (a, b) => a.openDate - b.openDate,
        render: rowData => <div>{getDate(rowData.openDate)}</div>,
        // defaultSort: "asc",
        cellStyle: cellStyle
    },
    {
        title: "Close Date",
        field: "closeDate",
        type: "date",
        headerStyle: detailedHeaderStyle,
        customSort: (a, b) => a.closeDate - b.closeDate,
        render: rowData => <div>{getDate(rowData.closeDate)}</div>,
        defaultSort: "desc",
        // defaultSort: "asc",
        cellStyle: cellStyle
    }
];
export const openDealsColumns = [
    {
        title: "Pair",
        field: "pair",
        headerStyle: firstHeaderStyle,
        cellStyle: firstCellStyle
    },
    {
        title: "AVG buy Price",
        field: "buyPrice",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Current price",
        field: "currentPrice",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Minimal Sell Price",
        field: "expectedSellPrice",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Diff %",
        field: "diff",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cell => {
            return cellStyleColored(parseFloat(cell) < 0, 600)
        },
        defaultSort: "asc",
    },
    {
        title: "Volume Base",
        field: "value",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Volume Alt",
        field: "amount",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Trades",
        field: "tradesCount",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Open Date",
        field: "openDate",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.openDate - a.openDate,
        render: rowData => <div>{getDate(rowData.openDate)}</div>,
        defaultSort: "asc",
        cellStyle: cellStyle
    }
];

export const detailColumns = [
    {
        title: "Side",
        field: "side",
        headerStyle: firstDetailedHeaderStyle,
        cellStyle: cell => {
            return detailCellStyleColored(cell === "SELL", 600)
        }
    },
    {
        title: "Price",
        field: "price",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle,
    },
    {
        title: "Volume Alt",
        field: "amount",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle
    },
    {
        title: "Volume Base",
        field: "quoteQty",
        type: "numeric",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle
    },
    {
        title: "Date",
        field: "date",
        type: "date",
        customSort: (a, b) => b.date - a.date,
        render: rowData => <div>{getDate(rowData.date)}</div>,
        defaultSort: "asc",
        headerStyle: detailedHeaderStyle,
        cellStyle: detailCellStyle
    }
];

export const transactionColumns = [
    {
        title: "From Date",
        field: "start",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.start - a.start,
        render: rowData => <div>{getDate(rowData.start)}</div>,
        // defaultSort: "asc",
        cellStyle: cellStyle
    },
    {
        title: "To Date",
        field: "end",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.end - a.end,
        render: rowData => <div>{getDate(rowData.end)}</div>,
        defaultSort: "asc",
        cellStyle: cellStyle
    },
    {
        title: "Status",
        field: "status",
        headerStyle: headerStyle,
        // cellStyle: firstCellStyle
        // cellStyle: cell => {
        //     console.log('cell ===>', cell)
        //     return cellStyleColored(parseFloat(cell) < 0, 600)
        // },
        render: rowData => <div style={{fontStyle: "italic"}}>{rowData.status.replaceAll("_", " ")}</div>,
        cellStyle: cell => {
            return cellStyleColored(cell, 600)
        }
    },
    {
        title: "Profit By Period",
        field: "totalProfit",
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Fee %",
        field: "feePercent",
        type: "numeric",
        render: rowData => <div>{rowData.feePercent * 100}</div>,
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Fee To Pay",
        field: "totalFee",
        type: "numeric",
        headerStyle: headerStyle,
        // cellStyle: cellStyle
        cellStyle: cell => {
            return cellStyleColored(true)
        }
    },

    {
        title: "Currency",
        field: "base",
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },

    {
        title: "Clear Profit",
        field: "feePercent",
        type: "numeric",
        render: rowData =>
            <div>{roundCurrencyPrice(rowData.totalProfit - rowData.totalFee, rowData.base)}</div>,
        headerStyle: headerStyle,
        // cellStyle: cellStyle

        cellStyle: cell => {
            return cellStyleColored(false)
        }
    },


];
export const referralTransactionsColumns = [
    {
        title: "From Date",
        field: "start",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.start - a.start,
        render: rowData => <div>{getDate(rowData.start)}</div>,
        // defaultSort: "asc",
        cellStyle: cellStyle
    },
    {
        title: "To Date",
        field: "end",
        type: "date",
        headerStyle: headerStyle,
        customSort: (a, b) => b.end - a.end,
        render: rowData => <div>{getDate(rowData.end)}</div>,
        defaultSort: "asc",
        cellStyle: cellStyle
    },

    {
        title: "Status",
        field: "status",
        headerStyle: firstHeaderStyle,
        // cellStyle: firstCellStyle
        // cellStyle: cell => {
        //     console.log('cell ===>', cell)
        //     return cellStyleColored(parseFloat(cell) < 0, 600)
        // },
        render: rowData => <div style={{fontStyle: "italic"}}>{rowData.status.replaceAll("_", " ")}</div>,
        cellStyle: cell => {
            return cellStyleColored(cell, 600)
        }
    },

    {
        title: "Bonus In Currency",
        field: "totalFee",
        type: "numeric",
        render: rowData =>
            <div>{roundCurrencyPrice(rowData.totalFee, rowData.base)}</div>,
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },
    {
        title: "Bonus %",
        field: "feePercent",
        type: "numeric",
        render: rowData => <div>{rowData.feePercent * 100}</div>,
        headerStyle: headerStyle,
        cellStyle: cellStyle
    },

    {
        title: "Currency",
        field: "base",
        headerStyle: firstHeaderStyle,
        cellStyle: firstCellStyle
    },


];

