import { GET_24_HOURS_STATS_SUCCESS } from "../actions/binance";

const initialState = {
  statistics: null
};

const replaceStatistic = (statistics, newItem) => {
  const newStatistics = { ...statistics };
  newStatistics[newItem.symbol] = {
    priceChange: newItem.priceChange,
    lastPrice: newItem.lastPrice
  };
  return newStatistics;
};

const binance = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === GET_24_HOURS_STATS_SUCCESS) {
    return {
      ...state,
      statistics: replaceStatistic(state.statistics, payload)
    };
  }

  return state;
};

export default binance;
