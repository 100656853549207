import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { userBalanceSelector } from "../../../../../../../../../../selectors/userStatistic";
import { getLiveBalanceRequest } from "../../../../../../../../../../actions/userStatistics";
import RateItem from "./RateItem";
import "../../styles/StatisticTab.scss";

class Rates extends React.Component {
  componentDidMount() {
    const { getBalance, userId } = this.props;
    getBalance(userId);
  }

  render() {
    const { balances } = this.props;
    return (
      <div className="statistic">
        <div className="rate-wrapper">
          {balances.map((balance, index) =>
            balance.name !== "BNB" ? (
              <RateItem key={index} balance={balance} />
            ) : (
              <div key={index} />
            )
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBalance: userId => dispatch(getLiveBalanceRequest(userId))
});

const mapStateToProps = state => ({
  balances: userBalanceSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rates);

Rates.propTypes = {
  getBalance: PropTypes.func,
  balances: PropTypes.arrayOf(PropTypes.object)
};
Rates.defaultProps = {
  getBalance: null,
  balances: []
};
