import { all } from "redux-saga/effects";
import auth from "./auth";
import admin from "./admin";
import user from "./user";
import binance from "./binance";
import userStatistic from "./userStatistics";

export default function* rootSaga() {
  yield all([auth(), admin(), user(), binance(), userStatistic()]);
}
