import { CLOSE_MODAL, OPEN_MODAL } from "../actions/app";

const initialState = {
  isFetching: false,
  modals: {
    userCreationForm: {
      open: false
    }
  }
};

function isEndOfRequest(type) {
  return type.includes("SUCCESS") || type.includes("FAILURE");
}

function isStartOfRequest(type) {
  return type.includes("REQUEST");
}

const changeByKey = (modals, key) => {
  const newModals = { ...modals };
  newModals[key].open = !newModals[key].open;
  return newModals;
};

const app = (state = initialState, action) => {
  const { payload, type } = action;

  if (type && isStartOfRequest(type)) {
    return {
      ...state,
      isFetching: true
    };
  }

  if (type && isEndOfRequest(type)) {
    return {
      ...state,
      isFetching: false
    };
  }

  switch (type) {
    case OPEN_MODAL:
    case CLOSE_MODAL:
      return {
        ...state,
        modals: changeByKey(state.modals, payload)
      };

    default:
      return state;
  }
};

export default app;
