import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBtc } from "@fortawesome/free-brands-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

import classNames from "classnames";

const styles = theme => ({
  root: {},
  value: {
    fontSize: "0.9rem",
    display: "flex",
    justifyContent: "flex-start",
    color: theme.color.black
  },
  currencyIcon: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1.8rem"
  },
  change: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "0.7rem",
    color: theme.color.increaseGreen
  },
  plus: {
    ...theme.scrooge.plus
  },
  minus: {
    ...theme.scrooge.minus
  }
});

class RateItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balances: [],
      faIcon: null,
      round: 0
    };
  }

  getCurrencyInfo = currencyName => {
    switch (currencyName) {
      case "ETH":
        return { faIcon: faEthereum, round: 5 };
      case "BTC":
        return { faIcon: faBtc, round: 8 };
      case "USDT":
        return { faIcon: faDollarSign, round: 2 };
      default:
        return {};
    }
  };

  componentDidMount() {
    const { balance } = this.props;
    this.setState({ ...this.getCurrencyInfo(balance.name) });
  }

  render() {
    const { classes, balance } = this.props;
    const { faIcon, round } = this.state;
    return (
      <div className={classes.root}>
        {faIcon && (
          <Grid container>
            <Grid item xs={6} className={classes.currencyIcon}>
              <FontAwesomeIcon icon={faIcon} className={classes.currencyIcon} />
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12} className={classes.value}>
                  {balance.value.toFixed(round)}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classNames(
                    classes.change,
                    balance.value > 0 ? classes.plus : classes.minus
                  )}
                >
                  {balance.value.toFixed(round)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

RateItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RateItem);
