import React from "react";
import { Field } from "formik";
import {Slider} from "@mui/material";

export const FormikSliderField = ({
  children,
  ...props
}) => {
  return (
    <Field
      {...props}
      component={Slider}
    >
      {children}
    </Field>
  );
};
