import React from "react";
import {connect} from "react-redux";
import {getUsersRequest} from "../../../../../../actions/admin";
import {
    usersAwaitingFeeSelector,
    usersBalanceSelector,
    usersCompletedFeeSelector,
    usersCompletedSystemFeeSelector,
    usersFutureFeeSelector,
    usersFutureSystemFeeSelector,
    usersLastUpdateSelector,
    usersPageContentSelector,
    usersPageCountSelector,
    usersProfitSelector
} from "../../../../../../selectors/admin";
import {isFetchingSelector} from "../../../../../../selectors/app";
import PageableTable from "../../../../../../components/PageableTable/components";
import {columns, userColumns} from "./tableColumns";
import {Link} from "react-router-dom";
import "../../../styles/users-page.scss";
import DealsChart from "../../../../../user/TraderDiary/components/DealsChart";
import {roundCurrencyPrice, toNumeralFormat} from "../../../../../../utils/calculations";
import numeral from "numeral";
import moment from "moment";
import SyncIcon from '@material-ui/icons/Sync';


const UsersPageHeader = () => (
    <div className="subheader">
        <label>Users</label>
        <Link to={"/admin/users/create-new-user"}>
            <button>Create new user</button>
        </Link>
    </div>
);

class UsersTableSubPage extends React.Component {
    state = {}

    goToCertainUser = (userId) => {
        const {
            history: {
                location: {pathname}
            },
            history
        } = this.props;
        history.push(pathname + "/" + userId + "/statistics");
    };

    // fetchDataCallback = params => {
    //     const {getUsers} = this.props;
    //     getUsers(params);
    // };

    componentDidMount() {
        // this.getData();
        const {getUsers, lastUpdate} = this.props;
        if (!lastUpdate) {
            getUsers();
        }
    }

    title = () => {
        const {lastUpdate, getUsers} = this.props;
        return <div className="last-update">
            <div className="title margin-none">Last
                sync: <span>{moment(lastUpdate).format('MMMM Do YYYY, HH:mm:ss')}</span></div>
            <SyncIcon className="last-update_btn" onClick={() => {
                getUsers()
            }}>Sync</SyncIcon>
        </div>
    }

    render() {
        const {content, count, isFetching, profit, balance, futureSystemFee, completedSystemFee} = this.props;


        return (
            <div className="users-table-subpage">
                <UsersPageHeader/>

                <div className="block-white" style={{marginBottom: '30px'}}>

                    <div className="trader-summary">
                        {Object.keys(profit).map(k => {
                            return <div key={`profit_${k}`} className="trader-summary__block">
                                <div className="trader-summary__block__title">{`Profit ${k}`}</div>
                                <div className="trader-summary__block__data green">
                                    {toNumeralFormat(profit[k], k)}
                                </div>
                            </div>
                        })}

                        {Object.keys(balance).map(k => {
                            return <div key={`balance_${k}`} className="trader-summary__block">
                                <div className="trader-summary__block__title">{`Balance ${k}`}</div>
                                <div className="trader-summary__block__data">
                                    {toNumeralFormat(balance[k], k)}
                                </div>
                            </div>
                        })}


                    </div>
                    <div className="trader-summary">
                        {Object.keys(completedSystemFee).map(k => {
                            return <div key={`completedSystemFee_${k}`} className="trader-summary__block">
                                <div className="trader-summary__block__title">{`Paid fee ${k}`}</div>
                                <div className="trader-summary__block__data green">
                                    {toNumeralFormat(completedSystemFee[k], k)}
                                </div>
                            </div>
                        })}

                        {Object.keys(futureSystemFee).map(k => {
                            return <div key={`futureSystemFee_${k}`} className="trader-summary__block">
                                <div className="trader-summary__block__title">{`Fee to pay ${k}`}</div>
                                <div className="trader-summary__block__data yellow">
                                    {toNumeralFormat(futureSystemFee[k], k)}
                                </div>
                            </div>
                        })}
                    </div>
                </div>

                <div className="users-table">
                    <PageableTable
                        className="table-shadow"
                        pageable={true}
                        sortEnabled={false}
                        columns={userColumns}
                        content={content}
                        count={count}
                        title={this.title}
                        // fetchDataCallback={this.fetchDataCallback}
                        rowOnClickCallback={this.goToCertainUser}
                        loading={isFetching}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: params => {
            dispatch(getUsersRequest(params));
        }
    };
};

const mapStateToProps = state => ({
    content: usersPageContentSelector(state),
    count: usersPageCountSelector(state),
    isFetching: isFetchingSelector(state),
    lastUpdate: usersLastUpdateSelector(state),
    profit: usersProfitSelector(state),
    balance: usersBalanceSelector(state),
    futureFee: usersFutureFeeSelector(state),
    completedFee: usersCompletedFeeSelector(state),
    awaitingFee: usersAwaitingFeeSelector(state),
    futureSystemFee: usersFutureSystemFeeSelector(state),
    completedSystemFee: usersCompletedSystemFeeSelector(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersTableSubPage);
