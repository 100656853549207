import * as moment from 'moment';
import * as mTZ from 'moment-timezone';

window.moment = moment;
mTZ();

export const Options = (data, alignZoomButton = 'left', currency = 'USD', round = 2, dateRangeHandler = (startDate, endDate) => {
}) => ({
    chart: {
        backgroundColor: '#FCFFC5',
        // type: 'area',
        spacing: 35,
        // height: (9 / 30 * 100) + '%',
        // height: 500,
        height: 500,
        // width: (20 / 9 * 100) + '%'
        minWidth: 800,
        events: {
            redraw: function () {
                try {
                    let sd = this.xAxis[0].userMin
                    let ed = this.xAxis[0].userMax
                    dateRangeHandler(sd, ed)
                } catch (e) {
                    console.log(e)
                }
            }
        },
        // time:{
        //     timezone: 'Europe/London',
        //     // useUTC: false,
        //     // timezoneOffset: 4
        // }
        // time: {
        //     timezone: 'Europe/Berlin'
        // },
        // xAxis: {
        //     type: 'datetime'
        // },

    },
    // xAxis: {
    //     type: 'datetime'
    // },
    time: {
        timezone: moment.tz.guess(),
        moment: moment
    },
    stockTools: {
        gui: {
            toolbarClassName: 'tools-container',
            enabled: true,
        },
    },
    title: {
        margin: -30,
        // text: 'Test',
        verticalAlign: "top",
        align: 'left',
        y: 15
    },
    rangeSelector: {
        // inputEnabled: false,
        buttonPosition: {
            align: alignZoomButton,
        },
        // allButtonsEnabled: true,
        // buttons: [
        //     {
        //         type: 'week',
        //         count: 1,
        //         text: '1W',
        //     },
        //     {
        //         type: 'month',
        //         count: 1,
        //         text: '1M',
        //     },
        //     {
        //         type: 'year',
        //         count: 1,
        //         text: '1Y',
        //     },
        //     {
        //         type: 'all',
        //         text: 'ALL',
        //     },
        // ],
        // selected: 0,
        // buttonTheme: {
        //     width: 65,
        //     fill: '#fff',
        //     stroke: 'none',
        //     'stroke-width': 0,
        //     r: 8,
        //     style: {
        //         color: '#646a75',
        //         fontWeight: 'normal',
        //         fontSize: '18px',
        //         fontFamily: 'Montserrat',
        //         letterSpacing: '1px',
        //     },
        //     states: {
        //         hover: {},
        //         select: {
        //             fill: '#fff',
        //             style: {
        //                 color: '#505bda',
        //             },
        //         },
        //     },
        // },
        // inputBoxBorderColor: 'none',
        // inputBoxWidth: 200,
        // inputStyle: {
        //     color: '#039',
        //     fontWeight: 'bold',
        // },
        // labelStyle: {
        //     visibility: 'hidden',
        // },
    },
    lang: {
        noData: 'There is no data',
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
        },
    },

    yAxis: {
        // max:
        //     (data || []).map(el => el[1]).sort()[data.length - 2] < 1
        //         ? (data || []).map(el => el[1]).sort()[data.length - 2] + 0.2
        //         : (data || []).map(el => el[1]).sort()[data.length - 2] * 1.02,
        // min:
        //     (data || []).map(el => el[1]).sort()[0] < 1
        //         ? (data || []).map(el => el[1]).sort()[0] + 0.2
        //         : (data || []).map(el => el[1]).sort()[0] * 0.98,
        gridLineWidth: 0.4,
        minorGridLineWidth: 0.4,
        labels: {
            // enabled: false
        },
    },
    // xAxis: {
    //     labels: {
    //         style: {
    //             color: '#000000',
    //             fontSize: '12px',
    //             fontFamily: 'Montserrat',
    //             paddingTop: 'px',
    //         },
    //     },
    // },
    // scrollbar: {
    //   enabled: false
    // },
    //
    // navigator: {
    //   enabled: false
    // },
    series: [
        {
            // id: 'aapl',
            name: currency,
            data: data,
            // dataLabels: {
            //     enabled: true,
            //     format: '{x:%H:%M}'
            // },
            // color: '#EDEEFB',
            tooltip: {
                valueDecimals: round,
                zIndex: 999,
            },
        },
    ],
    tooltip: {
        display: "none",
        valueDecimals: round,
        zIndex: 999,
    },
    plotOptions: {
        series: {
            showInLegend: false,
            lineColor: '#505bda',
            marker: {
                fillColor: '#505bda',
                // enabled: false
            },
        },
    },
});

export const ColumnOptions = (data, alignZoomButton = 'left', currency = 'USD', round = 2, dateRangeHandler = (startDate, endDate) => {
}) => ({
    chart: {
        backgroundColor: '#FCFFC5',
        type: 'column',
        spacing: 35,
        height: (9 / 30 * 100) + '%',
        // width: (20 / 9 * 100) + '%'
        // events: {
        //     redraw: function () {
        //         try {
        //             let sd = this.xAxis[0].userMin
        //             let ed = this.xAxis[0].userMax
        //             dateRangeHandler(sd, ed)
        //         } catch (e) {
        //             console.log(e)
        //         }
        //     }
        // },
        // time:{
        //     timezone: 'Europe/London',
        //     // useUTC: false,
        //     // timezoneOffset: 4
        // }
        // time: {
        //     timezone: 'Europe/Berlin'
        // },
        // xAxis: {
        //     type: 'datetime'
        // },

    },
    // xAxis: {
    //     type: 'datetime'
    // },
    time: {
        timezone: moment.tz.guess(),
        moment: moment
    },
    stockTools: {
        gui: {
            toolbarClassName: 'tools-container',
            enabled: true,
        },
    },
    title: {
        margin: -30,
        // text: 'Test',
        verticalAlign: "top",
        align: 'left',
        y: 15
    },
    rangeSelector: {
        // inputEnabled: false,
        buttonPosition: {
            align: alignZoomButton,
        },
        // allButtonsEnabled: true,
        // buttons: [
        //     {
        //         type: 'week',
        //         count: 1,
        //         text: '1W',
        //     },
        //     {
        //         type: 'month',
        //         count: 1,
        //         text: '1M',
        //     },
        //     {
        //         type: 'year',
        //         count: 1,
        //         text: '1Y',
        //     },
        //     {
        //         type: 'all',
        //         text: 'ALL',
        //     },
        // ],
        // selected: 0,
        // buttonTheme: {
        //     width: 65,
        //     fill: '#fff',
        //     stroke: 'none',
        //     'stroke-width': 0,
        //     r: 8,
        //     style: {
        //         color: '#646a75',
        //         fontWeight: 'normal',
        //         fontSize: '18px',
        //         fontFamily: 'Montserrat',
        //         letterSpacing: '1px',
        //     },
        //     states: {
        //         hover: {},
        //         select: {
        //             fill: '#fff',
        //             style: {
        //                 color: '#505bda',
        //             },
        //         },
        //     },
        // },
        // inputBoxBorderColor: 'none',
        // inputBoxWidth: 200,
        // inputStyle: {
        //     color: '#039',
        //     fontWeight: 'bold',
        // },
        // labelStyle: {
        //     visibility: 'hidden',
        // },
    },
    lang: {
        noData: 'There is no data',
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
        },
    },

    yAxis: {
        // max:
        //     (data || []).map(el => el[1]).sort()[data.length - 2] < 1
        //         ? (data || []).map(el => el[1]).sort()[data.length - 2] + 0.2
        //         : (data || []).map(el => el[1]).sort()[data.length - 2] * 1.02,
        // min:
        //     (data || []).map(el => el[1]).sort()[0] < 1
        //         ? (data || []).map(el => el[1]).sort()[0] + 0.2
        //         : (data || []).map(el => el[1]).sort()[0] * 0.98,
        gridLineWidth: 0.4,
        minorGridLineWidth: 0.4,
        labels: {
            // enabled: false
        },
    },
    xAxis: {
        categories: (data || []).map(d => d[0]),
        crosshair: true
        // labels: {
        //     style: {
        //         color: '#000000',
        //         fontSize: '12px',
        //         fontFamily: 'Montserrat',
        //         paddingTop: 'px',
        //     },
        // },
    },
    // scrollbar: {
    //   enabled: false
    // },
    //
    // navigator: {
    //   enabled: false
    // },
    series: [{
        // id: 'aapl',
        // name: currency,
        data: data,

        // dataLabels: {
        //     enabled: true,
        //     format: '{x:%H:%M}'
        // },
        // color: '#EDEEFB',
        tooltip: {
            valueDecimals: round,
            zIndex: 999,
        }
    }],
    tooltip: {
        valueDecimals: round,
        zIndex: 999,
    },
    plotOptions: {
        // series: {
        //     showInLegend: false,
        //     lineColor: '#505bda',
        //     marker: {
        //         fillColor: '#505bda',
        //         // enabled: false
        //     },
        // },
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },


});
