import {
    GET_USER_BALANCES_FAILURE,
    GET_USER_BALANCES_REQUEST,
    GET_USER_BALANCES_SUCCESS,
    GET_USER_LAST_BALANCE_FAILURE,
    GET_USER_LAST_BALANCE_REQUEST,
    GET_USER_LAST_BALANCE_SUCCESS,
    GET_USER_REFEREES_SUCCESS,
    GET_USER_REFERRAL_CODE_SUCCESS,
    USER_UPDATE,
    GET_USER_DEALS_REQUEST,
    GET_USER_DEALS_SUCCESS,
    GET_USER_DEALS_FAILURE, GET_USER_OPEN_DEALS_SUCCESS, GET_USER_OPEN_DEALS_FAILURE
} from "../actions/user";
import {AUTH_REFRESH_TOKEN_FAILURE} from "../actions/auth";

const initialState = {
    user: null,
    referees: [],
    referralCode: null,
    balances: null,
    lastBalance: {},
    trades: null,
    deals: null,
    openDeals: null,
    error: null,
    refereesLastUpdate: null,
    refereesUserId: null,
    isUserRefereesLoading: false
};

const user = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case USER_UPDATE:
            return {
                ...state,
                user: payload,
                error: null
            };
        case GET_USER_REFEREES_SUCCESS:
            return {
                ...state,
                referees: payload.referees,
                refereesUserId: payload.userId,
                refereesLastUpdate: new Date(),
                error: null
            };
        case GET_USER_REFERRAL_CODE_SUCCESS:
            return {
                ...state,
                referralCode: payload.code,
                error: null
            };
        case GET_USER_BALANCES_REQUEST:
            return {
                ...state,
                error: null
            };
        case GET_USER_BALANCES_SUCCESS:
            return {
                ...state,
                balances: payload,
                error: null
            };
        case GET_USER_BALANCES_FAILURE:
            return {
                ...state,
                error: payload
            };
        case GET_USER_LAST_BALANCE_SUCCESS:
            return {
                ...state,
                lastBalance: payload,
                error: null
            };
        case GET_USER_LAST_BALANCE_FAILURE:
            return {
                ...state,
                error: payload
            };

        case GET_USER_DEALS_REQUEST:
            return {
                ...state,
                error: null
            };
        case GET_USER_DEALS_SUCCESS:
            return {
                ...state,
                trades: payload,
                error: null
            };
        case GET_USER_DEALS_FAILURE:
            return {
                ...state,
                error: payload
            };
        case GET_USER_OPEN_DEALS_SUCCESS:
            return {
                ...state,
                openDeals: payload,
                error: null
            };
        case GET_USER_OPEN_DEALS_FAILURE:
            return {
                ...state,
                error: payload
            };
        case AUTH_REFRESH_TOKEN_FAILURE:
            return initialState;
        default:
            return state;
    }
};

export default user;
