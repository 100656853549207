import {call, put, takeEvery} from "redux-saga/effects";
import {forwardTo} from "../helper/forwardTo/forwardTo";
import {
  AUTH_LOG_OUT_REQUEST,
  AUTH_LOGIN_REQUEST,
  AUTH_REFRESH_TOKEN,
  authLoginFailure,
  authLoginSuccess,
  authLogoutFailure,
  authLogoutRequest,
  authLogoutSuccess,
  authRefreshTokenFailure,
  authRefreshTokenSuccess
} from "../actions/auth";
import {login, logout, refreshToken} from "../api/auth";
import {userUpdate} from "../actions/user";
import {getTradingConfig} from "../api/user";

function* authSaga(action) {
  try {
    const response = yield call(login, action.payload);

    let data = response.data;
    yield put(authLoginSuccess(data.jwt));


    let user = data.user
    const tradingConfig = yield call(getTradingConfig, data.user.id)
    user.tradingConfig = tradingConfig.data

    yield put(userUpdate(user));

    if (data.user.roles[0] === "ROLE_CLIENT") {
      yield call(forwardTo("/user/home"));
    } else {
      yield call(forwardTo("/admin/users"));
    }
  } catch (e) {
    console.log(AUTH_LOGIN_REQUEST, e);
    yield put(authLoginFailure(e.status));
  }
}

function* logoutSaga(action) {
  try {
    yield call(logout, action.payload);
    yield put(authLogoutSuccess());
    yield put(userUpdate(null));
  } catch (e) {
    console.log(AUTH_LOG_OUT_REQUEST, e);
    yield put(userUpdate(null));
    yield put(authLogoutFailure(e.data));
    // yield put(userUpdate(null));
    // yield call(forwardTo("/login"));
  }
}

function* refreshSaga(action) {
  try {
    const response = yield call(refreshToken, action.payload.refreshToken);

    yield put(authRefreshTokenSuccess(response.data));

    if (action.payload.retryAction.type === AUTH_LOG_OUT_REQUEST) {
      //retry logout with new body (refresh token)
      yield put(authLogoutRequest(response.data.refreshToken));
    } else {
      //retry action before token refreshed
      yield put(action.payload.retryAction);
    }
  } catch (e) {
    console.log(AUTH_REFRESH_TOKEN, e);
    yield put(authRefreshTokenFailure());
  }
}

function* watchAuthSaga() {
  yield takeEvery(AUTH_LOGIN_REQUEST, authSaga);
  yield takeEvery(AUTH_LOG_OUT_REQUEST, logoutSaga);
  yield takeEvery(AUTH_REFRESH_TOKEN, refreshSaga);
}

export default watchAuthSaga;
