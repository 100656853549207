import React, {Component} from "react";
import "../styles/PageableTable.scss";
import MaterialTable from "@material-table/core";
import Paper from "@material-ui/core/Paper";

class PageableTable extends Component {
    state = {
        page: 0,
        size: 10,
        sortField: null,
        sortDirection: "asc",
        selected: null,
        prevScrollProps: window.pageXOffset,
        visible: true
    };

    componentDidMount() {
        // this.getData();
    }

    buildParams = () => {
        const {pageable, sortEnabled} = this.props;
        const {sortDirection, sortField, page, size} = this.state;
        return {
            page: pageable ? page : null,
            size: pageable ? size : null,
            sortProperty: sortEnabled ? sortField : null,
            sortDirection: sortEnabled ? sortDirection.toUpperCase() : null
        };
    };

    // getData = () => {
    //     const {fetchDataCallback} = this.props;
    //     const params = this.buildParams();
    //
    //     fetchDataCallback(params);
    // };

    onRowClick = (state, rowInfo) => {
        const {rowOnClickCallback} = this.props;
        if (rowInfo && rowInfo.tableData) {
            this.setState({
                selected: rowInfo.tableData.id
            });
            rowOnClickCallback(rowInfo.id);
        }
    };

    render() {
        const {
            content,
            count,
            columns,
            showPagination,
            loading,
            className,
            title
        } = this.props;

        return (
            <div className="pageable-table">
                {loading ? (
                    <div className="loading-wrapper">
                        <div className="linear-activity">
                            <div className="indeterminate"/>
                        </div>
                    </div>
                ) : null}

                <MaterialTable
                    className={content.length === 0 ? "invisible" : className}
                    isLoading={loading}
                    options={{
                        emptyRowsWhenPaging: false,
                        sorting: true,
                        exportButton: false,
                        search: true,
                        searchAutoFocus: false,
                        toolbar: true,
                        title: "User",
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        headerStyle: {
                            backgroundColor: 'rgb(241, 244, 247)',
                        },
                        rowStyle: (rowData) => {
                            if (rowData.status){
                                if(rowData.status.states && rowData.status.states.length > 0){
                                    return {background: "rgba(255, 0, 0, 0.13)"}
                                }
                            }else{
                                return {background: "rgba(255, 0, 0, 0.13)"}
                            }
                        }

                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    columns={columns}
                    data={content}
                    title={title && typeof title === 'function' ? title() : title}
                    onRowClick={this.onRowClick}
                />


                {content.length === 0 ? (
                    <div className="no-data">There is no data</div>
                ) : null}
            </div>
        );
    }
}

export default PageableTable;
