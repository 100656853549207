// import React, { Component } from "react";
// import { connect } from "react-redux";
// import PageableTable from "../../../../../../../../components/PageableTable/components";
// import { getUserTransactionsRequest } from "../../../../../../../../actions/admin";
// import {
//   userTransactionsPageContentSelector,
//   userTransactionsPageCountSelector
// } from "../../../../../../../../selectors/admin";
// import "../../styles/Referees.scss";
//
// const columns = [
//   {
//     Header: "Amount",
//     id: "amount",
//     accessor: d => d.amount
//   },
//   {
//     Header: "Date",
//     id: "date",
//     accessor: d => d.date
//   },
//   {
//     Header: "User Wallet",
//     id: "userWallet",
//     accessor: d => d.userWallet
//   },
//   {
//     Header: "Scrooge Wallet",
//     id: "scroogeWallet",
//     accessor: d => d.scroogeWallet
//   },
//   {
//     Header: "Transaction Type",
//     id: "transactionType",
//     accessor: d => d.transactionType
//   }
// ];
//
// class TransactionTab extends Component {
//   fetchDataCallback = params => {
//     const {
//       match: {
//         params: { id }
//       },
//       getUserTransactions
//     } = this.props;
//     getUserTransactions({ userId: id, params: params });
//   };
//
//   render() {
//     const { count, content } = this.props;
//
//     return (
//       <div className="referees">
//         <div className="container">
//           <PageableTable
//             pageable={true}
//             sortEnabled={false}
//             columns={columns}
//             content={content}
//             count={count}
//             fetchDataCallback={this.fetchDataCallback}
//             showPagination={false}
//           />
//         </div>
//       </div>
//     );
//   }
// }
//
// const mapStateToProps = state => ({
//   content: userTransactionsPageContentSelector(state),
//   count: userTransactionsPageCountSelector(state)
// });
//
// const mapDispatchToProps = dispatch => ({
//   getUserTransactions: data => dispatch(getUserTransactionsRequest(data))
// });
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TransactionTab);



import React, { Component } from "react";
import "../../../../../../../admin/UsersPage/styles/users-page.scss";
import "../../../../../../../user/Referees/styles/Referees.scss";
import "../../../../../../../user/TraderDiary/styles/TraderDiary.scss";
import "../../../../../../../user/Payments/styles/Payments.scss";
import UserTransactions from "../../../../../../../user/Payments/components/UserTransactions";
import "./styles/user-transactions-tab.scss"

class TransactionTab extends Component {
  render() {
    const {
      title,
      match: {
        params: {id}
      }
    } = this.props;
    return (
        <div className="user-transactions-tab">
          <UserTransactions userId={id} id="referral-transactions" title="Referral Bonus" style={{margin: '2em 0'}}/>
          <UserTransactions userId={id} id="user-transactions" title="Payments to Scrooge"/>
        </div>
    );
  }
}

export default TransactionTab;
