import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOG_OUT_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOG_OUT_FAILURE,
  AUTH_REFRESH_TOKEN_SUCCESS,
  AUTH_REFRESH_TOKEN_FAILURE
} from "../actions/auth";

const initialState = {
  isLoggedIn: false,
  jwt: null,
  error: null
};

const auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        jwt: payload,
        error: null
      };
    case AUTH_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        jwt: payload,
        error: null
      };
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        error: payload
      };

    case AUTH_REFRESH_TOKEN_FAILURE:
    case AUTH_LOG_OUT_SUCCESS:
    case AUTH_LOG_OUT_FAILURE:
      return initialState;

    default:
      return state;
  }
};

export default auth;
