import React, {Component} from "react";
import {connect} from "react-redux";
import {
    getUserRefereesRequest,
    getUserReferralCodeRequest
} from "../../../../../../../../actions/user";
import {
    userRefereesContentSelector,
    userReferralCodeSelector
} from "../../../../../../../../selectors/user";
import "../../styles/Referees.scss";
import Referees from "../../../../../../../user/Referees/components/Referees";

class RefereeTab extends Component {
    render() {
        const {
            match: {
                params: {id}
            }
        } = this.props;
        return (
            <div className="referees">
                <div className="referees__white-block">
                    <Referees userId={id}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    refCode: userReferralCodeSelector(state),
    content: userRefereesContentSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getUserReferees: userId => dispatch(getUserRefereesRequest(userId)),
    getUserReferralCode: userId => dispatch(getUserReferralCodeRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefereeTab);
