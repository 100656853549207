import { createAction } from "redux-actions";

export const GET_LIVE_BALANCE_REQUEST = "GET_LIVE_BALANCE_REQUEST";
export const GET_LIVE_BALANCE_SUCCESS = "GET_LIVE_BALANCE_SUCCESS";
export const GET_LIVE_BALANCE_FAILURE = "GET_LIVE_BALANCE_FAILURE";

export const GET_CHART_DATA_REQUEST = "GET_CHART_DATA_REQUEST";
export const GET_CHART_DATA_SUCCESS = "GET_CHART_DATA_SUCCESS";
export const GET_CHART_DATA_FAILURE = "GET_CHART_DATA_FAILURE";

export const GET_DEALS_REQUEST = "GET_DEALS_REQUEST";
export const GET_DEALS_SUCCESS = "GET_DEALS_SUCCESS";
export const GET_DEALS_FAILURE = "GET_DEALS_FAILURE";

export const getLiveBalanceRequest = createAction(GET_LIVE_BALANCE_REQUEST);
export const getLiveBalanceSuccess = createAction(GET_LIVE_BALANCE_SUCCESS);
export const getLiveBalanceFailure = createAction(GET_LIVE_BALANCE_FAILURE);

export const getChartDataRequest = createAction(GET_CHART_DATA_REQUEST);
export const getChartDataSuccess = createAction(GET_CHART_DATA_SUCCESS);
export const getChartDataFailure = createAction(GET_CHART_DATA_FAILURE);

export const getDealsRequest = createAction(GET_DEALS_REQUEST);
export const getDealsSuccess = createAction(GET_DEALS_SUCCESS);
export const getDealsFailure = createAction(GET_DEALS_FAILURE);
