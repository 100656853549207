import { createTheme } from '@material-ui/core/styles';

const color = {
  // TODO: Define your color's instance
  grey: {
    main: '#e8ecf1',
    desaturated: '#e0d9ed',
    inner: '#a0a6ab'
  },
  black: '#000',
  increaseGreen: '#23c88c',
  decreaseRed: '#ed5565',
};

const scrooge = {
  plus: {
    color: color.increaseGreen,
    '&:after': {
      content: '"↑"'
    }
  },
  minus: {
    color: color.decreaseRed,
    '&:after': {
      content: '"↓"'
    }
  }
}

const theme = createTheme({
  color,
  scrooge
});

export default theme;
