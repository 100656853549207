import {
  GET_USER_ACTIVITY_REQUEST,
  GET_USER_ACTIVITY_SUCCESS,
  GET_USER_SUCCESS,
  GET_USER_TRADING_SETTINGS_SUCCESS,
  GET_USER_TRANSACTIONS_REQUEST,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_TRADING_SETTINGS_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE
} from "../actions/admin";

const initialState = {
  usersPage: null,
  user: null,
  userTradingSettings: null,
  userLogsPage: null,
  userTransactionsPage: null,
  status: null,
  message: null
};

const admin = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case CREATE_USER_REQUEST:
      return {
        ...state,
        status: null,
        message: null
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        status: payload
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        status: payload.status,
        message: payload.data.message
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        status: null,
        message: null
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        usersPage: payload
      };
    case GET_USER_ACTIVITY_REQUEST:
      return {
        ...state,
        status: null
      };
    case GET_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        userLogsPage: payload
      };
    case GET_USER_TRANSACTIONS_REQUEST:
      return {
        ...state,
        status: null
      };
    case GET_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        userTransactionsPage: payload
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        status: null,
        user: payload
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        status: payload
      };

    case GET_USER_TRADING_SETTINGS_SUCCESS:
      return {
        ...state,
        status: null,
        userTradingSettings: payload
      };
    case UPDATE_USER_TRADING_SETTINGS_SUCCESS:
      return {
        ...state,
        status: payload
      };

    default:
      return state;
  }
};

export default admin;
