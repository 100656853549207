
export const userBalanceSelector = state =>
  state.userStatistic.liveBalance || {};

export const userChartDataSelector = state =>
  state.userStatistic.chartData || {};



export const userDealsSelector = state => state.userStatistic.deals || {};
