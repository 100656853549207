import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import MaterialTable from "@material-table/core";
import {Paper} from "@material-ui/core";
import {
    lastBalanceSelector,
    openDealsSelector, openDealsStatsSelector,
    userDealsSelector
} from "../../../../../../../../selectors/user";
import {getUserDealsRequest, getUserOpenDealsRequest} from "../../../../../../../../actions/user";
import {getLiveBalanceRequest} from "../../../../../../../../actions/userStatistics";
import {userLogsColumns} from "../../../../../../../user/TraderDiary/components/TraderTable";
import "../../../../../../../user/Payments/components/UserTransactions/styles/user-transactions.scss";
import moment from "moment";
import {
    getUserLogs,
} from "../../../../../../../../api/user";
import "./styles/logs-tab.scss"

class LogsTab extends Component {

    constructor() {
        super()
    }

    render() {
        const {
            title,
            match: {
                params: {id}
            }
        } = this.props;

        return (
            <div className="trader-diary logs-tab">
                <div className="page-content">
                    <div className="white-table">
                        <MaterialTable
                            options={{
                                emptyRowsWhenPaging: false,
                                sorting: true,
                                toolbar: true,
                                search: false,
                                headerStyle: {
                                    backgroundColor: 'rgb(241, 244, 247)',
                                    letterSpacing: "1px",
                                    color: "#000000",
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    // backgroundColor: "#f1f4f7",
                                    textTransform: "capitalize",
                                    // border: "none",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    // width: '100%',
                                    paddingLeft: "0",
                                    paddingRight: "0",
                                },
                                actionsCellStyle: {
                                    // color: 'red',
                                    // height: '200px'
                                    // backgroundColor: "white",
                                },
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50],
                            }}
                            components={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            columns={userLogsColumns}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    let uri = `size=${query.pageSize}&page=${query.page}`
                                    if (query.orderBy) {
                                        uri += `&sort=${query.orderBy.field},${query.orderDirection}`
                                    } else {
                                        uri += `&sort=closeDate,desc`
                                    }
                                    getUserLogs(id, uri)
                                        .then(result => {
                                            resolve({
                                                data: result.data.content
                                                    ? result.data.content.map(t => ({
                                                        id: t.id,
                                                        userId: t.userId,
                                                        date: t.date,
                                                        type: t.type,
                                                        mess: t.mess,
                                                        count: t.count
                                                    }))
                                                    : [],
                                                page: result.data.pageable.pageNumber,
                                                totalCount: result.data.totalElements,
                                            })
                                        })
                                })}
                            title={"Trading System Logs"}
                            detailPanel={({rowData}) => {
                                return (
                                    <div style={{
                                        margin: "20px",
                                        textOverflow: "ellipsis",

                                        /* Required for text-overflow to do anything */
                                        whiteSpace: "wrap",
                                        // overflow: "hidden",
                                        // maxWidth: "1300px"
                                    }}>{rowData.mess}</div>
                                );
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        />
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    trades: userDealsSelector(state),
    // userId: userIdSelector(state),
    lastBalance: lastBalanceSelector(state),
    openDeals: openDealsSelector(state),
    openDealsStats: openDealsStatsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getDeals: userId => dispatch(getUserDealsRequest(userId)),
    getUsersOpenDeals: userId => dispatch(getUserOpenDealsRequest(userId)),
    getBalances: userId => dispatch(getLiveBalanceRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogsTab);
