import numeral from "numeral";

export const roundCurrencyPrice = (v = 0, baseCurrency, r, isString) => {
    if (r) {
        if (isString) {
            return Number.parseFloat(v).toFixed(r || 2)
        } else {
            return parseFloat(Number.parseFloat(v).toFixed(r || 2))
        }
    } else {
        let round = 2
        switch (baseCurrency) {
            case 'USDT':
                round = 2
                break;
            case 'BUSD':
                round = 2
                break;
            case 'ETH':
                round = 6
                break;
            case 'BTC':
                round = 6
                break;
            default:
                round = 6
        }
        if (isString) {
            return Number.parseFloat(v).toFixed(round)
        } else {
            return parseFloat(Number.parseFloat(v).toFixed(round))
        }
    }
}

export const roundCurrencyVolume = (v = 0, baseCurrency, r, isString) => {
    if (r) {
        if (isString) {
            return Number.parseFloat(v).toFixed(r || 2)
        } else {
            return parseFloat(Number.parseFloat(v).toFixed(r || 2))
        }
    } else {
        let round = 5
        // switch (baseCurrency) {
        //     case 'USDT':
        //         round = 8
        //         break;
        //     case 'BUSD':
        //         round = 8
        //         break;
        //     case 'ETH':
        //         round = 8
        //         break;
        //     case 'BTC':
        //         round = 8
        //         break;
        //     default:
        //         round = 8
        // }
        if (isString) {
            return Number.parseFloat(v).toFixed(round)
        } else {
            return parseFloat(Number.parseFloat(v).toFixed(round))
        }
    }
}

export const perc2color = (perc) => {
    let r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}

export const toNumeralFormat = (n, base = "USDT", format) => {
    return numeral(roundCurrencyPrice(n, base)).format(format ? format : base === "USDT" || base === "BUSD" ? '0,0.00' : '0,0.000000');
}
