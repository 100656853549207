import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import {
    lastBalanceSelector,
    openDealsSelector, openDealsStatsSelector,
    userDealsSelector
} from "../../../../selectors/user";
import {userIdSelector} from "../../../../selectors/user";
import {getUserDealsRequest, getUserOpenDealsRequest} from "../../../../actions/user";
import {getLiveBalanceRequest} from "../../../../actions/userStatistics";
import "../styles/new-order.scss"
import BuyOrder from "./BuyOrder";
import SellOrder from "./SellOrder";
import Spinner from "../../../../components/Spinner";


class NewOrder extends Component {

    state = {
        orderType: "limit",
        symbolLimits: {}
    };

    constructor(props) {
        super(props)

    }

    componentDidMount() {
        const {openDeal} = this.props
        fetch(`https://api.binance.com/api/v3/exchangeInfo?symbol=${openDeal.alt + openDeal.base}`)
            .then(res => res.json())
            .then(
                (result) => {
                    const symbolData = result.symbols.filter(s => s.status === "TRADING")[0] || {};
                    const data = {};
                    (symbolData.filters || []).forEach(f => {
                        if (f.filterType === "PRICE_FILTER") {
                            data.maxPrice = parseFloat(f.maxPrice)
                            data.minPrice = parseFloat(f.minPrice)
                            data.tickSize = parseFloat(f.tickSize)
                        }
                        if (f.filterType === "LOT_SIZE") {
                            data.maxQty = parseFloat(f.maxQty)
                            data.minQty = parseFloat(f.minQty)
                            data.stepSize = parseFloat(f.stepSize)
                        }
                        if (f.filterType === "MIN_NOTIONAL") {
                            data.minNotional = parseFloat(f.minNotional)
                        }
                        if (f.filterType === "MARKET_LOT_SIZE") {
                            const market = {
                                maxQty: parseFloat(f.maxQty),
                                minQty: parseFloat(f.minQty),
                                stepSize: parseFloat(f.stepSize)
                            }
                            data.market = market
                        }
                    })
                    try {
                        const ba = data.stepSize.toString().split(".")
                        const qa = data.tickSize.toString().split(".")
                        data.baseAssetPrecision = ba.length > 1 ? (ba[1].indexOf("1") || 0) + 1 : 0
                        data.quoteAssetPrecision = qa.length > 1 ? (qa[1].indexOf("1") || 0) + 1 : 0
                    } catch (err) {
                        data.baseAssetPrecision = symbolData.baseAssetPrecision
                        data.quoteAssetPrecision = symbolData.quoteAssetPrecision
                    }

                    this.setState({
                        isLoaded: true,
                        symbolData: symbolData,
                        symbolLimits: data
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const {
            openDeal,
            availableBase
        } = this.props;
        const {orderType, symbolLimits, isLoaded} = this.state
        const availableAlt = openDeal.volume
        return (
            <div className="new-order">
                <Spinner isLoading={!isLoaded}/>
                <div className="new-order__types">
                    <div className={`new-order__type-item ${orderType === 'limit' ? "active-item" : ""}`}
                         onClick={() => this.setState({orderType: "limit"})}>Limit
                    </div>
                    <div className={`new-order__type-item ${orderType === 'market' ? "active-item" : ""}`}
                         onClick={() => this.setState({orderType: "market"})}>Market
                    </div>
                </div>
                <div className="new-order__sides">
                    <BuyOrder orderType={orderType} openDeal={openDeal}
                              availableBase={availableBase}
                              availableAlt={availableAlt}
                              symbolLimits={symbolLimits}/>
                    <SellOrder orderType={orderType} openDeal={openDeal}
                               availableBase={availableBase}
                               availableAlt={availableAlt}
                               symbolLimits={symbolLimits}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    trades: userDealsSelector(state),
    userId: ownProps.userId || userIdSelector(state),
    lastBalance: lastBalanceSelector(state),
    openDeals: openDealsSelector(state),
    openDealsStats: openDealsStatsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getDeals: userId => dispatch(getUserDealsRequest(userId)),
    getUsersOpenDeals: userId => dispatch(getUserOpenDealsRequest(userId)),
    getBalances: userId => dispatch(getLiveBalanceRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewOrder);
