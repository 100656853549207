import clientAPI from "./";

export const register = data => {
  return clientAPI.post(`/register`, data);
};

export const login = data => {
  return clientAPI.post(`/login`, data);
};

export const logout = refreshToken => {
  return clientAPI.post(`/logout`, refreshToken);
};

export const refreshToken = refreshToken => {
  return clientAPI.post(`/refresh-token`, refreshToken);
};
