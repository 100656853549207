import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {userBaseSelector, userDealsSelector, userIdSelector} from "../../../../selectors/user";
import HomeChart from "./HomeChart";
import "../../../admin/UsersPage/styles/users-page.scss";
import "../styles/Home.scss";
import {getLiveBalanceRequest} from "../../../../actions/userStatistics";
import {userBalanceSelector} from "../../../../selectors/userStatistic";
import {TraderSummary} from "../../TraderDiary/components/TradeSummary";
import {getUserDealsRequest} from "../../../../actions/user";
import Spinner from "../../../../components/Spinner";

class Home extends Component {
    state = {
        isLoading: true
    }

    componentDidMount() {
        const {getBalance, getDeals, userId} = this.props;
        getBalance(userId);
        getDeals(userId);
        setTimeout(()=> {
            this.setState({isLoading: false})
        }, 2500)
    }

    render() {
        const {user, lastBalances, userDeals = {}} = this.props;
        const {balances = []} = lastBalances || {};
        // console.log('balances ===>', balances)
        // return <></>
        const usd = balances.filter(el => el.name === "USDT").map(el => parseFloat(el.value))[0];
        const btc = balances.filter(el => el.name === "BTC").map(el => parseFloat(el.value))[0];
        const eth = balances.filter(el => el.name === "ETH").map(el => parseFloat(el.value))[0];
        return (
            <div className="users-page user-referees home">
                <div className="page-content">
                    {/*<div className="white-container margin-container">*/}
                    {/*    <div className="title margin-none">Main Coins</div>*/}
                    {/*    <Stats/>*/}
                    {/*</div>*/}

                    <div className="white-container margin-container">
                        <div className="chart-container">
                            {/*<div className="portfolio">*/}
                            {/*  <p>Portfolio value</p>*/}
                            {/*  <span className="portfolio-main">*/}
                            {/*    ${usd.length !== 0 ? parseFloat(usd).toFixed(2) : 0}*/}
                            {/*  </span>*/}
                            {/*  <span className="portfolio-regular">*/}
                            {/*    {btc.length !== 0 ? parseFloat(btc).toFixed(5) : 0} BTC*/}
                            {/*  </span>*/}
                            {/*  <span className="portfolio-regular">*/}
                            {/*    {eth.length !== 0 ? parseFloat(eth).toFixed(5) : 0} ETH*/}
                            {/*  </span>*/}
                            {/*</div>*/}
                            <div className="title margin-none">Account Summary</div>
                            <TraderSummary
                                usdtValue={usd}
                                deals={userDeals.deals}
                                profit={userDeals.profit}
                                avgDealProfit={userDeals.avgDealProfit}
                                // profitPercent={}
                                btcValue={btc}
                                ethValue={eth}
                                baseCurrency={userDeals.base}
                                isLoading={this.state.isLoading}
                            />
                            <HomeChart userId={user} alignZoomButton={"left"} base={userDeals.base}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    userId: ownProps.userId || userIdSelector(state),
    lastBalances: userBalanceSelector(state),
    userDeals: userDealsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getBalance: userId => dispatch(getLiveBalanceRequest(userId)),
    getDeals: userId => dispatch(getUserDealsRequest(userId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

Home.propTypes = {
    user: PropTypes.string
};
Home.defaultProps = {
    user: ""
};
