import * as Yup from "yup";

const getNumericPattern = (end = 8) => {
    const start = 1;
    const p = new RegExp(`^\\d+(\\.\\d{${Array(end - start + 1).fill().map((_, idx) => start + idx).join(",")}})?$`)
    return p
}

export const UserTradingSettingsSchema = Yup.object().shape({
    baseTradeConfig: Yup.object({
        belowAverage: Yup.number()
            .typeError("Field must be a number")
            .positive('Please provide positive number')
            .test(
                "maxDigitsAfterDecimal",
                "Field must have 2 digits after decimal or less",
                (number) => getNumericPattern(2).test(number)
            )
            .required('Required'),
        emaPeriod: Yup.number()
            .typeError("Field must be a number")
            .positive('Please provide positive number')
            .integer('Please provide integer')
            .required('Required'),
        fee: Yup.number()
            .typeError("Field must be a number")
            .positive('Please provide positive number')
            .test(
                "maxDigitsAfterDecimal",
                "Field must have 2 digits after decimal or less",
                (number) => getNumericPattern(2).test(number)
            )
            .required('Required'),
        profit: Yup.number()
            .typeError("Field must be a number")
            .positive('Please provide positive number')
            .test(
                "maxDigitsAfterDecimal",
                "Field must have 2 digits after decimal or less",
                (number) => getNumericPattern(2).test(number)
            )
            .required('Required'),
        tradeMultiply: Yup.number()
            .typeError("Field must be a number")
            .positive('Please provide positive number')
            .integer('Please provide integer')
            .required("Required"),
        candleStickIntervalId: Yup.string().required("Required"),
        dynamicProfit: Yup.bool().required("Required"),
        fixedProfit: Yup.bool().required("Required"),
        stopLossProfit: Yup.bool().required("Required"),
  })
    .default(null)
    .nullable(),
  tradingPairs: Yup.array().of(
    Yup.object().shape({
      alt: Yup.string().required("Required"),
      base: Yup.string().required("Required")
    })
  )
});


export const NewOrderSchema = Yup.object().shape({
    baseCurrencyUp: Yup.number().required("Required"),


    baseTradeConfig: Yup.object({
        baseCurrencyUp: Yup.number().required("Required")
    })
        .default(null)
        .nullable(),
    tradingPairs: Yup.array().of(
        Yup.object().shape({
            alt: Yup.string().required("Required"),
            base: Yup.string().required("Required")
        })
    )
});
