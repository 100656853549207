import React from "react";
import { Formik } from "formik";
import { LoginValidationScheme } from "../validators/login-form";
import "../styles/login-form.scss";
import { NavLink } from "react-router-dom";
import FormikInput from "../../../../../../../components/FormikInput";
import "../styles/login-form.scss";

export const ResetPasswordForm = ({ isFetching, onSubmitCallback, error }) => (
  <div className="login-form">
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={LoginValidationScheme}
      onSubmit={onSubmitCallback}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormikInput
            label="New Password"
            name="password"
            type="password"
            className="formik-input"
            placeholder="Enter your new password"
          />
          <FormikInput
            label="Confirm Password"
            name="password"
            type="password"
            placeholder="Confirm your new password"
          />
          <div className="forgot-password"></div>
          <button type="submit" disabled={isFetching} className="button">
            Reset Password
          </button>
          <NavLink
            to="/login"
            type="cancel"
            disabled={isFetching}
            className="sign-up"
          >
            Cancel
          </NavLink>
          {error && <span className="error-message">Bad credentials</span>}
        </form>
      )}
    </Formik>
  </div>
);
