export const getEthDif = (balances, lastBalance) => {
  let diff = 0;
  balances.forEach(balance => {
    if (balance.name === "ETH") {
      diff = balance.value - lastBalance;
    }
  });
  return diff;
};

export const getExchangeRates = balances => {
  const exchangeRates = {};
  balances.forEach(fromBalance => {
    balances.forEach(toBalance => {
      if (fromBalance.name !== toBalance.name) {
        exchangeRates[fromBalance.name + toBalance.name] =
          toBalance.value / fromBalance.value;
      }
    });
  });
  return exchangeRates;
};

export const getCalculatedBalances = (balances, lastBalance) => {
  const diff = getEthDif(balances, lastBalance);
  const exchangeRates = getExchangeRates(balances);
  return balances.map(balance => {
    balance.diff = diff;
    if (balance.name !== "ETH") {
      balance.diff = exchangeRates["ETH" + balance.name] * diff;
    }
    return balance;
  });
};

export const getUserDeals = (deals, trades) => {
  let profit = 0;
  deals.sort((a, b) => a.date - b.date);
  trades.sort((a, b) => a.date - b.date);
  const includedTradeIds = [];
  const tradesMap = new Map();
  let base = "USDT"
  deals.map(deal => {
    profit += deal.profit;
    const filteredTrades = [];
    if (base !== deal.base){
      base = deal.base
    }
    trades.map(trade => {
      if (deal.pair === trade.pair && deal.date >= trade.date) {
        if (includedTradeIds.indexOf(trade.id) < 0) {
          includedTradeIds.push(trade.id);
          filteredTrades.push(trade);
        }
      }
    });
    tradesMap.set(deal.id, filteredTrades);
  });
  const firstBalance = parseFloat(localStorage.getItem("firstBalance"));
  return {
    tradesMap,
    deals,
    base,
    profit,
    avgDealProfit: parseFloat(profit / deals.length),
    loading: false,
    profitPercent: ((profit / firstBalance) * 100).toFixed(5)
  };
};
