import React, {Component} from "react";
import "../../../App.scss";
import {connect} from "react-redux";
import {isFetchingSelector} from "../../../selectors/app";
import {Route, Switch} from "react-router";
import LoginPage from "./LoginPage";
import PrivateRoute from "../../../components/PrivateRoute";
import {MainContent} from "./MainContent";
import ResetPasswordPage from "./ResetPasswordPage";
import {ToastContainer, toast} from "react-toastify";
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
window.document.body.style.zoom = "75%";
class MainPage extends Component {

    render() {

        return (
            <>
                <Switch>
                    <Route path="/login" exact component={LoginPage}/>
                    <Route path="/reset-password" exact component={ResetPasswordPage}/>
                    <PrivateRoute path="/" component={MainContent}/>
                </Switch>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    transition={Bounce}
                    theme={"colored"}
                    hideProgressBar={false}
                    newestOnTop
                    // closeOnClick
                    rtl={false}
                    className={"toast-container"}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    isFetching: isFetchingSelector(state)
});

export default connect(mapStateToProps)(MainPage);
