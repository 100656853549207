import { authRefreshTokenRequest } from "../actions/auth";

// this middleware is supposted to refresh jwt when it has expired
// before each request to Back-End
// Each action that perform request to Back End should consist 'REQUEST' word
// and vice versa
export default function createRequestMiddleware() {
  return ({ dispatch, getState }) => next => action => {
    // console.log("createRequestMiddleware", action.type, action.payload);
    if (action.type && !action.type.includes("REQUEST")) {
      return next(action);
    }

    //gets here only when action type contains 'REQUEST'
    if (getState().auth.jwt) {
      const now = new Date().valueOf() / 1000;
      // 1 minutes before ending date of token
      const refreshThreshold = getState().auth.jwt.expiresIn - 60;

      while (now > refreshThreshold && getState().app.isFetching) {
        //TODO wait when previous request to be done
      }

      if (now > refreshThreshold) {
        dispatch(
          authRefreshTokenRequest({
            refreshToken: getState().auth.jwt.refreshToken,
            retryAction: action
          })
        );
        return;
      }
    }
    return next(action);
  };
}
