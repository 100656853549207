import { createAction } from "redux-actions";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const GET_USER_TRADING_SETTINGS_REQUEST =
  "GET_USER_TRADING_SETTINGS_REQUEST";
export const GET_USER_TRADING_SETTINGS_SUCCESS =
  "GET_USER_TRADING_SETTINGS_SUCCESS";
export const GET_USER_TRADING_SETTINGS_FAILURE =
  "GET_USER_TRADING_SETTINGS_FAILURE";

export const UPDATE_USER_TRADING_SETTINGS_REQUEST =
  "UPDATE_USER_TRADING_SETTINGS_REQUEST";
export const UPDATE_USER_TRADING_SETTINGS_SUCCESS =
  "UPDATE_USER_TRADING_SETTINGS_SUCCESS";
export const UPDATE_USER_TRADING_SETTINGS_FAILURE =
  "UPDATE_USER_TRADING_SETTINGS_FAILURE";

export const GET_USER_ACTIVITY_REQUEST = "GET_USER_ACTIVITY_REQUEST";
export const GET_USER_ACTIVITY_SUCCESS = "GET_USER_ACTIVITY_SUCCESS";
export const GET_USER_ACTIVITY_FAILURE = "GET_USER_ACTIVITY_FAILURE";

export const GET_USER_TRANSACTIONS_REQUEST = "GET_USER_TRANSACTIONS_REQUEST";
export const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAILURE = "GET_USER_TRANSACTIONS_FAILURE";

export const getUsersRequest = createAction(GET_USERS_REQUEST);
export const getUsersSuccess = createAction(GET_USERS_SUCCESS);
export const getUsersFailure = createAction(GET_USERS_FAILURE);

export const createUserRequest = createAction(CREATE_USER_REQUEST);
export const createUserSuccess = createAction(CREATE_USER_SUCCESS);
export const createUserFailure = createAction(CREATE_USER_FAILURE);

export const getUserRequest = createAction(GET_USER_REQUEST);
export const getUserSuccess = createAction(GET_USER_SUCCESS);
export const getUserFailure = createAction(GET_USER_FAILURE);

export const updateUserRequest = createAction(UPDATE_USER_REQUEST);
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
export const updateUserFailure = createAction(UPDATE_USER_FAILURE);

export const getUserTradingSettingsRequest = createAction(
  GET_USER_TRADING_SETTINGS_REQUEST
);
export const getUserTradingSettingsSuccess = createAction(
  GET_USER_TRADING_SETTINGS_SUCCESS
);
export const getUserTradingSettingsFailure = createAction(
  GET_USER_TRADING_SETTINGS_FAILURE
);

export const updateUserTradingSettingsRequest = createAction(
  UPDATE_USER_TRADING_SETTINGS_REQUEST
);
export const updateUserTradingSettingsSuccess = createAction(
  UPDATE_USER_TRADING_SETTINGS_SUCCESS
);
export const updateUserTradingSettingsFailure = createAction(
  UPDATE_USER_TRADING_SETTINGS_FAILURE
);

export const getUserActivityRequest = createAction(GET_USER_ACTIVITY_REQUEST);
export const getUserActivitySuccess = createAction(GET_USER_ACTIVITY_SUCCESS);
export const getUserActivityFailure = createAction(GET_USER_ACTIVITY_FAILURE);

export const getUserTransactionsRequest = createAction(
  GET_USER_TRANSACTIONS_REQUEST
);
export const getUserTransactionsSuccess = createAction(
  GET_USER_TRANSACTIONS_SUCCESS
);
export const getUserTransactionsFailure = createAction(
  GET_USER_TRANSACTIONS_FAILURE
);
