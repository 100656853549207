import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { getUserBalancesRequest } from "../../../../actions/user";
import { userIdSelector } from "../../../../selectors/user";
import { Options } from "../../../../helper/statistics/Options";
import { userBalancesSelector } from "../../../../selectors/user";
import "../../Home/styles/Home.scss";

const styles = () => ({
  root: {},
  message: {
    position: "relative",
    textAlign: "center",
    bottom: "5em"
  },
  invisible: {
    display: "none"
  }
});

class HomeChart extends Component {
  componentDidMount() {
    const { getChartData, userId } = this.props;
    getChartData(userId);
  }

  render() {
    const { classes, chartData, alignZoomButton, base} = this.props;
    return (
      <div style={{zoom: "133.5%"}}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={Options(chartData || [], alignZoomButton, base)}
        />
        <div
          className={
            chartData.length === 0 ? classes.message : classes.invisible
          }
        >
          There is no data yet
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getChartData: userId => dispatch(getUserBalancesRequest(userId))
});

const mapStateToProps = state => ({
  chartData: userBalancesSelector(state),
  userId: userIdSelector(state)
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HomeChart);

HomeChart.propTypes = {
  classes: PropTypes.object.isRequired,
  chartData: PropTypes.array,
  getChartData: PropTypes.func
};

HomeChart.defaultProps = {
  classes: {},
  chartData: [],
  getChartData: null
};
