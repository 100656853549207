import React from "react";
import { Field } from "formik";
import TextField from "../TextField";


export const FormikTextField = ({
  children,
  render,
  margin,
  placeholder,
  label,
  name,
  fullWidth,
  type,
  ...props
}) => {
  return (
    <Field
      margin={margin || "normal"}
      placeholder={placeholder || `Type your ${label.toLowerCase()}...`}
      label={label}
      name={name || label.toLowerCase()}
      fullWidth={fullWidth || true}
      type={type || "text"}
      {...props}
      component={TextField}
    >
      {children}
    </Field>
  );
};
