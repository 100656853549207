import {combineReducers} from "redux";
import auth from "./auth";
import app from "./app";
import user from "./user";
import admin from "./admin";
import binance from "./binance";
import userStatistic from "./userStatistic";
import ui from "./ui";

const rootReducer =
    combineReducers({
        auth,
        app,
        user,
        admin,
        binance,
        userStatistic,
        ui
    });

export default rootReducer;
