import React from "react";
import "../styles/TraderDiary.scss"
import {NavLink} from "react-router-dom";
import "../../../../pages/MainPage/components/Header/components/Stats/styles/Stats.scss";
import {roundCurrencyPrice} from "../../../../utils/calculations";
import Spinner from "../../../../components/Spinner";

export const TraderSummary = ({
                                  profit,
                                  usdtValue,
                                  btcValue,
                                  ethValue,
                                  deals,
                                  avgDealProfit,
                                  profitPercent,
                                  baseCurrency,
                                  isLoading
                              }) => (
    isLoading ? <Spinner className={"trader-summary__spinner"} isLoading={true}/> : <div className="trader-summary">
        <div className="trader-summary__block">
            <div className="trader-summary__block__title">Base Currency</div>
            <div className="trader-summary__block__data green">
                {baseCurrency}
            </div>
        </div>

        <NavLink to="/user/trader-diary" className="navlink">
            <div className="trader-summary__block">
                <div className="trader-summary__block__title">Total Profit</div>
                <div className="trader-summary__block__data green">
                    +{roundCurrencyPrice(profit, baseCurrency)}
                </div>
            </div>
        </NavLink>

        <div className="trader-summary__block">
            <div className="trader-summary__block__title">Portfolio USDT</div>
            <div className="trader-summary__block__data">
                {roundCurrencyPrice(usdtValue, 'USDT')}
            </div>
        </div>
        <div className="trader-summary__block">
            <div className="trader-summary__block__title">Portfolio BTC</div>
            <div className="trader-summary__block__data">
                ≈ {roundCurrencyPrice(btcValue, 'BTC')}
            </div>
        </div>
        <div className="trader-summary__block">
            <div className="trader-summary__block__title">Portfolio ETH</div>
            <div className="trader-summary__block__data">
                ≈ {roundCurrencyPrice(ethValue, 'ETH')}
            </div>
        </div>

        <NavLink to="/user/trader-diary" className="navlink">
            <div className="trader-summary__block">
                <div className="trader-summary__block__title">Number of deals</div>
                <div className="trader-summary__block__data">
                    {deals ? `${deals.length}` : "0"}
                </div>
            </div>
        </NavLink>

    </div>
);

export const MediaData = ({
                              profit,
                              deals,
                              avgDealProfit,
                              profitPercent,
                              baseCurrency
                          }) => (
    <div className="media-container">
        <div className="gray-block">
            Total profit
            <br/>
            by closed deals
            <div className="white-block">
                {profit ? profit.toFixed(5) : 0} {baseCurrency}
                <br/> (
                {!isNaN(profitPercent) ? parseFloat(profitPercent).toFixed(2) : 0} %)
            </div>
        </div>
        <div className="gray-block">
            Number
            <br/> of deals
            <div className="white-block padding-block">
                {deals ? `${deals.length}` : "0"}
            </div>
        </div>
        <div className="gray-block">
            Avg profit
            <br/> by deal
            <div className="white-block">
                {avgDealProfit ? `${avgDealProfit.toFixed(5)}` : "0"}
                <br/>
                {baseCurrency}
            </div>
        </div>
    </div>
);
