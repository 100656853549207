import React, {Component} from "react";
import {connect} from "react-redux";
import {Formik} from "formik";
import {FormikTextField} from "../../../../../../../../components/formik/FormikTextField";

import {UserSettingsSchema} from "./validators/user-settings";
import {isFetchingSelector} from "../../../../../../../../selectors/app";
import {userSelector} from "../../../../../../../../selectors/admin";
import {
    getUserRequest,
    updateUserRequest
} from "../../../../../../../../actions/admin";
import {toast} from "react-toastify";
import FormikSelectField from "../../../../../../../../components/formik/FormikSelectField";
import "./styles/user-settings-tab.scss";


const options = [
    {label: "ACTIVE", value: "ACTIVE"},
    {label: "DELETED", value: "DELETED"},
    {label: "BLOCKED", value: "BLOCKED"}
]

class UserSettings extends Component {
    componentDidMount() {
        const {
            match: {
                params: {id}
            },
            getUser
        } = this.props;
        getUser(id);
    }

    onUserSubmit = values => {
        const {
            match: {
                params: {id}
            },
            updateUser
        } = this.props;
        // updateUser({ userId: id, user: values });
        toast(`Settings were successfully updated`, {
            className: "toast"
        });
    };

    render() {
        const {isFetching, user, status} = this.props;

        return (
            <div className="user-settings-tab">
                <div className="white-block">
                    <Formik
                        enableReinitialize={true}
                        initialValues={{...user}}
                        validationSchema={UserSettingsSchema}
                        onSubmit={this.onUserSubmit}
                    >
                        {({handleSubmit, values, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-item flex-end">
                                    <button className="submit-btn">Update</button>
                                </div>
                                <div className="flex-container">
                                    <div className="flex-item">
                                        <FormikTextField
                                            label="Email"
                                            type="email"
                                            className="user-basic-field"
                                            autoComplete="email"
                                            disabled={true}
                                        />
                                        <FormikSelectField
                                            label="Account status"
                                            name="status"
                                            className="user-basic-field"
                                            options={options}
                                            value={values.status}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    `status`,
                                                    e.target.value
                                                );
                                            }}
                                        />

                                        <FormikTextField
                                            label="Profit Fee Percent"
                                            name="profitFeePercent"
                                            disabled={true}
                                            className="user-basic-field"
                                        />
                                        <FormikTextField
                                            label="Referrer ID"
                                            name="referrerId"
                                            disabled={true}
                                            className="user-basic-field"
                                        />
                                    </div>


                                </div>
                                <div>
                                    <p className="title-user">Telegram Info:</p>
                                    <div className="flex-container">
                                        <div className="flex-item">
                                            <FormikTextField
                                                label="Telegram ID"
                                                name="telegramId"
                                            />
                                            <div className="flex-container">
                                                <FormikTextField
                                                    label="Chat ID"
                                                    name="telegramBotInfo.chatId"
                                                />
                                                <FormikTextField
                                                    label="Name"
                                                    name="telegramBotInfo.name"
                                                />
                                            </div>
                                            <FormikTextField
                                                label="Token"
                                                name="telegramBotInfo.token"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <FormikTextField
                                        label="Api Secret"
                                        name="apiSecret"
                                        disabled={true}
                                    />
                                    <FormikTextField
                                        label="API Key"
                                        name="apiKey"
                                        disabled={true}
                                    />
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: userSelector(state),
    isFetching: isFetchingSelector(state),
    status: state.admin.status
});

const mapDispatchToProps = dispatch => ({
    getUser: userId => dispatch(getUserRequest(userId)),
    updateUser: data => dispatch(updateUserRequest(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSettings);
