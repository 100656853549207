import {
  GET_LIVE_BALANCE_REQUEST,
  GET_LIVE_BALANCE_SUCCESS,
  GET_LIVE_BALANCE_FAILURE,
  GET_CHART_DATA_REQUEST,
  GET_CHART_DATA_SUCCESS,
  GET_CHART_DATA_FAILURE,
  GET_DEALS_REQUEST,
  GET_DEALS_SUCCESS,
  GET_DEALS_FAILURE
} from "../actions/userStatistics";

const initialState = {
  loading: null,
  liveBalance: {
    balances: []
  },
  chartData: null,
  homeChartData: null,
  deals: null,
  error: null
};

const userStatistic = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_LIVE_BALANCE_REQUEST:
      return {
        ...state,
        liveBalance: {},
        loading: true
      };
    case GET_LIVE_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        liveBalance: payload
      };
    case GET_LIVE_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case GET_CHART_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        chartData: payload
      };
    case GET_CHART_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case GET_DEALS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_DEALS_SUCCESS:
      return {
        ...state,
        loading: false,
        deals: payload
      };
    case GET_DEALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    default:
      return state;
  }
};

export default userStatistic;
