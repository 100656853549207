import { ErrorMessage, Field } from "formik";
import React from "react";
import "../styles/formik-input.scss";

const FormikInput = ({ label, name, placeholder, type, disabled }) => {
  return (
    <div className="input-box">
      <label>{label}</label>
      <Field
        placeholder={placeholder}
        name={name}
        type={type}
        disabled={disabled}
      />
      <div className="error">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default FormikInput;
