import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { authLogoutRequest } from "../../../../../../actions/auth";
import { userRolesSelector } from "../../../../../../selectors/user";
import { jwtSelector } from "../../../../../../selectors/auth";
import "./styles/ProfileMenu.scss";
import { NavLink } from "react-router-dom";

class ProfileMenu extends Component {
  state = {
    anchorEl: false
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = node => (this.wrapperRef = node);

  handleClick = () => {
    const { anchorEl } = this.state;
    this.setState({ anchorEl: !anchorEl });
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ anchorEl: false });
    }
  };

  logoutHandler = () => {
    window.localStorage.clear();
    const { logout, jwt } = this.props;
    logout(jwt.refreshToken);
  };
  render() {
    const { anchorEl } = this.state;
    const { userRoles } = this.props;
    return (
      <div className="profile-menu" ref={this.setWrapperRef}>
        <button className="logout-menu" onClick={this.handleClick}></button>
        <div className={anchorEl ? "select-menu" : "invisible"}>
          {userRoles.includes("ROLE_CLIENT") ? (
            <NavLink
              className="menu-item account"
              to="/user/profile"
              onClick={this.handleClick}
            >
              Profile
            </NavLink>
          ) : null}
          <button className="menu-item logout" onClick={this.logoutHandler}>
            Logout
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: refreshToken => {
      dispatch(authLogoutRequest(refreshToken));
    }
  };
};

const mapStateToProps = state => ({
  jwt: jwtSelector(state),
  userRoles: userRolesSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileMenu);

ProfileMenu.propTypes = {
  logout: PropTypes.func,
  jwt: PropTypes.object
};
ProfileMenu.defaultProps = {
  logout: null,
  jwt: {}
};
