import clientAPI from "./";

export const getUsers = params => {
  return clientAPI.get(`/admin/users/statistic`, {
    params: { ...params }
  });
};

export const createUser = data => {
  return clientAPI.post(`/admin/users`, data);
};

export const getUser = userId => {
  return clientAPI.get(`/admin/users/${userId}`);
};

export const updateUser = (userId, data) => {
  return clientAPI.put(`/admin/users/${userId}`, data);
};

export const getUserTradingSettings = userId => {
  return clientAPI.get(`/admin/users/${userId}/trading-setting`);
};

export const updateUserTradingSettings = (userId, data) => {
  return clientAPI.put(`/admin/users/${userId}/trading-setting`, data);
};

export const getUserActivity = (userId, query) => {
  return clientAPI.get(`/admin/users/${userId}/activity?${query}`)
};

export const getUserTransactions = (userId, params) => {
  return clientAPI.get(`/admin/users/${userId}/transactions`, {
    params: { ...params }
  });
};
