import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "@material-table/core";
import { isFetchingSelector } from "../../../../../../../../../../selectors/app";
import { getDealsRequest } from "../../../../../../../../../../actions/userStatistics";
import { userDealsSelector } from "../../../../../../../../../../selectors/userStatistic";
import "../../../../../../../../../user/TraderDiary/styles/TraderDiary.scss";

const styles = () => ({
  total: {
    width: "100%",
    height: "60px",
    backgroundColor: "white",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    marginBottom: "2rem",
    display: "flex",
    alignItems: "center"
  },

  closedDeals: {
    marginLeft: "1.5rem",
    fontWeight: "600"
  },

  profit: {
    color: "#23c88c",
    marginLeft: "5px",
    fontWeight: "600"
  }
});

const getDate = milisec => {
  return new Date(milisec).toLocaleString();
};

class SimpleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trades: [],
      loading: true
    };
  }

  componentDidMount() {
    const { getDeals, userId } = this.props;
    getDeals(userId);
  }

  render() {
    const {
      classes,
      userDeals: { deals, profit, profitPercent, avgDealProfit, tradesMap },
      loading
    } = this.props;
    return (
      <div className="">
        <div className={classes.total}>
          <div
            className={classes.closedDeals}
          >{`Total profit by closed deals:  `}</div>
          <div className={classes.profit}>
            {profit ? `+ ${profit.toFixed(5)} ETH  (${profitPercent} %)` : ""}
          </div>

          <div className={classes.closedDeals}>{`Number of deals:  `}</div>
          <div className={classes.profit}>{deals ? `${deals.length}` : ""}</div>

          <div className={classes.closedDeals}>{`Avg profit by deal:  `}</div>
          <div className={classes.profit}>
            {avgDealProfit ? `${avgDealProfit.toFixed(5)} ETH` : "0 ETH"}
          </div>
        </div>
        <MaterialTable
          isLoading={loading}
          options={{
            emptyRowsWhenPaging: false,
            sorting: true,
            exportButton: true
          }}
          columns={[
            { title: "Pair", field: "pair" },
            { title: "Buy price (avg)", field: "buyPrice", type: "numeric" },
            { title: "Sell price (avg)", field: "sellPrice", type: "numeric" },
            { title: "Value (currency)", field: "amount", type: "numeric" },
            { title: "Profit (ETH)", field: "profit", type: "numeric" },
            { title: "Diff (%)", field: "diff", type: "numeric" },
            { title: "Trades", field: "tradesCount", type: "numeric" },
            {
              title: "Date",
              field: "date",
              type: "date",
              customSort: (a, b) => b.date - a.date,
              render: rowData => <div>{getDate(rowData.date)}</div>,
              defaultSort: "asc"
            }
          ]}
          data={
            deals
              ? deals.map(deal => ({
                  id: deal.id,
                  pair: deal.pair,
                  buyPrice: deal.buyPrice,
                  sellPrice: deal.sellPrice,
                  amount: deal.amount,
                  profit: deal.profit.toFixed(5),
                  diff: deal.diff.toFixed(5),
                  tradesCount:
                    tradesMap.size === undefined
                      ? 0
                      : tradesMap.get(deal.id).length,
                  date: deal.date
                }))
              : []
          }
          title="Deals"
          detailPanel={rowData => {
            const trades_data = tradesMap.get(rowData.id);
            return (
              <MaterialTable
                title="Trades"
                columns={[
                  { title: "Pair", field: "pair" },
                  {
                    title: "Side",
                    field: "side",
                    cellStyle: data => {
                      if (data === "BUY") {
                        return {
                          color: "#23c88c"
                        };
                      } else {
                        return {
                          color: "#ed5565"
                        };
                      }
                    }
                  },
                  { title: "Price", field: "price", type: "numeric" },
                  { title: "Amount", field: "amount", type: "numeric" },
                  { title: "Status", field: "status" },
                  {
                    title: "Date",
                    field: "date",
                    type: "date",
                    customSort: (a, b) => b.date - a.date,
                    render: rowData => <div>{getDate(rowData.date)}</div>,
                    defaultSort: "asc"
                  }
                ]}
                data={trades_data.map(trade => {
                  return {
                    pair: trade.pair,
                    side: trade.side,
                    price: trade.price,
                    amount: trade.amount,
                    status: trade.status,
                    date: trade.date
                  };
                })}
                options={{
                  toolbar: false,
                  paging: false
                }}
              />
            );
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDeals: userDealsSelector(state),
  loading: isFetchingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getDeals: userId => dispatch(getDealsRequest(userId))
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SimpleTable);
