import React from "react";
import {Field} from "formik";
import TextField from "../TextField";
import Autocomplete from "@mui/material/Autocomplete";

const AutoComplete = (props) => <Autocomplete
    {...props}
    renderInput={(params) => <TextField {...params} {...props}/>}
/>


const FormikAutoCompleteField = ({render, ...props}) => {
    return <Field {...props} component={AutoComplete}/>;
};

export default FormikAutoCompleteField;
