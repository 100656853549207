import React, {Component} from "react";
import MaterialTable from "@material-table/core";
import {Paper} from "@material-ui/core";
import {userActivityColumns} from "../../../../../../../user/TraderDiary/components/TraderTable";
import "../../../../../../../user/Payments/components/UserTransactions/styles/user-transactions.scss";
import "./styles/user-activity-tab.scss"
import {getUserActivity} from "../../../../../../../../api/admin";

class UserActivityTab extends Component {

    constructor() {
        super()
    }

    render() {
        const {
            match: {
                params: {id}
            }
        } = this.props;

        return (
            <div className="trader-diary user-activity-tab">
                <div className="page-content">
                    <div className="white-table">
                        <MaterialTable
                            title={"User Activity"}
                            options={{
                                emptyRowsWhenPaging: false,
                                sorting: true,
                                toolbar: true,
                                search: false,
                                headerStyle: {
                                    backgroundColor: 'rgb(241, 244, 247)',
                                    letterSpacing: "1px",
                                    color: "#000000",
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    // backgroundColor: "#f1f4f7",
                                    textTransform: "capitalize",
                                    // border: "none",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    // width: '100%',
                                    paddingLeft: "0",
                                    paddingRight: "0",
                                },
                                actionsCellStyle: {
                                    // color: 'red',
                                    // height: '200px'
                                    // backgroundColor: "white",
                                },
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50],
                            }}
                            components={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            columns={userActivityColumns}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    let uri = `size=${query.pageSize}&page=${query.page}`
                                    if (query.orderBy) {
                                        uri += `&sort=${query.orderBy.field},${query.orderDirection}`
                                    } else {
                                        uri += `&sort=closeDate,desc`
                                    }
                                    getUserActivity(id, uri)
                                        .then(result => {
                                            resolve({
                                                data: result.data.content
                                                    ? result.data.content.map(t => ({
                                                        id: t.id,
                                                        userId: t.userId,
                                                        date: t.date,
                                                        activityType: t.activityType
                                                    }))
                                                    : [],
                                                page: result.data.pageable.pageNumber,
                                                totalCount: result.data.totalElements,
                                            })
                                        })
                                })}

                        />
                    </div>

                </div>
            </div>
        );
    }
}



export default UserActivityTab;
