import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import createRequestMiddleware from "./requestMiddleware";

const sagaMiddleware = createSagaMiddleware();
const requestMiddleware = createRequestMiddleware();

const persistConfig = {
  key: "root",
  storage: storage
};

const storeEnhancer =
  process.env.NODE_ENV === "production"
    ? compose(applyMiddleware(requestMiddleware, sagaMiddleware))
    : composeWithDevTools(applyMiddleware(requestMiddleware, sagaMiddleware));

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, storeEnhancer);

export default () => {
  let persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};
