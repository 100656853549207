export const userItems = [
  {
    text: "Home",
    link: "/user/home"
  },
  {
    text: "Trader Diary",
    link: "/user/trader-diary"
  },
  {
    text: "Referees",
    link: "/user/referees"
  },
  {
    text: "Payments",
    link: "/user/payments"
  }
];

export const adminItems = [
  {
    text: "Statistics",
    link: "/admin/statistics"
  },
  {
    text: "Users",
    link: "/admin/users"
  }
];
