import * as Yup from "yup";

export const newPasswordValidator = Yup.string().required("Required");

export const passwordValidator = Yup.string().required("Required");

export const LoginValidationScheme = Yup.object().shape({
  newPassword: newPasswordValidator,
  password: passwordValidator
});
