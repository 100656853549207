import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_USER_REFEREES_REQUEST,
    GET_USER_REFERRAL_CODE_REQUEST,
    GET_USER_BALANCES_REQUEST,
    GET_USER_BALANCES_FAILURE,
    GET_USER_DEALS_REQUEST,
    GET_USER_DEALS_FAILURE,
    GET_USER_OPEN_DEALS_REQUEST,
    GET_USER_OPEN_DEALS_FAILURE,
    GET_USER_LAST_BALANCE_REQUEST,
    GET_USER_LAST_BALANCE_FAILURE,
    getUserDealsSuccess,
    getUserDealsFailure,
    getUserOpenDealsSuccess,
    getUserOpenDealsFailure,
    getUserBalancesFailure,
    getUserBalancesSuccess,
    getUserLastBalanceFailure,
    getUserLastBalanceSuccess,
    getUserRefereesFailure,
    getUserRefereesSuccess,
    getUserReferralCodeFailure,
    getUserReferralCodeSuccess
} from "../actions/user";
import {
    startLoading,
    stopLoading
} from "../actions/ui";
import {
    getUserReferees,
    getUserReferralCode,
    getUserBalances,
    getUserLastBalance,
    getUsersTrades,
    getUsersDeals,
    getUsersOpenDeals
} from "../api/user";
import {getUserDeals} from "../helper/statistics/userStatistic";

function* getUserRefereesSaga(action) {
    try {
        yield put(startLoading("UserReferees"))
        const response = yield call(getUserReferees, action.payload);
        yield put(getUserRefereesSuccess({referees: response.data, userId: action.payload}));
    } catch (e) {
        console.log(GET_USER_REFEREES_REQUEST, e);
        yield put(getUserRefereesFailure());
    } finally {
        yield put(stopLoading("UserReferees"))
    }
}

function* getUserReferralCodeSaga(action) {
    try {
        const response = yield call(getUserReferralCode, action.payload);

        yield put(getUserReferralCodeSuccess(response.data));
    } catch (e) {
        console.log(GET_USER_REFERRAL_CODE_REQUEST, e);
        yield put(getUserReferralCodeFailure());
    }
}

function* getUserBalanceSaga(action) {
    try {
        // const response = yield call(getUserBalances, action.payload);
        // const balances = response.data;
        // if (balances.length !== 0) {
        //     localStorage.setItem(
        //         "firstBalance",
        //         balances[balances.length - 1].balance
        //     );
        //     const liveBalance = parseFloat(localStorage.getItem("liveBalance"));
        //     const actualBalance = {
        //         base: "ETH",
        //         balance: liveBalance,
        //         date: new Date().getTime()
        //     };
        //     balances.push(actualBalance);
        //     balances.sort((a, b) => a.date - b.date);
        //     const result = [
        //         ...balances.map(balance => [balance.date, balance.balance])
        //     ];
        //
        //     yield put(getUserBalancesSuccess(result));
        // } else {
        //     yield put(getUserBalancesSuccess([]));
        // }
    } catch (e) {
        console.log(GET_USER_BALANCES_FAILURE, e);
        yield put(getUserBalancesFailure(e));
    }
}

function* getUserLastBalanceSaga(action) {
    try {
        const response = yield call(getUserLastBalance, action.payload);
        const balance = response.data;
        if (balance) {
            yield put(getUserLastBalanceSuccess(balance));
        } else {
            yield put(getUserLastBalanceSuccess({}));
        }
    } catch (e) {
        console.log(GET_USER_LAST_BALANCE_FAILURE, e);
        yield put(getUserLastBalanceFailure(e));
    }
}

function* getUserDealsTradesSaga(action) {
    try {
        const trades = yield call(getUsersTrades, action.payload);
        const deals = yield call(getUsersDeals, action.payload);
        const result = getUserDeals(deals.data.content, trades.data.content);

        yield put(getUserDealsSuccess(result));
    } catch (e) {
        console.log(GET_USER_DEALS_FAILURE, e);
        yield put(getUserDealsFailure(e));
    }
}

function* getUserOpenDealsTradesSaga(action) {
    try {
        const deals = yield call(getUsersOpenDeals, action.payload);
        yield put(getUserOpenDealsSuccess(deals));
    } catch (e) {
        console.log(GET_USER_OPEN_DEALS_FAILURE, e);
        yield put(getUserOpenDealsFailure(e));
    }
}

function* watchUserSaga() {
    yield takeEvery(GET_USER_REFEREES_REQUEST, getUserRefereesSaga);
    yield takeEvery(GET_USER_REFERRAL_CODE_REQUEST, getUserReferralCodeSaga);
    yield takeEvery(GET_USER_BALANCES_REQUEST, getUserBalanceSaga);
    yield takeEvery(GET_USER_LAST_BALANCE_REQUEST, getUserLastBalanceSaga);
    yield takeEvery(GET_USER_DEALS_REQUEST, getUserDealsTradesSaga);
    yield takeEvery(GET_USER_OPEN_DEALS_REQUEST, getUserOpenDealsTradesSaga);
}

export default watchUserSaga;
